import * as Sentry from '@sentry/react'
import {buildOptions, buildUri, getPathname} from './helpers'
import type {FetchOptions, FetchProps} from './types'
import {FetchError, PayloadError} from './types'

type DataAndResponse<Data = any> = {
  payload: Data
  response: Response
}

export async function rawFetch<Data = any, ErrorData = any>(
  uri: string,
  options: FetchOptions,
  returnResponse: true
): Promise<DataAndResponse<Data>>

export async function rawFetch<Data = any, ErrorData = any>(
  uri: string,
  options: FetchOptions,
  returnResponse?: false
): Promise<Data>

export async function rawFetch<Data = any, ErrorData = any>(
  rawUri: string,
  options: FetchOptions,
  returnResponse?: boolean
): Promise<Data | DataAndResponse> {
  let response: Response | undefined
  const uri = buildUri(rawUri, options)
  const fetchOptions = await buildOptions(options)

  try {
    response = await fetch(uri, fetchOptions)

    if (!response.ok) {
      let errorData: ErrorData | undefined
      try {
        errorData = (await response.json()) as ErrorData
      } catch {
        errorData = undefined
      }
      throw new PayloadError(response.statusText, errorData)
    }

    if (response.status === 204) {
      return undefined as Data
    }

    if (returnResponse) {
      return {
        payload: (await response.json()) as Data,
        response,
      }
    }

    return response.json() as Data
  } catch (err) {
    if (err instanceof Error) {
      const code = response?.status ?? 0
      const method = options.method?.toUpperCase() ?? 'GET'

      const message = `Fetch: ${method} ${getPathname(rawUri)} [${code}]: ${
        err?.message ?? 'Unknown error'
      }`
      const payload =
        err instanceof PayloadError ? (err.payload as ErrorData) : undefined
      const originalMessage = err.message

      const decoratedError = new FetchError<ErrorData>(
        message,
        payload,
        response,
        code,
        originalMessage
      )

      Sentry.withScope((scope) => {
        scope.setExtras({
          message,
          response,
          code,
          originalMessage: originalMessage,
          method,
          url: rawUri,
          requestPayload: options,
          responsePayload: payload,
        })
        Sentry.captureException(decoratedError)
      })

      throw decoratedError
    }
    throw Error
  }
}

export const apiFetch = {
  get: <Data = any, ErrorData = any>(
    uri: string,
    queryParams?: FetchOptions['queryParams'],
    options?: Omit<FetchOptions, 'method' | 'queryParams' | 'body'>
  ) => rawFetch<Data, ErrorData>(uri, {...options, queryParams, method: 'GET'}),

  post: <Data = any, ErrorData = any>(
    uri: string,
    body?: FetchOptions['body'],
    options?: Omit<FetchOptions, 'method' | 'queryParams' | 'body'>
  ) => rawFetch<Data, ErrorData>(uri, {...options, body, method: 'POST'}),

  put: <Data = any, ErrorData = any>(
    uri: string,
    body?: FetchOptions['body'],
    options?: Omit<FetchOptions, 'method' | 'queryParams' | 'body'>
  ) => rawFetch<Data, ErrorData>(uri, {...options, body, method: 'PUT'}),

  patch: <Data = any, ErrorData = any>(
    uri: string,
    body?: FetchOptions['body'],
    options?: Omit<FetchOptions, 'method' | 'queryParams' | 'body'>
  ) => rawFetch<Data, ErrorData>(uri, {...options, body, method: 'PATCH'}),

  delete: <Data = any, ErrorData = any>(
    uri: string,
    body?: FetchOptions['body'],
    options?: Omit<FetchOptions, 'method' | 'queryParams' | 'body'>
  ) => rawFetch<Data, ErrorData>(uri, {...options, body, method: 'DELETE'}),
}

export function apiFetchReturnResponse<Data = any, ErrorData = any>({
  uri,
  options,
}: FetchProps) {
  return rawFetch<Data, ErrorData>(uri, options, true)
}
