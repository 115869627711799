import {MyActsSelect} from 'components/connected'
import {PriorityMenu} from 'components/elements'
import {Filters} from 'containers/Core'
import {useSingleActPriorityMenu} from './hooks'

export const SingleActPriorityMenu = () => {
  const {value, onChange, options} = useSingleActPriorityMenu()

  return (
    <Filters className='grid grid-cols-[200px_1fr] gap-x-5 items-center'>
      <MyActsSelect
        theme='big'
        value={value}
        onChange={onChange}
        placeholder='Select act...'
      />
      <PriorityMenu items={options} value={value} onChange={onChange} />
    </Filters>
  )
}
