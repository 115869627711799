import {Main as MainComp} from 'containers/Core'
import type {MainProps} from 'containers/Core/Main'
import {cnLegacy} from 'utils'

export const Main = ({
  disabled,
  className,
  ...props
}: MainProps & {disabled?: boolean}) => (
  <MainComp
    {...props}
    className={cnLegacy(disabled ? 'bg-white' : 'bg-santasGray', className)}
  />
)
