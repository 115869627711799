import {cnLegacy} from 'utils'
import styles from './Form.module.css'
import type {GridHOCProps} from './gridHOC'
import {gridHOC} from './gridHOC'

interface InlineSpacerInnerProps {
  wrapperClassName?: string
  className?: string
}

export type InlineSpacerProps = GridHOCProps<InlineSpacerInnerProps>

const InlineSpacerInner: React.FC<
  React.PropsWithChildren<InlineSpacerProps>
> = ({wrapperClassName, className}) => {
  return (
    <div
      className={cnLegacy(
        styles.inlineSpacer,
        'form-inlineSpacer',
        wrapperClassName,
        className
      )}
    />
  )
}

export const InlineSpacer = gridHOC(InlineSpacerInner)
