import {Button} from 'components/elements'
import {useAppSelector} from 'hooks/store'
import {useEntity} from 'hooks/useEntity'

export const MissingOrganizerContactData = () => {
  const {openPerson} = useEntity()

  const contract = useAppSelector(({contract}) => contract.result)
  const invoiceOrganizerId =
    contract?.show.stakeholder_organizer_for_invoicing?.contact_person
      ?.affiliation.person.id
  const organizerId =
    contract?.show.stakeholder_organizer?.contact_person?.affiliation.person.id

  if (invoiceOrganizerId) {
    return (
      <Button
        onClick={() => openPerson(invoiceOrganizerId)}
        icon='new-window'
        label='Edit org. contact'
      />
    )
  }
  if (organizerId) {
    return (
      <Button
        onClick={() => openPerson(organizerId)}
        icon='new-window'
        label='Edit org. contact'
      />
    )
  }

  return null
}
