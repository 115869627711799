import {useLocation} from 'react-router-dom'
import {DropdownMenu, LinkButton, MobileMenu} from 'components/elements'
import type {IconName} from 'components/elements/Icon'
import {Icon} from 'components/elements/Icon'
import {BOOKING_BASE_PATH, BOOKING_PATHS, BOOKING_VIEWS} from 'options/booking'

interface ViewSelectorOption {
  title: string
  value: string
  icon: IconName
}
interface ViewSelectorOptgroup {
  label?: string
  options: ViewSelectorOption[]
}

const OPTIONS: ViewSelectorOptgroup[] = [
  {
    options: [
      {
        title: 'Flow',
        value: `/${BOOKING_BASE_PATH}/${BOOKING_PATHS[BOOKING_VIEWS.FLOW]}`,
        icon: 'board',
      },
    ],
  },
  {
    label: 'Single act',
    options: [
      {
        title: 'Months in columns',
        value: `/${BOOKING_BASE_PATH}/${
          BOOKING_PATHS[BOOKING_VIEWS.SINGLE_MONTHS_IN_COLUMNS]
        }`,
        icon: 'calendar-months-in-columns',
      },
      {
        title: 'One month',
        value: `/${BOOKING_BASE_PATH}/${
          BOOKING_PATHS[BOOKING_VIEWS.SINGLE_MONTH]
        }`,
        icon: 'calendar-one-month',
      },
      {
        title: 'List',
        value: `/${BOOKING_BASE_PATH}/${
          BOOKING_PATHS[BOOKING_VIEWS.SINGLE_LIST]
        }`,
        icon: 'calendar-agenda',
      },
    ],
  },
  {
    label: 'Multiple acts',
    options: [
      {
        title: 'Acts in columns',
        value: `/${BOOKING_BASE_PATH}/${
          BOOKING_PATHS[BOOKING_VIEWS.MULTIPLE_ACTS_IN_COLUMNS]
        }`,
        icon: 'calendar-acts-in-columns',
      },
      {
        title: 'Months in columns',
        value: `/${BOOKING_BASE_PATH}/${
          BOOKING_PATHS[BOOKING_VIEWS.MULTIPLE_MONTHS_IN_COLUMNS]
        }`,
        icon: 'calendar-months-in-columns',
      },
      {
        title: 'One month',
        value: `/${BOOKING_BASE_PATH}/${
          BOOKING_PATHS[BOOKING_VIEWS.MULTIPLE_MONTH]
        }`,
        icon: 'calendar-one-month',
      },
      {
        title: 'List',
        value: `/${BOOKING_BASE_PATH}/${
          BOOKING_PATHS[BOOKING_VIEWS.MULTIPLE_LIST]
        }`,
        icon: 'calendar-agenda',
      },
    ],
  },
]

const OPTIONS_REDUCED = OPTIONS.reduce(
  (
    sum: ViewSelectorOption[],
    a: ViewSelectorOptgroup
  ): ViewSelectorOption[] => [...sum, ...a.options],
  []
)

const ViewSelectorInput = () => {
  const {pathname} = useLocation()
  const selected = OPTIONS_REDUCED.find(
    (option: ViewSelectorOption) => option.value === pathname
  )

  if (!selected) {
    return null
  }

  const {icon, title} = selected

  return (
    <div className='flex items-center min-w-[160px]'>
      <Icon icon={icon} size='16' className='mr-2.5' />
      <div>{title}</div>
    </div>
  )
}

const ViewSelectorContent = () => {
  const {pathname} = useLocation()

  return (
    <section>
      {OPTIONS.map((optGroup, index) => (
        <section key={optGroup.label || index}>
          {!!optGroup.label && (
            <div className='p-2.5 bg-springWood border-y border-westar'>
              {optGroup.label}
            </div>
          )}
          <div className='p-2.5 space-y-1'>
            {optGroup.options.map((option) => (
              <LinkButton
                key={option.value}
                to={option.value}
                icon={option.icon}
                label={option.title}
                width='fill'
                theme={pathname === option.value ? 'primary' : 'light'}
                alignment='left'
              />
            ))}
          </div>
        </section>
      ))}
    </section>
  )
}

const useMobileIcon = () => {
  const {pathname} = useLocation()
  const selected = OPTIONS_REDUCED.find(
    (option: ViewSelectorOption) => option.value === pathname
  )

  return selected?.icon ?? 'calendar'
}

interface ViewSelectorProps {
  mobile?: boolean
}

export const ViewSelector = ({mobile}: ViewSelectorProps) => {
  const mobileIcon = useMobileIcon()

  if (mobile) {
    return (
      <MobileMenu
        title='Select view'
        icon={mobileIcon}
        targetProps={{size: 'big'}}
      >
        {() => <ViewSelectorContent />}
      </MobileMenu>
    )
  }

  return (
    <section>
      <DropdownMenu
        big
        target={() => <ViewSelectorInput />}
        content={() => <ViewSelectorContent />}
      />
    </section>
  )
}
