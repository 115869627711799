import type {PayloadAction} from '@reduxjs/toolkit'
import {createAction} from '@reduxjs/toolkit'
import type {ClassValue} from 'clsx'
import clsx from 'clsx'
import {format} from 'date-fns'
import {isNil} from 'lodash'
import {twMerge} from 'tailwind-merge'
import type {DateFormat, DateFormatString} from 'types/date'

export {default as cnLegacy} from 'classnames'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

type ExcludesFalse = <T>(x: T | false) => x is T

export type Unpacked<T> = T extends (infer U)[] ? U : T

export function compact<T>(arr: T[]): NonNullable<T>[] {
  return arr.filter<T>(Boolean as any as ExcludesFalse) as NonNullable<T>[]
}

export function trimAll(input: string): string {
  return input.trim().replace(/\s+/, ' ')
}

function getDateFormatString(dateFormat: DateFormat): DateFormatString {
  switch (dateFormat) {
    case 'DayOfMonth':
      return 'DD'
    case 'DayOfWeek':
      return 'dd'
    case 'DayOfMonthAndMonth':
      return 'Do MMMM'
    case 'YearLong':
      return 'YYYY'
    case 'MonthLong':
      return 'MMMM'
    case 'MonthYear':
      return 'MMM YYYY'
    case 'ISOYearMonth':
      return 'YYYY-MM'
    case 'ISOYearMonthDay':
      return 'YYYY-MM-DD'
    case 'DateShort':
      return 'Do MMM YYYY'
    case 'DateShortWithTime':
      return 'Do MMM YYYY HH:mm'
    case 'DateLong':
      return 'dd, Do MMM YYYY'
  }
}

export function formatDate(
  date: Date | string | number,
  dateFormat: DateFormat
) {
  return format(date, getDateFormatString(dateFormat))
}

export const createActionWithMeta = <P, M>(type: string) =>
  createAction(type, (payload: P, meta: M) => ({payload, meta}))

export interface MetaThunk {
  thunk: true
}

export const createActionWithThunk = <P, M = MetaThunk>(type: string) =>
  createAction(type, (payload: P, meta?: M) => ({
    payload,
    meta: {...meta, thunk: true},
  }))

export type PayloadActionWithMeta<P, M> = PayloadAction<P, string, M>

export type PayloadActionWithThunk<P, M = MetaThunk> = PayloadActionWithMeta<
  P,
  M
>

interface TokenObject {
  key: string
  value: string
}
type TokenString = string

type Token = TokenObject | TokenString
type Tokens = Record<string, Token>
type TokenTransformer = (value: string) => string | undefined
type TokenResponse = string | undefined

function getToken<T = Tokens>(
  tokens: T,
  key: keyof T,
  transformers?: Record<keyof T, TokenTransformer>
): TokenResponse {
  if (!tokens[key]) {
    return undefined
  }

  const token = tokens[key] as unknown as Token

  const transformer =
    transformers && transformers[key]
      ? transformers[key]
      : (value: string) => value

  if (typeof token === 'object') {
    return 'value' in token ? transformer(token.value) : undefined
  } else {
    return transformer(token)
  }
}

export function replaceTokens<T = Tokens>(
  tokens: T,
  str: string,
  options?: {
    getter?: (
      tokens: T,
      key: keyof T,
      transformers?: Record<keyof T, TokenTransformer>
    ) => TokenResponse
    transformers: Record<keyof T, TokenTransformer>
  }
) {
  const getter = options?.getter ?? getToken

  return str.replace(/\{\{\s([a-zA-Z_]+)\s\}\}/gi, (match, p1: keyof T) => {
    const value = getter(tokens, p1, options?.transformers)
    return !isNil(value) ? value : ''
  })
}

export function getTimeInSeconds(
  input: number,
  unit: 'minutes' | 'hours' | 'days'
) {
  if (input < 1) {
    throw new Error('Input must be at least 1')
  }

  switch (unit) {
    case 'minutes':
      return input * 60
    case 'hours':
      return input * 60 * 60
    case 'days':
      return input * 60 * 60 * 24
    default:
      throw new Error("This error shouldn't occur.")
  }
}
export function getTimeInMilliseconds(
  input: number,
  unit: 'minutes' | 'hours' | 'days'
) {
  return getTimeInSeconds(input, unit) * 1000
}

export type TTL = 'FAST' | 'NORMAL' | 'SLOW' | 'INFINITE'

export function getTTL(
  ttl: TTL,
  interval: 'seconds' | 'milliseconds' = 'milliseconds'
) {
  const func =
    interval === 'milliseconds' ? getTimeInMilliseconds : getTimeInSeconds

  switch (ttl) {
    case 'FAST':
      return func(15, 'minutes')
    case 'NORMAL':
      return func(1, 'hours')
    case 'SLOW':
      return func(6, 'hours')
    case 'INFINITE':
      return func(1, 'days')
  }
}

export function castArray<T>(input: T | T[]): T[] {
  return Array.isArray(input) ? input : [input]
}

export function isNonNullable<T>(input: T): input is NonNullable<T> {
  return input !== undefined && input !== null
}

export const isReactElement = (
  element: React.ReactNode
): element is React.ReactElement =>
  element !== null && typeof element === 'object' && 'props' in element

export function isPromise(p: unknown): boolean {
  if (
    !!p &&
    typeof p === 'object' &&
    'then' in p &&
    typeof p.then === 'function'
  ) {
    return true
  }
  return false
}
