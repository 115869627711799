import castArray from 'lodash/castArray'
import compact from 'lodash/compact'
import {dateDeforeAndAfter, dateRangeFromType} from 'helpers/api'

export function jobListExportQueryParamsFormatter({
  tourId,
  actId,
  startDay,
  endDay,
  showsOnly,
  dateRangeType,
}) {
  const rtn = {
    ...dateRangeFromType(startDay, endDay, dateRangeType),
    shows_only: showsOnly,
  }

  if (tourId) {
    rtn.tour_id = tourId
  } else {
    rtn.act_id = actId
  }

  return rtn
}

export function accountingExportQueryParamsFormatter({
  tourId,
  actIds,
  organizerIds,
  startDay,
  endDay,
  dateRangeType,
}) {
  const rtn = dateRangeFromType(startDay, endDay, dateRangeType)

  if (tourId) {
    rtn.tour_id = tourId
  } else if (compact(castArray(actIds)).length) {
    rtn.act_ids = compact(castArray(actIds)).join(',')
  } else if (compact(castArray(organizerIds)).length) {
    rtn.organizer_ids = compact(castArray(organizerIds)).join(',')
  }

  return rtn
}

export function actCommissionExportQueryParamsFormatter({
  startDay,
  endDay,
  dateRangeType,
}) {
  const rtn = dateRangeFromType(startDay, endDay, dateRangeType)

  return rtn
}

export function actRoyaltyExportQueryParamsFormatter({
  actId,
  tourId,
  startDay,
  endDay,
  dateRangeType,
}) {
  const rtn = dateRangeFromType(startDay, endDay, dateRangeType)

  if (tourId) {
    rtn.tour_id = tourId
  } else {
    rtn.act_id = actId
  }

  return rtn
}

export function navisionExportQueryParamsFormatter({startDay, endDay}) {
  return dateDeforeAndAfter({
    startDay,
    endDay,
  })
}
