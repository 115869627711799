import {Icon, Time} from 'components/elements'
import {getStaticRoot} from 'helpers/api'
import type {Signing} from 'types/signing'
import type {PropsWithClassName} from 'types/util'
import {cnLegacy} from 'utils'
import {useT} from './helpers'

interface ContentProps extends PropsWithClassName {
  data: Signing
}

export function Unsigned({data, className}: ContentProps) {
  const {t} = useT(data.document.language, data.document)

  return (
    <div className={cnLegacy('space-y-4', className)}>
      {t('unsignedContent')}

      <img
        src={`${getStaticRoot()}static/contracts/images/logo-department-${
          data.document.raw_data.booking_agency.id
        }.svg`}
        alt=''
        className='w-32'
      />
    </div>
  )
}

export function PartiallySigned({data, className}: ContentProps) {
  const {t} = useT(data.document.language, data.document)

  return (
    <div className={cnLegacy('space-y-4', className)}>
      <div className='bg-hintOfGreen border p-3 flex gap-x-3'>
        <Icon icon='checkmark-fill' />
        <div>
          <div>{t('promiseeSigned')}</div>
          <div>
            {data.document.promisee.signee?.affiliation.person.full_name}
          </div>
          <Time
            obj={data.document.promisee.signed_at}
            format='DateShortWithTime'
          />
        </div>
      </div>
      <div className='bg-desertStorm border p-3 flex gap-x-2'>
        <Icon icon='hour-glass' />
        <div>
          <div>{t('promisorPending')}</div>
          <div>
            {data.document.promisor.signee?.affiliation.person.full_name}
          </div>
          <Time
            obj={data.document.promisor.invitation_expiry_at}
            format='DateShortWithTime'
          />
        </div>
      </div>
    </div>
  )
}

export function Signed({data, className}: ContentProps) {
  const {t} = useT(data.document.language, data.document)

  return (
    <div className={cnLegacy('space-y-4', className)}>
      <div className='bg-hintOfGreen border p-3 flex gap-x-3'>
        <Icon icon='checkmark-fill' />
        <div>
          <div>{t('promiseeSigned')}</div>
          <div>
            {data.document.promisee.signee?.affiliation.person.full_name}
          </div>
          <Time
            obj={data.document.promisee.signed_at}
            format='DateShortWithTime'
          />
        </div>
      </div>
      <div className='bg-hintOfGreen border p-3 flex gap-x-2'>
        <Icon icon='checkmark-fill' />
        <div>
          <div>{t('promisorSigned')}</div>
          <div>
            {data.document.promisor.signee?.affiliation.person.full_name}
          </div>
          <Time
            obj={data.document.promisor.signed_at}
            format='DateShortWithTime'
          />
        </div>
      </div>
    </div>
  )
}
