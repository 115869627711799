import {useMutation} from '@tanstack/react-query'
import {useSetAtom} from 'jotai'
import {initErrorsAtom} from 'app/modal/Email/atoms'
import type {
  EmailCreateData,
  EmailInitErrorData,
  EmailInitResponse,
} from 'app/modal/Email/types'
import {apiFetch} from 'lib/fetch'
import {FetchError} from 'lib/fetch/types'

export const useEmailInitMutation = () => {
  const setError = useSetAtom(initErrorsAtom)

  return useMutation({
    mutationFn: async (url: string) => {
      setError(undefined)
      return apiFetch.get<EmailInitResponse, EmailInitErrorData>(url)
    },
    cacheTime: 0,
    onError: (error) => {
      if (error instanceof FetchError) {
        setError(error.payload)
      }
    },
  })
}

interface CreateMutationData {
  url: string
  data: EmailCreateData
}
export const useEmailCreateMutation = () => {
  return useMutation({
    mutationFn: async ({url, data}: CreateMutationData) => {
      return apiFetch.post(url, data)
    },
  })
}
