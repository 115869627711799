import {useDispatch} from 'react-redux'
import {DocumentDownloader} from 'components/connected'
import type {ButtonProps} from 'components/elements'
import {Button} from 'components/elements'
import {toastrActions} from 'features/toastr'
import {useContractActions} from '../helpers'

export const DownloadContract = (props: ButtonProps) => {
  const {status, disabled, pdfDownloadUrl} = useContractActions()
  const dispatch = useDispatch()

  const handleDownloadFailure = (e: Error) => {
    dispatch(
      toastrActions.errorToast(
        'Could not download',
        e instanceof Error ? e.message : undefined
      )
    )
  }

  return (
    <DocumentDownloader
      url={pdfDownloadUrl}
      disabled={disabled}
      failureCallback={handleDownloadFailure}
    >
      <Button
        onClick={props.onClick}
        icon='pdf'
        label={status === 'CONTRACT_STATUS_TBC' ? 'Open draft PDF' : 'Open'}
      />
    </DocumentDownloader>
  )
}
