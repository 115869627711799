import {useSetAtom} from 'jotai'
import {useDispatch} from 'react-redux'
import {Contract} from 'app/modal/Contract'
import {initErrorsAtom} from 'app/modal/Email/atoms'
import {
  initializeContract,
  resetContract,
  showContract,
} from 'store/contract/actions'
import type {ContractInitializeParams} from 'store/contract/types'
import {useModal} from './useModal'

type NewContractParams = Partial<ContractInitializeParams>

export const useContract = () => {
  const dispatch = useDispatch()
  const {openModal} = useModal()
  const setEmailInitError = useSetAtom(initErrorsAtom)

  return {
    newContract: (initializeParams: NewContractParams) => {
      if (!!initializeParams && !!initializeParams.actId) {
        dispatch(
          initializeContract({
            actId: initializeParams.actId,
            ...initializeParams,
          })
        )
      } else {
        dispatch(resetContract())
      }
      setEmailInitError(undefined)
      openModal(() => <Contract />)
    },
    openContract: (id: number) => {
      dispatch(showContract({id}))
      setEmailInitError(undefined)
      openModal(() => <Contract />)
    },
  }
}
