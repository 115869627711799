import {useEffect, useRef, useState} from 'react'
import PDFObject from 'pdfobject'
import type {PropsWithClassName} from 'types/util'
import {cnLegacy} from 'utils'

export function PdfViewer({
  fileUrl,
  label = 'PDF of the contract',
  className,
}: {
  fileUrl: string
  label?: string
} & PropsWithClassName) {
  const ref = useRef(null)
  const [init, setInit] = useState(false)

  useEffect(() => {
    if (!ref.current) {
      return
    }
    if (init) {
      return
    }

    PDFObject.embed(fileUrl, ref.current, {
      pdfOpenParams: {
        toolbar: 1,
      },
    })
    setInit(true)
  }, [])

  return (
    <div
      className={cnLegacy('absolute inset-0', className)}
      ref={ref}
      aria-label={label}
    />
  )
}
