import {useState} from 'react'
import PDFObject from 'pdfobject'
import {SignDocument} from 'components/connected/SignDocument'
import {Button, HeaderText, Spinner, Visible} from 'components/elements'
import {Logo} from 'components/svg/Logo'
import {Header, Container, HeaderLeft, HeaderRight, Main} from 'containers/Core'
import {useIsDesktop} from 'hooks/useIsDesktop'
import {FetchError} from 'lib/fetch/types'
import type {PropsWithClassName} from 'types/util'
import {cn} from 'utils'
import {PartiallySigned, Signed, Unsigned} from './Content'
import {ErrorGeneric} from './ErrorGeneric'
import {useT} from './helpers'
import {useDocumentSigning} from './hooks'
import {PdfViewer} from './PdfViewer'

function CompoundLogo({className}: PropsWithClassName) {
  return (
    <div className={cn('flex items-center gap-x-2', className)}>
      <div className='space-y-1'>
        <div className='text-secondary text-ratSize'>Powered by</div>
        <Logo className='text-primary md:text-white fill-current w-20' />
      </div>
      <img
        src='/images/logo_lights_no_bg_css.svg'
        alt=''
        width={64}
        className='hidden md:block'
      />
    </div>
  )
}

export const ContractSign = () => {
  const [contractVisible, setContractVisible] = useState(false)
  const {data, status, refetch, error} = useDocumentSigning()
  const isDesktop = useIsDesktop()

  function handleContractVisible() {
    if (!PDFObject.supportsPDFs) {
      if (!data) {
        return
      }
      window.open(data.document.file.file_url, '_blank')
      return
    }

    setContractVisible((oldVal) => !oldVal)
  }

  if (
    status === 'error' &&
    error &&
    error instanceof FetchError &&
    error.payload
  ) {
    return <ErrorGeneric />
  }

  if (status === 'loading') {
    return <Spinner />
  }

  if (status !== 'success') {
    return <ErrorGeneric />
  }

  if (!data) {
    return <ErrorGeneric />
  }

  const {t} = useT(data.document.language, data.document)

  return (
    <Container>
      <Header className='static'>
        <HeaderLeft>
          <HeaderText
            primary={t('title')}
            secondary={isDesktop ? t('fileName') : undefined}
            pipe
          />
        </HeaderLeft>
        <HeaderRight>
          <Visible ifFalse={isDesktop}>
            <CompoundLogo className='py-2' />
          </Visible>
        </HeaderRight>
      </Header>

      <Main
        grid
        className={cn(
          'border-2 ',
          'grid-rows-[1fr_auto_auto]',
          'md:grid-cols-[1fr_minmax(400px,_30%)] md:grid-rows-[1fr_auto_auto]'
        )}
      >
        <section
          className={cn(
            'relative overflow-hidden',
            'bg-white',
            'col-start-1 row-start-1',
            'md:col-start-1 md:row-start-1 md:row-span-full',
            contractVisible ? 'order-1' : 'order-2 hidden md:block'
          )}
        >
          <PdfViewer fileUrl={data.document.file.file_url} />
        </section>

        <section
          className={cn(
            'overflow-hidden',
            'col-start-1 row-start-1',
            'md:col-start-2 md:row-start-1 md:row-span-1',
            'bg-white',
            contractVisible ? 'order-2 hidden' : 'order-1',
            'p-5'
          )}
        >
          {data.document.state === 'STATE_UNSIGNED' && <Unsigned data={data} />}
          {data.document.state === 'STATE_PARTIALLY_SIGNED' && (
            <PartiallySigned data={data} />
          )}
          {data.document.state === 'STATE_SIGNED' && <Signed data={data} />}
        </section>

        <section
          className={cn(
            'grid pt-5',
            'row-start-2',
            'md:col-start-2',
            'bg-white border-l'
          )}
        >
          <div className='md:hidden pl-5 col-start-1 row-start-1 pb-5'>
            <Button
              label={contractVisible ? t('hidePdf') : t('showPdf')}
              icon={contractVisible ? 'close' : 'visible'}
              className='visible md:invisible'
              onClick={() => handleContractVisible()}
            />
          </div>
          <div className='col-start-1 row-start-1'>
            {data.document.state === 'STATE_UNSIGNED' && (
              <SignDocument
                config={{
                  sendCodeUrl: data.send_code_url,
                  signingUrl: data.signing_url,
                  auth: false,
                }}
                onSuccess={() => refetch()}
                showSkipButton
                language={data.document.language}
                buttonProps={{
                  label: t('startSigning'),
                  icon: 'checkmark-fill',
                  theme: 'primary',
                }}
                phoneNumber={data.document.promisee.scrambled_phone_number}
                signeeName={
                  data.document.promisee.signee?.affiliation.person.full_name ??
                  ''
                }
              />
            )}
          </div>
        </section>

        <footer
          className={cn(
            'hidden md:flex',
            'md:col-start-2 md:row-start-3',
            'justify-end items-center',
            'bg-oxfordBlue py-3 px-2'
          )}
        >
          <CompoundLogo />
        </footer>
      </Main>
    </Container>
  )
}
