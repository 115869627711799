import type {PropsWithChildren} from 'react'
import type {PropsWithClassName} from 'types/util'
import {cnLegacy} from 'utils'

export const Container = ({children}: PropsWithChildren) => (
  <div className='grid grid-rows-[auto_1fr] overflow-hidden'>{children}</div>
)

export const Buttons = ({children}: PropsWithChildren) => (
  <div className='flex gap-2.5'>{children}</div>
)

export const Filters = ({children}: PropsWithChildren) => (
  <div className='m-5 p-5 bg-springWood border border-alto grid gap-2.5 justify-start'>
    {children}
  </div>
)

export const Filter = ({
  label,
  children,
}: {label: string} & PropsWithChildren) => (
  <label className='space-y-1 w-48'>
    <div className='font-bold text-secondary'>{label}</div>
    {children}
  </label>
)

export const Content = ({
  children,
  className,
}: PropsWithChildren & PropsWithClassName) => (
  <div className={cnLegacy('p-5 overflow-y-auto', className)}>{children}</div>
)

export const Controls = ({children}: PropsWithChildren) => (
  <fieldset className='p-5 border border-alto'>{children}</fieldset>
)
