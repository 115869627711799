// https://www.natterly.com/blog/toast-notification-system-in-a-react-redux-application
import {useAppSelector} from 'hooks/store'
import {Toast} from './Toast'

export const Toastr = () => {
  const toasts = useAppSelector((store) => store.toastr)

  return (
    <div
      className={
        'z-[999999] fixed top-2.5 right-2.5 flex flex-col items-end space-y-2'
      }
    >
      {toasts.map((toast) => (
        <Toast key={toast.id} data={toast} />
      ))}
    </div>
  )
}
