import type {ChangeEvent} from 'react'
import type {FormFieldBaseProps} from 'types/form'
import {cnLegacy} from 'utils'
import styles from './Checkbox.module.css'

export interface CheckboxProps extends FormFieldBaseProps<boolean> {
  text?: string
  inverted?: boolean
  className?: string
}

export const Checkbox: React.FC<React.PropsWithChildren<CheckboxProps>> = ({
  name,
  value,
  onChange,
  onBlur,
  text,
  disabled,
  inverted,
  className,
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = inverted ? !e.target.checked : e.target.checked

    onChange(value)
  }

  const checked = inverted ? !value : value

  return (
    <section
      className={cnLegacy(
        styles.root,
        {
          [styles.disabled]: disabled,
        },
        className
      )}
    >
      <label className={styles.wrapper}>
        <input
          name={name}
          className={styles.element}
          checked={checked}
          onChange={handleChange}
          onBlur={onBlur}
          type='checkbox'
        />
        <div className={styles.checkbox} />
        {text && <div className={styles.text}>{text}</div>}
      </label>
    </section>
  )
}
