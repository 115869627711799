import {useState} from 'react'
import {Input, Select} from 'components/elements'
import {Icon, ICON_NAMES} from 'components/elements/Icon'
import {colors} from 'styles/variables'
import {Container, Content, Filter, Filters} from './components'

const options = Object.keys(colors).map((key) => ({
  label: key,
  value: (colors as any)[key] as string,
}))

export const PlaygroundIcon = () => {
  const [size, setSize] = useState<number>(16)
  const [color, setColor] = useState<string | undefined>()

  return (
    <Container>
      <Filters>
        <Filter label='Size'>
          <Input
            kind='number'
            min='10'
            max='128'
            value={size}
            onChange={(v) => setSize(v)}
          />
        </Filter>
        <Filter label='Color'>
          <Select value={color} onChange={setColor} options={options} />
        </Filter>
      </Filters>

      <Content className='grid grid-cols-[repeat(auto-fit,_minmax(200px,_1fr))] gap-y-10 gap-x-2'>
        {ICON_NAMES.toSorted().map((icon) => (
          <li key={icon} className='flex flex-col items-center'>
            <div style={{color}} className='border p-1.5'>
              <Icon icon={icon} size={size} />
            </div>
            <pre>{icon}</pre>
          </li>
        ))}
      </Content>
    </Container>
  )
}
