import type {MouseEvent} from 'react'
import {useCallback} from 'react'
import type {ButtonProps, IconName} from 'components/elements'
import {Button} from 'components/elements'
import {useEmailer} from 'hooks/useEmailer'
import type {ContractStatusType} from 'types/contract'
import {useContractActions, useReloadContract} from '../helpers'
import type {ContractEmailAction} from '../types'

function getEmailHeader(
  action: ContractEmailAction,
  status: ContractStatusType | undefined
): string {
  console.log(action, status)
  return 'Send contract'
}

function getEmailBtnLabel(action: ContractEmailAction): string {
  switch (action) {
    case 'sendSigningLink':
      return 'Send signing link'
    case 'sendPdfAndMarkAsSent':
      return 'Send and mark as sent'
    case 'sendPdf':
      return 'Send draft'
    case 'retractSigning':
      return 'Send signing rectraction'
  }
}

function getBtnIcon(action: ContractEmailAction): IconName {
  switch (action) {
    case 'sendSigningLink':
      return 'link'
    default:
      return 'mail'
  }
}

function getBtnLabel(action: ContractEmailAction): string {
  switch (action) {
    case 'sendSigningLink':
      return 'Send signing link'
    case 'sendPdfAndMarkAsSent':
      return 'Send PDF'
    case 'sendPdf':
      return 'Send draft PDF'
    case 'retractSigning':
      return 'Retract Signing'
  }
}

interface EmailContractProps extends ButtonProps {
  action: ContractEmailAction
}

export const EmailContract = ({action, ...restProps}: EmailContractProps) => {
  const {compose, isInitializing} = useEmailer()
  const {
    id,
    status,
    disabled,
    draftEmailUrl,
    finalEmailUrl,
    signingInvitationUrl,
    emailHeader,
  } = useContractActions()
  const {reloadContract} = useReloadContract()

  const handleClick = useCallback(
    (e: MouseEvent) => {
      let url: string | undefined

      if (!id) {
        throw new Error('EmailContract: Missing ID')
      }

      if (action === 'sendSigningLink') {
        if (!signingInvitationUrl) {
          throw new Error('EmailContract: Missing signingInvitationUrl')
        }
        url = signingInvitationUrl
      } else if (action === 'sendPdfAndMarkAsSent') {
        if (!finalEmailUrl) {
          throw new Error('EmailContract: Missing finalEmailUrl')
        }
        url = finalEmailUrl
      } else if (action === 'sendPdf') {
        if (!draftEmailUrl) {
          throw new Error('EmailContract: Missing draftEmailUrl')
        }
        url = draftEmailUrl
      } else if (action === 'retractSigning') {
        if (!signingInvitationUrl) {
          throw new Error('EmailContract: Missing signingInvitationUrl')
        }
        url = signingInvitationUrl.replace('/signing-invitation/', '/retract/')
      }

      if (!url) {
        throw new Error('EmailContract: Missing url')
      }

      compose({
        url,
        headerPrimary: getEmailHeader(action, status),
        headerSecondary: emailHeader,
        onSuccess: () => {
          reloadContract()
        },
        sendBtnText: getEmailBtnLabel(action),
        lockRecipients: action === 'sendSigningLink',
      })
    },
    [
      compose,
      emailHeader,
      draftEmailUrl,
      finalEmailUrl,
      signingInvitationUrl,
      id,
      status,
      reloadContract,
      action,
    ]
  )

  if (!id) {
    return null
  }

  return (
    <Button
      {...restProps}
      activitySpinnerOverlay
      onClick={handleClick}
      disabled={disabled}
      hasActivity={isInitializing}
      icon={getBtnIcon(action)}
      label={getBtnLabel(action)}
    />
  )
}
