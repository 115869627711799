import {useCallback, useState} from 'react'
import {apiFetch} from 'lib/fetch'
import type {DocumentSigningConfig} from './types'

export const useDocumentSigning = (
  config: DocumentSigningConfig,
  onSuccess?: () => void
) => {
  const [code, setCode] = useState<string>('')
  const [signingUrl, setSigningUrl] = useState<string | undefined>(undefined)
  const [isSendingCode, setIsSendingCode] = useState(false)
  const [isSigning, setIsSigning] = useState(false)

  const sendCode = useCallback(async () => {
    try {
      setIsSendingCode(true)
      let _sendCodeUrl = ''
      let _signingUrl = ''

      if ('sendCodeUrl' in config) {
        _sendCodeUrl = config.sendCodeUrl
        _signingUrl = config.signingUrl
      } else if ('signingKeyUrl' in config) {
        const response = await apiFetch.post(config.signingKeyUrl, undefined, {
          auth: config.auth,
        })

        const response2 = await apiFetch.get(
          response.signing_url.replace('/api', ''),
          undefined,
          {auth: config.auth}
        )

        _sendCodeUrl = response2.send_code_url as string
        _signingUrl = response2.signing_url as string
      }

      await apiFetch.post(_sendCodeUrl, undefined, {auth: config.auth})

      setSigningUrl(_signingUrl)
    } catch (e) {
      console.error(e)
    } finally {
      setIsSendingCode(false)
    }
  }, [config])

  const sign = useCallback(
    async (code: string) => {
      try {
        if (!signingUrl) {
          throw new Error('no signing url')
        }
        setIsSigning(true)
        await apiFetch.post(signingUrl, {code}, {auth: config.auth})
        if (onSuccess) {
          onSuccess()
        }
      } finally {
        setIsSigning(false)
      }
    },
    [signingUrl, onSuccess]
  )

  const openSign = () => {
    setCode('')
    if ('signingUrl' in config) {
      setSigningUrl(config.signingUrl)
    }
  }

  const closeSign = () => {
    setCode('')
    setSigningUrl(undefined)
  }

  return {
    pendingSigning: !!signingUrl,
    code,
    setCode,
    sendCode,
    sign,
    isSendingCode,
    isSigning,
    openSign,
    closeSign,
  }
}
