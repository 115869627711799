import {createElement, PureComponent} from 'react'
import includes from 'lodash/includes'
import {useDispatch} from 'react-redux'
import styled from 'styled-components'
import {Button, ButtonGroup, Tooltip} from 'components/elements'
import {useContract} from 'hooks/useContract'
import {useEntity} from 'hooks/useEntity'
import {createPlanner} from 'store/planner/actions'
import type {PlannerActionMeta, PlannerCreateParams} from 'store/planner/types'
import type {EventType} from 'types/event'

const StyledButton = styled(Button)`
  margin: 0;
  height: 28px;
  line-height: 28px;
  padding: 0 4px;
  border-radius: 0 !important;
`

interface NewTourBtnProps {
  startOn: string
  actId: number
}

const NewTourBtn = ({startOn, actId}: NewTourBtnProps) => {
  const {newTour} = useEntity()

  const handleClick = () => {
    newTour({
      startOn,
      actId,
    })
  }

  return (
    <Tooltip content='Create tour'>
      <StyledButton theme='tour' icon='road' onClick={handleClick} />
    </Tooltip>
  )
}

interface NewContractBtnProps {
  date: string
  actId: number
  id: number
  type: EventType
}

const NewContractBtn = ({date, actId, id, type}: NewContractBtnProps) => {
  const {newContract} = useContract()

  const handleClick = () =>
    newContract({
      actId,
      date,
      fromEventId: id,
      fromEventType: type,
    })

  return (
    <Tooltip
      content={type === 'EMPTY_DAY' ? 'Create contract' : 'Upgrade to contract'}
    >
      <StyledButton onClick={handleClick} icon='contract' />
    </Tooltip>
  )
}

interface ActionsProps {
  cellKey: string
  date: string
  id: number
  type: EventType
  actId?: number
  onDelete: () => void
  addNoteStart: () => void
}

interface ActionsInnerProps extends ActionsProps {
  createPlanner: (data: PlannerCreateParams, meta: PlannerActionMeta) => void
}

export class ActionsInner extends PureComponent<ActionsInnerProps> {
  createPlanner = (type: EventType) => {
    const {date, actId, cellKey} = this.props

    if (!actId) {
      return null
    }

    this.props.createPlanner(
      {
        type,
        start_on: date,
        act: {id: actId},
      },
      {cellKey}
    )
  }

  createReservedDate = () => this.createPlanner('EVENT_TYPE_RESERVED_DATE')
  createOffday = () => this.createPlanner('EVENT_TYPE_OFF_DAY')
  createLogistics = () => this.createPlanner('EVENT_TYPE_LOGISTICS_EVENT')

  showOnEmpty = () => this.props.type === 'EMPTY_DAY'
  showButton = (arr: EventType[]) => includes(arr, this.props.type)
  showDeleteBtn = () =>
    this.showButton([
      'EVENT_TYPE_NOTE_EVENT',
      'EVENT_TYPE_RESERVED_DATE',
      'EVENT_TYPE_OFF_DAY',
      'EVENT_TYPE_LOGISTICS_EVENT',
    ])

  showNewContract = () =>
    this.props.actId
      ? this.showButton([
          'EMPTY_DAY',
          'EVENT_TYPE_NOTE_EVENT',
          'EVENT_TYPE_RESERVED_DATE',
        ])
      : this.showButton(['EVENT_TYPE_NOTE_EVENT', 'EVENT_TYPE_RESERVED_DATE'])

  showNewTour = () => this.props.actId
  showReserveDate = () => this.props.actId && this.showOnEmpty()
  showCreateOffday = () => this.props.actId && this.showOnEmpty()
  showCreateLogisticsDay = () => this.props.actId && this.showOnEmpty()
  showNewNote = () =>
    this.props.actId &&
    this.showButton(['EVENT_TYPE_SHOW', 'EVENT_TYPE_NOTE_EVENT'])

  renderNewContractBtn() {
    const {date, actId, id, type} = this.props

    if (!actId) {
      return null
    }

    return createElement(NewContractBtn, {
      date,
      actId,
      id,
      type,
    })
  }

  renderNewTourBtn() {
    const {date, actId} = this.props

    if (!actId) {
      return null
    }

    return createElement(NewTourBtn, {
      startOn: date,
      actId,
    })
  }

  render() {
    return (
      <div
        onClick={(e) => e.stopPropagation()} // Prevents cell from going into editing mode
        className='absolute top-0 right-0 flex justify-end z-50'
      >
        {this.showDeleteBtn() && (
          <Button
            round
            icon='close'
            size='small'
            onClick={this.props.onDelete}
            className='mt-[7px] mr-2'
          />
        )}
        <ButtonGroup>
          {this.showNewContract() && this.renderNewContractBtn()}
          {this.showReserveDate() && (
            <Tooltip content='Reserve date'>
              <StyledButton
                theme='reserveDate'
                onClick={() => this.createReservedDate()}
                icon='bookmark'
              />
            </Tooltip>
          )}
          {this.showCreateOffday() && (
            <Tooltip content='Create offday'>
              <StyledButton
                theme='notAvailable'
                onClick={this.createOffday}
                icon='palm'
              />
            </Tooltip>
          )}
          {this.showCreateLogisticsDay() && (
            <Tooltip content='Create logistics day'>
              <StyledButton
                theme='logistics'
                onClick={this.createLogistics}
                icon='van'
              />
            </Tooltip>
          )}
          {this.showNewTour() && this.renderNewTourBtn()}
          {this.showNewNote() && (
            <Tooltip content='New note'>
              <StyledButton onClick={this.props.addNoteStart} icon='new-row' />
            </Tooltip>
          )}
        </ButtonGroup>
      </div>
    )
  }
}

export const Actions = (props: ActionsProps) => {
  const dispatch = useDispatch()
  const handleCreatePlanner = (
    data: PlannerCreateParams,
    meta: PlannerActionMeta
  ) => dispatch(createPlanner(data, meta))

  return <ActionsInner {...props} createPlanner={handleCreatePlanner} />
}
