import {useAtom} from 'jotai'
import {useLocation} from 'react-router-dom'
import {Icon, MobileMenu} from 'components/elements'
import {useAuth} from 'hooks/useAuth'
import {useIsDesktop} from 'hooks/useIsDesktop'
import {cnLegacy} from 'utils'
import {appNavigationMaximized} from './atoms'
import {MenuItem} from './components'
import {useCurrentBookingViewPath} from './hooks'
import {UserMenu} from './UserMenu'

interface MenuItemsProps {
  onItemClick?: () => void
}

const MenuItems: React.FC<React.PropsWithChildren<MenuItemsProps>> = ({
  onItemClick = () => undefined,
}) => {
  const {hasRole} = useAuth()
  const {pathname} = useLocation()
  const currentBookingPath = useCurrentBookingViewPath()

  return (
    <div>
      <MenuItem
        to={currentBookingPath}
        active={pathname.startsWith('/booking')}
        onClick={onItemClick}
        icon='calendar'
        title='Booking'
      />
      <MenuItem
        to='/production'
        active={pathname.startsWith('/production')}
        onClick={onItemClick}
        icon='road'
        title='Production'
      />
      <MenuItem
        to='/reports'
        active={pathname.startsWith('/reports')}
        onClick={onItemClick}
        icon='report'
        title='Reports'
      />
      {hasRole('BOOKING_AGENCY_ACCOUNTANT') && (
        <MenuItem
          to='/accounting'
          active={pathname.startsWith('/accounting')}
          onClick={onItemClick}
          icon='economy'
          title='Accounting'
        />
      )}
      {!import.meta.env.PROD && (
        <MenuItem
          active={pathname.startsWith('/playground')}
          onClick={onItemClick}
          to='/playground'
          icon='star'
          title='Playground'
        />
      )}
    </div>
  )
}

export const AppNavigation = () => {
  const [maximized, setMaximized] = useAtom(appNavigationMaximized)
  const isDesktop = useIsDesktop()

  const content = isDesktop ? (
    <>
      <header className='md:h-16 md:flex md:flex-row'>
        <UserMenu />
      </header>

      <nav className={cnLegacy('flex-none md:flex-1')}>
        <MenuItems />
      </nav>

      <button
        onClick={() => setMaximized(!maximized)}
        className={cnLegacy(
          'h-14 hidden md:grid place-items-center text-midGray hover:text-pictonBlue'
        )}
      >
        <Icon icon={maximized ? 'chevron-left' : 'chevron-right'} size='32' />
      </button>
    </>
  ) : (
    <MobileMenu
      title='Navigation'
      target={({openMenu}) => (
        <button
          onClick={openMenu}
          className='-m-2.5 bg-oxfordBlue text-white w-10 h-14 grid place-items-center'
        >
          <Icon icon='menu' size='20' />
        </button>
      )}
    >
      {({closeMenu}) => (
        <>
          <div className='text-dogSize divide-y'>
            <MenuItems onItemClick={closeMenu} />
            <UserMenu />
          </div>
        </>
      )}
    </MobileMenu>
  )

  return (
    <div
      className={cnLegacy(
        'flex-none bg-ebonyClay overflow-hidden relative',
        maximized ? 'md:flex-[0_0_240px]' : 'md:flex-[0_0_56px]',
        'transition-all'
      )}
    >
      <div
        className={cnLegacy(
          'flex flex-row md:flex-col',
          'p-2.5 md:p-0',
          'justify-between',
          'items-center md:items-unset',
          'md:absolute md:inset-0'
        )}
      >
        {content}
      </div>
    </div>
  )
}
