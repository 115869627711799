import {SignDocument} from 'components/connected/SignDocument'
import {useAuth} from 'hooks/useAuth'
import {useContractActions, useReloadContract} from '../helpers'

export const PromisorSign = () => {
  const {signingKeyUrl, document} = useContractActions()
  const {reloadContract} = useReloadContract()
  const {user} = useAuth()

  if (!signingKeyUrl || !document) {
    return null
  }

  const disabled = document.promisor.signee?.affiliation.person.id !== user.id

  // TODO: Use phone number from actual signee and not logged in user
  const phoneNumber = `+${user.business_card.phone_numbers[0].value.country_code}${user.business_card.phone_numbers[0].value.number}`
  const signeeName = `${document.promisor.signee?.affiliation.person.full_name}`

  const tooltip = disabled ? `Only ${signeeName} can sign!` : undefined

  return (
    <SignDocument
      config={{signingKeyUrl, auth: true}}
      onSuccess={reloadContract}
      buttonProps={{
        icon: 'final',
        label: 'Sign',
        theme: 'secondary',
        width: 'fill',
      }}
      disabled={disabled}
      phoneNumber={phoneNumber}
      signeeName={signeeName}
      tooltip={tooltip}
    />
  )
}
