import {useEffect, useState, createRef} from 'react'
import isNil from 'lodash'
import {useDispatch} from 'react-redux'
import TextareaComp from 'react-textarea-autosize'
import styled from 'styled-components'
import {useClickOutside} from 'hooks/useClickOutside'
import {createPlanner, updatePlanner} from 'store/planner/actions'
import {colors, fontSizes, spacing} from 'styles/variables'
import type {PlannerEvent} from 'types/planner'

const MAX_LENGTH = 200

const Root = styled.div`
  padding: ${spacing.u2_5} 25px ${spacing.u2_5} ${spacing.u2};
  position: relative;
`

const Counter = styled.div`
  position: absolute;
  top: 2px;
  right: 5px;
  font-size: ${fontSizes.mouseSize};
`

const Textarea = styled(TextareaComp)`
  display: block;
  background: transparent;
  width: 100%;
  border: none;
  resize: none;
  line-height: 13px;
  font-size: 12px;
  color: ${colors.primaryTextColor};
  outline: none;
  padding: 0;
`

interface EditProps {
  onDelete: () => void
  onCancel: () => void
  cellKey: string
  value: string
  data: PlannerEvent
  disabled: boolean
}

export const Edit = ({
  onDelete,
  onCancel,
  cellKey,
  value,
  data,
  disabled,
}: EditProps) => {
  const dispatch = useDispatch()
  const [text, setText] = useState('')
  const _root = createRef<HTMLDivElement>()

  const handleCancel = () => {
    if (onCancel) {
      onCancel()
    }
  }

  const handleSave = () => {
    // Don't trigger if component is disabled
    // Prevents double saving
    if (disabled) {
      return
    }
    // If type is a NOTE and is has no text, trigger delete
    if (data.type === 'EVENT_TYPE_NOTE_EVENT' && !text) {
      onDelete()
      // Cancel if text is equal to the props value (or empty text)
    } else if (text === value || (text === '' && isNil(value))) {
      handleCancel()
      // Create new event
    } else if (data.type === 'EMPTY_DAY') {
      // Check that we have a text to save
      // Should no be needed, but its an extra guard
      if (!text) {
        handleCancel()
      } else {
        const {act, ...createData} = data
        if (!act?.id) {
          return
        }
        dispatch(
          createPlanner(
            {
              ...createData,
              text,
              type: 'EVENT_TYPE_NOTE_EVENT',
              act: {id: act.id},
            },
            {cellKey}
          )
        )
      }
      // Update event
    } else {
      dispatch(updatePlanner({...data, text}, {cellKey}))
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value
    if (value.length <= MAX_LENGTH) {
      setText(value)
    }
  }

  const handleSubmit = () => {
    handleSave()
  }

  const handleClickOutside = () => {
    handleSave()
  }

  const handleEscapeKey = () => handleCancel()

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      handleEscapeKey()
    } else if (e.key === 'Enter') {
      e.preventDefault()
      e.stopPropagation()
      handleSubmit()
    }
  }

  useEffect(() => {
    setText(value ?? '')
  }, [value])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress)
    return () => document.removeEventListener('keydown', handleKeyPress)
  })

  useClickOutside(
    _root,
    true,
    () => {
      handleClickOutside()
    },
    true
  )

  return (
    <Root ref={_root}>
      <Textarea autoFocus value={text} onChange={handleChange} />
      <Counter>{`${text.length}/${MAX_LENGTH}`}</Counter>
    </Root>
  )
}
