import {cnLegacy} from 'utils'

export interface EmptyMessageProps {
  title: string
  description?: string
  className?: string
}

export const EmptyMessage = ({
  className,
  title,
  description,
}: EmptyMessageProps) => (
  <div className={cnLegacy('text-center text-santasGray', className)}>
    <p className='text-wolfSize'>{title}</p>
    {description && <p className='text-dogSize'>{description}</p>}
  </div>
)
