import type {EmptyMessageProps} from 'components/elements'
import {EmptyMessage as EmptyMessageComp} from 'components/elements'
import {cnLegacy} from 'utils'

export const EmptyMessage = ({className, ...props}: EmptyMessageProps) => (
  <EmptyMessageComp
    {...props}
    className={cnLegacy('self-center mx-auto', className)}
  />
)
