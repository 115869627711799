import {useEmail} from 'app/modal/Email/hooks'
import type {EmailerComposeProps} from 'app/modal/Email/types'

export const useEmailer = () => {
  const {init, initializing} = useEmail()

  const compose = (props: EmailerComposeProps) => init(props)

  return {
    compose,
    isInitializing: initializing,
  }
}
