import styled from 'styled-components'
import {colors, fontSizes, spacing} from 'styles/variables'
import {useEmailData, useSetEmailData} from './hooks'

const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 32px;
  align-items: center;
  grid-gap: 0 ${spacing.u2_5};
  font-size: ${fontSizes.catSize};
`

const Title = styled.div`
  color: ${colors.secondaryTextColor};
`

const Input = styled.input`
  background: transparent;
  color: ${colors.primaryTextColor};
  border: none;
  font-size: inherit;

  &::placeholder {
    color: rgba(85, 84, 89, 0.4);
  }

  &,
  &:focus {
    outline: none;
  }
`

export const Subject = () => {
  const {subject} = useEmailData()
  const {setSubject} = useSetEmailData()

  return (
    <Container>
      <Title>Subject</Title>
      <Input
        type='text'
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
        placeholder='Enter email subject'
      />
    </Container>
  )
}
