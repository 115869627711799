import type {ReactNode} from 'react'
import {forwardRef} from 'react'
import {cnLegacy} from 'utils'
import {getTheme} from './helpers'
import type {BadgeColor} from './types'

interface BadgeContainerProps {
  color?: BadgeColor
  inverted?: boolean
  pill?: boolean
}

interface BadgeProps extends BadgeContainerProps {
  children: ReactNode
}

export const Badge = forwardRef<HTMLDivElement, BadgeProps>(
  ({children, color, inverted, pill}, ref) => {
    return (
      <div
        className={cnLegacy(
          'inline-block text-ratSize uppercase',
          pill ? 'py-px px-1' : 'py-px px-[3px]',
          pill ? 'rounded-full ' : 'rounded-sm',
          'border',
          'select-none',
          getTheme(color, inverted)
        )}
        ref={ref}
        color={color}
      >
        {children}
      </div>
    )
  }
)

Badge.displayName = 'Badge'
