import type {ContractStatusType} from '../types/contract'
import type {AdvanceOption} from '../types/contractConfigurations'

interface ContractStatusFlowOption {
  canAdvanceTo: ContractStatusType[]
  advanceOptions: AdvanceOption[]
  prompt?: unknown
}
const optionCancel: AdvanceOption = {
  label: 'Cancel',
  icon: 'cancel',
  action: 'cancel',
}
const optionContinueEditing: AdvanceOption = {
  label: 'Continue editing',
  icon: 'arrow-left',
  newState: 'CONTRACT_STATUS_CONFIRMED',
}

export const CONTRACT_STATUS_FLOW_OPTIONS: {
  [key in ContractStatusType]: ContractStatusFlowOption
} = {
  CONTRACT_STATUS_TBC: {
    canAdvanceTo: ['CONTRACT_STATUS_CONFIRMED'],
    advanceOptions: [
      {
        label: 'Mark as confirmed',
        icon: 'checkmark-fill',
        newState: 'CONTRACT_STATUS_CONFIRMED',
      },
      optionCancel,
    ],
  },
  CONTRACT_STATUS_CONFIRMED: {
    canAdvanceTo: [
      'CONTRACT_STATUS_FINALIZED',
      'CONTRACT_STATUS_UNSIGNED',
      'CONTRACT_STATUS_CANCELLED',
    ],
    advanceOptions: [
      {
        label: 'Send signing link',
        icon: 'link',
        action: 'sendSigningLink',
      },
      {
        label: 'Send PDF',
        icon: 'link',
        action: 'sendPdfAndMarkAsSent',
      },
      {
        label: 'Mark as sent',
        icon: 'sent',
        newState: 'CONTRACT_STATUS_FINALIZED',
      },
      optionCancel,
    ],
  },
  CONTRACT_STATUS_FINALIZED: {
    canAdvanceTo: [
      'CONTRACT_STATUS_SIGNED',
      'CONTRACT_STATUS_CONFIRMED',
      'CONTRACT_STATUS_CANCELLED',
    ],
    advanceOptions: [
      {
        label: 'Mark as signed',
        icon: 'signed',
        newState: 'CONTRACT_STATUS_SIGNED',
      },
      optionContinueEditing,
      optionCancel,
    ],
  },
  CONTRACT_STATUS_SIGNED: {
    canAdvanceTo: ['CONTRACT_STATUS_CONFIRMED', 'CONTRACT_STATUS_CANCELLED'],
    advanceOptions: [optionContinueEditing, optionCancel],
  },
  CONTRACT_STATUS_UNSIGNED: {
    canAdvanceTo: [
      'CONTRACT_STATUS_PARTIALLY_SIGNED',
      'CONTRACT_STATUS_CONFIRMED',
    ],
    advanceOptions: [
      {
        label: 'Awaiting booker',
        icon: 'phone',
        action: 'awaitingPartialSignature',
      },
      {
        label: 'Cancel signing',
        icon: 'arrow-left',
        action: 'retractSigning',
      },
      optionCancel,
    ],
  },
  CONTRACT_STATUS_PARTIALLY_SIGNED: {
    canAdvanceTo: ['CONTRACT_STATUS_FULLY_SIGNED', 'CONTRACT_STATUS_CONFIRMED'],
    advanceOptions: [
      {
        label: 'Sign contract',
        icon: 'checkered-flag',
        action: 'sign',
      },
      {
        label: 'Cancel signing',
        icon: 'arrow-left',
        action: 'retractSigning',
      },
      optionCancel,
    ],
  },
  CONTRACT_STATUS_FULLY_SIGNED: {
    canAdvanceTo: [],
    advanceOptions: [],
  },
  CONTRACT_STATUS_CANCELLED: {
    canAdvanceTo: ['CONTRACT_STATUS_CONFIRMED'],
    advanceOptions: [optionContinueEditing],
  },
}
