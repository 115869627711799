import {cnLegacy} from 'utils'
import styles from './formStyles.module.css'

export interface FieldLabelProps {
  name: string
  label?: string
}

export const FieldLabel: React.FC<React.PropsWithChildren<FieldLabelProps>> = ({
  name,
  label,
}) => {
  const labelStyles = cnLegacy(styles.label, 'whnue-inputWrapper-label')

  if (!label) {
    return null
  }

  return (
    <label htmlFor={name} className={labelStyles}>
      {label}
    </label>
  )
}

export interface FieldErrorProps {
  show?: boolean
  error?: string
}

export const FieldError: React.FC<React.PropsWithChildren<FieldErrorProps>> = ({
  show,
  error,
}) => {
  if (!(error && show)) {
    return null
  }
  return <div className={styles.error}>{error}</div>
}
