import {forwardRef} from 'react'
import {Icon, SpinnerInline} from 'components/elements'
import {cnLegacy} from 'utils'
import styles from './ReportCard.module.css'
import type {IconName} from '../Icon'

interface ReportCardProps {
  className?: string
  disabled?: boolean
  hasActivity?: boolean
  active?: boolean
  icon: IconName
  title: string
  description: string
  download?: boolean
}

export const ReportCard = forwardRef<HTMLElement, ReportCardProps>(
  (
    {
      className,
      disabled,
      hasActivity,
      active,
      icon,
      title,
      description,
      download,
      ...rest
    },
    ref
  ) => {
    const classNames = cnLegacy(
      styles.root,
      {
        [styles.disabled]: disabled || hasActivity,
        [styles.active]: !(disabled || hasActivity) && active,
      },
      className
    )

    return (
      <section ref={ref} className={classNames} {...rest}>
        <Icon icon={icon} size={40} className={styles.icon} />
        <section className={styles.content}>
          {hasActivity && <SpinnerInline className={styles.activitySpinner} />}
          <div className={styles.title}>{title}</div>
          <div className={styles.description}>{description}</div>
        </section>
        {download && (
          <Icon icon='download' size={16} className={styles.downloadIcon} />
        )}
      </section>
    )
  }
)
ReportCard.displayName = 'ReportCard'
