import Markdown from 'markdown-to-jsx'
import {Filters, Flow as FlowComp} from 'components/Flow'
import {AppLayout} from 'components/layout'

export const Flow = () => (
  <AppLayout
    className='relative overflow-hidden'
    title='Flow'
    filters={<Filters flow='booking' passiveText='cancelled' />}
  >
    <div className='absolute inset-0 flex flex-col flex-nowrap'>
      <Markdown className='flex-none m-5 text-secondary'>
        {`Drag the events from one column to another, to indicate where they are in the flow. All columns are sorted by date, with the most urgent on top.
          <br />
          The events in the 'Signed' and 'Cancelled' columns are automatically removed when the event is over.`}
      </Markdown>
      <FlowComp kind='booking' />
    </div>
  </AppLayout>
)
