import type {ReactNode} from 'react'
import type {SigningDocument} from 'types/signing'

type TConfig = string | ((document: SigningDocument) => ReactNode)

type TKeys =
  | 'title'
  | 'fileName'
  | 'unsignedContent'
  | 'promisorPending'
  | 'promisorSigned'
  | 'promiseePending'
  | 'promiseeSigned'
  | 'showPdf'
  | 'hidePdf'
  | 'startSigning'

const EN: Record<TKeys, TConfig> = {
  title: 'Sign contract',
  fileName: (data) => data.file.original_name,
  unsignedContent: (data) => (
    <div className='text-dogSize space-y-2'>
      <h2 className='font-bold'>
        Welcome {data.promisee.signee?.affiliation.person.full_name}
      </h2>
      <div>
        <p>This is where you sign the contract regarding</p>
        <p className='text-pictonBlue'>
          {data.raw_data.act.name} ({data.raw_data.show.date}. Contract #
          {data.raw_data.contract_number})
        </p>
      </div>
      <p>
        You sign the contract by pressing the button: &apos;Start signing&apos;.
      </p>

      <p>
        You will then receive a code by SMS which you must enter to identify
        yourself and sign.
      </p>
      <p>
        If you have any comments or questions about the contract, please contact
        me at:
      </p>

      <p>
        <a
          href={`mailto:${data.promisor.email_address}`}
          className='hover:underline'
        >
          {data.promisor.email_address}
        </a>
        <br />
        <a
          href={`tel:${data.promisor.phone_number}`}
          className='hover:underline'
        >
          {data.promisor.phone_number}
        </a>
      </p>
    </div>
  ),
  promiseePending: 'Awaiting organizer',
  promiseeSigned: 'Signed by organizer',
  promisorPending: 'Awaiting booker',
  promisorSigned: 'Signed by booker',
  showPdf: 'Show',
  hidePdf: 'Hide',
  startSigning: 'Start signing',
}

const DA: Partial<Record<TKeys, TConfig>> = {
  title: 'Underskriv kontrakt',
  unsignedContent: (data) => (
    <div className='text-dogSize space-y-2'>
      <h2 className='font-bold'>
        Velkommen {data.promisee.signee?.affiliation.person.full_name}
      </h2>

      <div>
        <p>Her underskriver du kontrakten vedrørende</p>
        <p className='text-pictonBlue'>
          {data.raw_data.act.name} ({data.raw_data.show.date}. Contract #
          {data.raw_data.contract_number})
        </p>
      </div>
      <p>
        Du underskriver kontrakten ved at trykke på knappen: &apos;Begynd
        underskrivning&apos;.
      </p>

      <p>
        Du vil herefter modtage en kode på SMS, som du skal indtaste for at
        identificere dig og underskrive.{' '}
      </p>
      <p>
        Hvis du har nogle kommentarer eller spørgsmål til kontrakten, så tag
        endelig fat i mig på:{' '}
      </p>

      <p>
        <a
          href={`mailto:${data.promisor.email_address}`}
          className='hover:underline'
        >
          {data.promisor.email_address}
        </a>
        <br />
        <a
          href={`tel:${data.promisor.phone_number}`}
          className='hover:underline'
        >
          {data.promisor.phone_number}
        </a>
      </p>
    </div>
  ),
  promiseePending: 'Afventer arrangør',
  promiseeSigned: 'Underskrevet af arrangør',
  promisorPending: 'Afventer booker',
  promisorSigned: 'Underskrevet af booker',
  showPdf: 'Vis',
  hidePdf: 'Skjul',
  startSigning: 'Begynd underskrivning',
} as const

function getConfig(language: string) {
  switch (language) {
    case 'da':
      return DA
    default:
      return EN
  }
}

function getTranslation(language: string, key: TKeys): TConfig | undefined {
  return getConfig(language)[key] || getConfig('en')[key]
}

export function useT(language: string, document: SigningDocument) {
  return {
    t: (key: TKeys): ReactNode => {
      const translatable = getTranslation(language, key)
      if (!translatable) {
        return `KEY '${key}' missing`
      }
      if (typeof translatable === 'function') {
        return translatable(document)
      }
      return translatable
    },
  }
}
