import {cnLegacy} from 'utils'
import styles from './container.module.css'

interface FiltersProps {
  className?: string
  bg?: 'standard' | 'white'
  shadow?: boolean
}

export const Filters: React.FC<React.PropsWithChildren<FiltersProps>> = ({
  className,
  children,
  bg = 'standard',
  shadow = true,
}) => (
  <section
    className={cnLegacy(className, styles.filters, `bg-${bg}`, {shadow})}
  >
    {children}
  </section>
)
