import {map} from 'lodash'
import {useDispatch, useSelector} from 'react-redux'
import type {TooltipPosition} from 'components/elements'
import {Tooltip} from 'components/elements'
import {useEntity} from 'hooks/useEntity'
import {highlightTour} from 'store/planner/actions'
import type {RootStore} from 'store/types'
import type {PlannerEvent} from 'types/planner'
import {cnLegacy} from 'utils'

const useTourItem = (id: number) => {
  const dispatch = useDispatch()
  const {openTour} = useEntity()
  const highlightedTour = useSelector<RootStore, number | null>(
    ({listPlanner}) => listPlanner.highligtedTour
  )

  return {
    openTour: () => openTour(id),
    highlighted: id === highlightedTour,
    setHighlight: () => dispatch(highlightTour(id)),
    unsetHighlight: () => dispatch(highlightTour(null)),
  }
}
interface TourItemProps {
  tooltipPosition?: TooltipPosition
  id: number
  text: string
  dots?: boolean
}

const TourItem = ({id, text, tooltipPosition, dots}: TourItemProps) => {
  const {openTour, highlighted, setHighlight, unsetHighlight} = useTourItem(id)

  return (
    <Tooltip
      content={
        <div>
          <div>{text || 'No name'}</div>
          <div className='text-secondary'>Click to edit</div>
        </div>
      }
      position={tooltipPosition}
    >
      <div
        className={cnLegacy(
          'bg-gossamer',
          'transition-all',
          dots ? 'h-3 rounded-full' : 'h-full',
          highlighted ? 'opacity-80' : 'opacity-40',
          dots ? (highlighted ? 'w-8' : 'w-3') : highlighted ? 'w-6' : 'w-1.5'
        )}
        // highlighted={highlighted}
        // dot={dots}
        onMouseEnter={setHighlight}
        onMouseLeave={unsetHighlight}
        onClick={openTour}
      />
    </Tooltip>
  )
}

interface ToursProps {
  data: PlannerEvent[]
  dots?: boolean
}

export const Tours = ({data, dots}: ToursProps) => {
  if (!data || !data.length) {
    return null
  }

  return (
    <div
      className={cnLegacy('flex', dots ? 'gap-x-1.5' : 'divide-x divide-white')}
    >
      {map(data, (tour) => (
        <TourItem
          key={tour.id}
          id={tour.id}
          text={tour.text ?? ''}
          tooltipPosition={dots ? 'top' : 'left'}
          dots={dots}
        />
      ))}
    </div>
  )
}
