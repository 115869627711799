import {cnLegacy} from 'utils'
import styles from './container.module.css'

interface ContainerProps {
  className?: string
  relative?: boolean
  width?: number
}

export const Container: React.FC<React.PropsWithChildren<ContainerProps>> = ({
  className,
  children,
  relative,
  width,
}) => (
  <section
    className={cnLegacy(className, styles.container, {relative})}
    style={{width: width ? `${width}px` : undefined}}
  >
    {children}
  </section>
)
