import Markdown from 'markdown-to-jsx'
import {FinancialExport} from 'app/modal/FinancialExport'
import {ReportsExport} from 'app/modal/ReportsExport'
import {HeaderText, ReportCard} from 'components/elements'
import {ViewHeader} from 'components/layout'
import {Container as PageContainer, Main} from 'containers/Core'
import {useModal} from 'hooks/useModal'
import {REPORT_CARD_OPTIONS} from 'options/reportCards'
import type {ReportCards} from 'types/reportCards'

const SORTED_CARDS: ReportCards[] = [
  'FINANCIAL_OVERVIEW',
  'ACT_COMMISSION',
  'JOB_LIST',
  'ACT_ROYALTY',
  'TOUR_FINANCIAL',
  'SAP_PROJECT_EXPORT',
  'SAP_EXPORT',
]

export const ReportsView = () => {
  const {openModal} = useModal()

  const handleClick = (card: ReportCards) => {
    if (
      card === 'NAVISION_EXPORT' ||
      card === 'SAP_PROJECT_EXPORT' ||
      card === 'SAP_EXPORT'
    ) {
      openModal(() => <FinancialExport kind={card} />)
    } else {
      openModal(() => <ReportsExport kind={card} />)
    }
  }

  return (
    <PageContainer>
      <ViewHeader>
        <HeaderText primary='Reports' />
      </ViewHeader>
      <Main className='py-5 px-2.5 md:p-8 overflow-y-auto md:max-w-5xl'>
        <div className='text-secondary mb-5 md:mb-8'>
          <Markdown>
            Here you can download various up to date reports, as means of
            communication or updating data in eg. economy systems.`
          </Markdown>
        </div>
        <ul className='grid items-stretch gap-2.5 md:gap-8 md:grid-cols-2'>
          {SORTED_CARDS.map((reportCard) => {
            return (
              <li key={reportCard} className='grid'>
                <button
                  onClick={() => handleClick(reportCard)}
                  key={reportCard}
                  className='grid text-left'
                >
                  <ReportCard
                    disabled={!!REPORT_CARD_OPTIONS[reportCard].disabled}
                    icon={REPORT_CARD_OPTIONS[reportCard].icon}
                    title={REPORT_CARD_OPTIONS[reportCard].title}
                    description={REPORT_CARD_OPTIONS[reportCard].description}
                    className='h-full'
                  />
                </button>
              </li>
            )
          })}
        </ul>
      </Main>
    </PageContainer>
  )
}
