import {Fragment} from 'react'
import {getISOWeek, isSameMonth, isFirstDayOfMonth} from 'date-fns'
import {chunk} from 'lodash'
import type {DaysArray} from 'helpers/booking'
import {cnLegacy, formatDate} from 'utils'
import {Day, Tours} from '../components'

interface OneMonthProps {
  month: string
  viewKey: string
  grid: DaysArray
}

export const OneMonth = ({grid, viewKey, month}: OneMonthProps) => {
  return (
    <div className='flex-1 grid grid-cols-[40px_repeat(7,_minmax(150px,_1fr))] grid-rows-[repeat(auto-fit,_minmax(100px,_1fr))] grid-flow-row overflow-auto'>
      {chunk(grid, 7).map((row, rowIndex) => (
        <Fragment key={`${viewKey}_row_${rowIndex}`}>
          <div
            className={
              'py-1 font-bold text-center border-l border-t first:border-0'
            }
          >
            {getISOWeek(row[0].date)}
          </div>
          {row.map((item, columnIndex) => {
            const {date, isToday, isWeekend, tours} = item
            const otherMonth = !isSameMonth(month, date)
            const headerDateFormat =
              !otherMonth && isFirstDayOfMonth(date)
                ? 'DayOfMonthAndMonth'
                : 'DayOfMonth'

            return (
              <div
                key={`${viewKey}_cell_${rowIndex}_${columnIndex}`}
                className={cnLegacy(
                  'flex flex-col border-l',
                  rowIndex > 0 ? 'border-t' : ''
                )}
              >
                <div
                  className={cnLegacy(
                    'flex-none flex justify-between items-center py-1 px-2',
                    isToday
                      ? 'bg-selago'
                      : isWeekend
                      ? 'bg-desertStorm'
                      : otherMonth
                      ? 'text-secondary'
                      : ''
                  )}
                >
                  <div>
                    <time className='font-bold'>
                      {formatDate(date, headerDateFormat)}
                    </time>
                    {rowIndex === 0 && (
                      <time className='lowercase ml-[3px] text-secondary'>
                        {formatDate(date, 'DayOfWeek')}
                      </time>
                    )}
                  </div>
                  <div>
                    <Tours data={tours} dots />
                  </div>
                </div>
                <div className='flex-1 flex overflow-hidden'>
                  <Day
                    {...item}
                    showTours={false}
                    className='flex-1 overflow-y-auto scrollbar scrollbar-w-1 scrollbar-h-1 scrollbar-track-babyBlue scrollbar-thumb-malibu'
                  />
                </div>
              </div>
            )
          })}
        </Fragment>
      ))}
    </div>
  )
}
