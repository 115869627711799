import {useAtom, useAtomValue, useSetAtom} from 'jotai'
import {useDispatch} from 'react-redux'
import {useEmailCreateMutation, useEmailInitMutation} from 'api'
import {Email} from 'app/modal/Email'
import {toastrActions} from 'features/toastr'
import {useModal} from 'hooks/useModal'
import {emailStateAtom} from './atoms'
import {buildStore} from './helpers'
import type {EmailerComposeProps, EmailRecipient} from './types'

export const useEmail = () => {
  const [state, setState] = useAtom(emailStateAtom)
  const {openModal, closeModal} = useModal()
  const dispatch = useDispatch()

  const {mutate: initMutation, status: initStatus} = useEmailInitMutation()
  const {mutate: createMutation, status: createStatus} =
    useEmailCreateMutation()

  const valid = state?.recipients.length

  const cancel = () => {
    closeModal()
    setTimeout(() => {
      setState(undefined)
    }, 1000)
  }

  return {
    async init(props: EmailerComposeProps) {
      return new Promise((resolve, reject) => {
        initMutation(props.url, {
          onSuccess: (data) => {
            setState(buildStore(data, props))
            openModal(() => <Email />)
            resolve(data)
          },
          onError: (error) => reject(error),
        })
      })
    },
    initializing: initStatus === 'loading',
    valid,
    async send(_action?: string) {
      if (!state) {
        return
      }
      if (!state.recipients.length) {
        throw new Error('recipients missing')
      }
      createMutation(
        {
          url: state.url,
          data: {
            recipients: state.recipients,
            attachments: state.attachments,
            subject: state.subject,
            html_body: state.html_body,
          },
        },
        {
          onSuccess: () => {
            if (state.onSuccess) {
              state.onSuccess()
            }
            dispatch(toastrActions.successToast('Email sent successfully'))
            cancel()
          },
          onError: () => {
            dispatch(toastrActions.errorToast('Email could not be sent'))
          },
        }
      )
    },
    sending: createStatus === 'loading',
    cancel() {
      closeModal()
      setTimeout(() => {
        setState(undefined)
      }, 1000)
    },
  }
}

export const useEmailData = () => {
  const state = useAtomValue(emailStateAtom)

  if (!state) {
    throw new Error('no state')
  }

  return state
}

export const useSetEmailData = () => {
  const setState = useSetAtom(emailStateAtom)

  return {
    setRecipients(recipients: EmailRecipient[]) {
      setState((oldState) => {
        if (!oldState) {
          return
        }
        return {
          ...oldState,
          recipients,
        }
      })
    },
    setSubject: (subject: string) => {
      setState((oldState) => {
        if (!oldState) {
          return
        }
        return {
          ...oldState,
          subject,
        }
      })
    },
    setHtmlBody: (html_body: string) => {
      setState((oldState) => {
        if (!oldState) {
          return
        }
        return {
          ...oldState,
          html_body,
        }
      })
    },
  }
}
