import {cnLegacy} from 'utils'
import styles from './container.module.css'

interface FooterProps {
  className?: string
}

export const Footer: React.FC<React.PropsWithChildren<FooterProps>> = ({
  className,
  children,
}) => <footer className={cnLegacy(className, styles.footer)}>{children}</footer>

export const FooterLeft: React.FC<React.PropsWithChildren<FooterProps>> = ({
  className,
  children,
}) => (
  <section className={cnLegacy(className, styles.footerLeft)}>
    {children}
  </section>
)

export const FooterMiddle: React.FC<React.PropsWithChildren<FooterProps>> = ({
  className,
  children,
}) => (
  <section className={cnLegacy(className, styles.footerMiddle)}>
    {children}
  </section>
)

export const FooterRight: React.FC<React.PropsWithChildren<FooterProps>> = ({
  className,
  children,
}) => (
  <section className={cnLegacy(className, styles.footerRight)}>
    {children}
  </section>
)
