import {useQuery} from '@tanstack/react-query'
import {useParams} from 'react-router-dom'
import {apiFetch} from 'lib/fetch'
import {FetchError} from 'lib/fetch/types'
import type {Signing} from 'types/signing'
import type {ContractSignParams} from './types'

export const useDocumentSigning = () => {
  const {id} = useParams<ContractSignParams>()

  return useQuery<Signing>(
    ['documentSiging', id],
    () => apiFetch.get(`documents/signing/${id}`, undefined, {auth: false}),
    {
      retry: (_failureCount, error) => {
        if (
          error instanceof FetchError &&
          error.code &&
          error.code >= 400 &&
          error.code < 500
        ) {
          return false
        }
        return true
      },
    }
  )
}
