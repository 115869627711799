import type {CSSProperties} from 'react'
import {getISOWeek} from 'date-fns'
import {Time} from 'components/elements'
import {cnLegacy} from 'utils'

function getWeekNumber(date: Date) {
  if (date.getDate() === 1 || date.getDay() === 1) {
    return (
      <span className='self-start flex-1 font-bold'>{getISOWeek(date)}</span>
    )
  } else {
    return null
  }
}
interface HeaderDayProps {
  className?: string
  style?: CSSProperties
  date: Date
  isWeekend: boolean
  isToday: boolean
}

export const HeaderDay = ({
  date,
  isWeekend,
  isToday,
  className,
  style,
}: HeaderDayProps) => (
  <div
    className={cnLegacy(
      'py-2.5 px-2 flex flex-row justify-end leading-[13px]',
      isToday ? 'bg-hawkesBlue' : isWeekend ? 'bg-westar' : 'bg-white',
      className
    )}
    style={style}
    // isWeekend={isWeekend}
    // isToday={isToday}
  >
    {getWeekNumber(date)}
    <div className='flex-[0_0_20px] lowercase text-secondary text-right'>
      <Time obj={date} format='DayOfWeek' />
    </div>
    <div className='flex-[0_0_20px] font-bold text-right'>
      <Time obj={date} format='DayOfMonth' />
    </div>
  </div>
)
