import {
  addDays,
  addMonths,
  addQuarters,
  addYears,
  startOfDay,
  startOfMonth,
  startOfYear,
  startOfQuarter,
} from 'date-fns'
import {isNil} from 'lodash'
import type {DateRangeType} from 'types/date'
import {formatDate} from 'utils'

interface BeforeAndAfter {
  startDay?: Date
  endDay?: Date
  startMonth?: Date
  endMonth?: Date
  month?: Date
}

// Handle dates
// After has to be the first day of the month: 2017-04-01 00:00:00
// Before has to be the first day of the next month: 2017-05-01 00:00:00
// It cannot the the last day (2017-04-30 23:59:59) because that excludes
// notes and simple all-day events, which ends the day after at 00:00:00
export const toString = (d: Date): string =>
  `${formatDate(d, 'ISOYearMonthDay')}T00:00:00Z`

export function dateDeforeAndAfter(obj: BeforeAndAfter) {
  const {startDay, endDay, startMonth, endMonth, month} = obj
  if (startDay && endDay) {
    return {
      after: toString(startOfDay(startDay)),
      before: toString(startOfDay(addDays(endDay, 1))),
    }
    // only startDay
  } else if (startDay) {
    return {after: toString(startOfDay(startDay))}
    // only endDay
  } else if (endDay) {
    return {
      before: toString(startOfDay(addDays(endDay, 1))),
    }
    // startMonth and endMonth
  } else if (startMonth && endMonth) {
    return {
      after: toString(startOfMonth(startOfDay(startMonth))),
      before: toString(startOfMonth(addMonths(startOfDay(endMonth), 1))),
    }
    // only startMonth
  } else if (startMonth && isNil(endMonth)) {
    return {
      after: toString(startOfMonth(startOfDay(startMonth))),
    }
    // only endMonth
  } else if (isNil(startMonth) && endMonth) {
    return {
      before: toString(startOfMonth(addMonths(startOfDay(endMonth), 1))),
    }
    // only month
  } else if (month) {
    return {
      after: toString(startOfMonth(startOfDay(month))),
      before: toString(startOfMonth(addMonths(startOfDay(month), 1))),
    }
  } else {
    throw new Error(
      `beforeAndAfter: could not generate response from ${Object.keys(obj)}`
    )
  }
}

export function dateRangeFromType(start: Date, end: Date, type: DateRangeType) {
  switch (type) {
    case 'day':
      return {
        after: toString(startOfDay(start)),
        before: toString(startOfDay(addDays(end, 1))),
      }
    case 'month':
      return {
        after: toString(startOfMonth(startOfDay(start))),
        before: toString(startOfMonth(addMonths(startOfDay(end), 1))),
      }
    case 'quarter':
      return {
        after: toString(startOfQuarter(startOfDay(start))),
        before: toString(startOfQuarter(addQuarters(startOfDay(end), 1))),
      }
    case 'year':
      return {
        after: toString(startOfYear(startOfDay(start))),
        before: toString(startOfYear(addYears(startOfDay(end), 1))),
      }
    default:
      return {}
  }
}

export function getStaticRoot() {
  return import.meta.env.VITE_API_ROOT.replace('api/', '')
}
