import {useEffect, useState} from 'react'
import type {PayloadAction} from '@reduxjs/toolkit'
import {isBefore, isDate} from 'date-fns'
import {deburr, filter} from 'lodash'
import {useDispatch, useSelector} from 'react-redux'
import {
  HeaderText,
  MonthRangePicker,
  Spinner,
  ButtonGroup,
  Button,
  Search,
} from 'components/elements'
import {ViewHeader} from 'components/layout'
import {Container as PageContainer, Filters, Main} from 'containers/Core'
import {
  fetch,
  setStartday,
  setEndday,
  setFilter,
  exportManually,
  exportAgain,
  taxIdExemption,
  setInvoicingStatus,
} from 'store/economyPlanner/actions'
import type {EconomyPlannerStore, RootStore} from 'store/types'
import type {ContractInvoicingStatusType, ContractNumber} from 'types/contract'
import type {PlannerEconomy} from 'types/planner'
import {AccountingList} from './List'

const stringIncludes = (input: string | number, query: string) =>
  typeof input === 'number'
    ? input.toString().includes(query)
    : input.includes(query)

const filterData = (data: PlannerEconomy[], queryRaw?: string) => {
  if (!queryRaw) {
    return data
  }

  const query = deburr(queryRaw.toLowerCase())

  return filter(data, (item) => {
    if (item.contractId && stringIncludes(item.contractId, query)) {
      return true
    }
    if (
      item.actName &&
      stringIncludes(deburr(item.actName.toLowerCase()), query)
    ) {
      return true
    }
    if (
      item.organizerName &&
      stringIncludes(deburr(item.organizerName.toLowerCase()), query)
    ) {
      return true
    }
    // if (item.taxId && stringIncludes(item.taxId, query)) {
    //   return true
    // }
    if (item.sapCustomerId && stringIncludes(item.sapCustomerId, query)) {
      return true
    }

    if (
      item.invoicingStatusExternalId &&
      stringIncludes(item.invoicingStatusExternalId, query)
    ) {
      return true
    }

    return false
  })
}

const useData = () => {
  const {startDay, endDay, filter, activity, data} = useSelector<
    RootStore,
    EconomyPlannerStore
  >((store) => store.economyPlanner)

  return {
    startDay: new Date(startDay),
    endDay: new Date(endDay),
    filter,
    activity,
    data,
  }
}

export const AccountingView = () => {
  const dispatch = useDispatch()
  const {startDay, endDay, filter, activity, data} = useData()
  const [query, setQuery] = useState('')

  const invokeDispatch = (action: PayloadAction<any>) => {
    setQuery('')
    dispatch(action)
  }

  const handleStartdayChange = (val: string | number | Date | null) => {
    if (!isDate(val) || !val) {
      return
    }
    invokeDispatch(setStartday(val.toString()))
    if (isBefore(endDay, val)) {
      invokeDispatch(setEndday(val.toString()))
    }
  }
  const handleEnddayChange = (val: string | number | Date | null) => {
    if (!isDate(val) || !val) {
      return
    }
    invokeDispatch(setEndday(val.toString()))
  }
  const handleSetFilter = (val: string) => invokeDispatch(setFilter(val))

  const handleExportManually = (contractId: ContractNumber) => {
    if (typeof contractId !== 'number') {
      return
    }
    invokeDispatch(exportManually({contractId}))
  }
  const handleExportAgain = (contractId: ContractNumber) => {
    if (typeof contractId !== 'number') {
      return
    }
    invokeDispatch(exportAgain({contractId}))
  }
  const handleTaxIdExemption = (id: number, name: string) =>
    invokeDispatch(taxIdExemption({id, name}))
  const handleInvoiceStatus = (
    id: number,
    status: ContractInvoicingStatusType
  ) => invokeDispatch(setInvoicingStatus({id, status}))

  useEffect(() => {
    dispatch(fetch())
  }, [dispatch])

  const filteredData = filterData(data, query)

  return (
    <PageContainer>
      <ViewHeader>
        <HeaderText primary='Accounting' />
      </ViewHeader>
      <Filters className='flex items-center gap-x-5'>
        <MonthRangePicker
          valueStart={startDay}
          onChangeStart={handleStartdayChange}
          valueEnd={endDay}
          onChangeEnd={handleEnddayChange}
          allowForever
        />
        <ButtonGroup>
          <Button
            onClick={() => handleSetFilter('pendingExport')}
            isSelected={filter === 'pendingExport'}
          >
            Pending export
          </Button>
          <Button
            onClick={() => handleSetFilter('exported')}
            isSelected={filter === 'exported'}
          >
            Pending invoice
          </Button>
          <Button
            onClick={() => handleSetFilter('invoiceSent')}
            isSelected={filter === 'invoiceSent'}
          >
            Invoice sent
          </Button>
          <Button
            onClick={() => handleSetFilter('invoicePaid')}
            isSelected={filter === 'invoicePaid'}
          >
            Invoice paid
          </Button>
        </ButtonGroup>
        <ButtonGroup>
          <Button
            onClick={() => handleSetFilter('noExport')}
            isSelected={filter === 'noExport'}
          >
            Invoice off
          </Button>
        </ButtonGroup>

        <Search
          value={query}
          onChange={setQuery}
          placeholder='Filter results...'
        />
      </Filters>
      <Main flex>
        {activity && <Spinner overlay />}
        <AccountingList
          data={filteredData}
          onExportManually={handleExportManually}
          onExportAgain={handleExportAgain}
          onTaxIdExemption={handleTaxIdExemption}
          onInvoiceStatus={handleInvoiceStatus}
        />
      </Main>
    </PageContainer>
  )
}
