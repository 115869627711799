import {PureComponent} from 'react'
import styled from 'styled-components'
import {SpinnerInline} from 'components/elements'
import {useContract} from 'hooks/useContract'
import type {PlannerStoreAsyncActionResult} from 'store/planner/types'
import {colors} from 'styles/variables'
import type {EventType} from 'types/event'
import type {PlannerEvent} from 'types/planner'
import {cnLegacy} from 'utils'
import {Actions} from './Actions'
import {Edit} from './Edit'
import {useCell} from './hooks'
import {Icon} from './Icon'
import {Show} from './Show'

interface ContainerProps {
  editing: boolean
  type?: EventType
}

function getColor(type?: EventType) {
  switch (type) {
    case 'EVENT_TYPE_OFF_DAY':
    case 'EVENT_TYPE_LOGISTICS_EVENT':
    case 'EVENT_TYPE_RESERVED_DATE':
      return colors.chatelle
  }
}

function getBg(type?: EventType) {
  switch (type) {
    case 'EVENT_TYPE_OFF_DAY':
      return colors.bridesMaid
    case 'EVENT_TYPE_LOGISTICS_EVENT':
      return colors.moonRaker
    case 'EVENT_TYPE_RESERVED_DATE':
      return colors.apricotWhite
  }
}

const Container = styled.div<ContainerProps>`
  margin: 0;
  min-height: 33px;
  position: relative;
  overflow: hidden;
  cursor: text;
  color: ${({type}) => getColor(type)};
  background-color: ${({type}) => getBg(type)};

  &:hover::before {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-shadow: inset 0 0 3px ${colors.bostonBlue};
  }

  ${({editing}) =>
    editing &&
    `
    box-shadow: inset 0 0 5px ${colors.pictonBlue};`}

  ${({type}) =>
    type === 'EVENT_TYPE_SHOW' &&
    `
    cursor: pointer;`}
`

const Loader = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.7);

  & > div {
    position: absolute;
    top: 7px;
    right: 7px;
  }
`

export type NewPlannerEvent = Extract<
  PlannerEvent,
  'type' | 'act' | 'start_on' | 'text'
>

type CalendarCellData = PlannerEvent | NewPlannerEvent

interface EventProps {
  data: CalendarCellData
  date: string
  actId?: number
  addNoteStart: () => void
  addNoteEnd: () => void
  showActName?: boolean
  className?: string
}

interface EventInnerProps extends EventProps {
  cellKey: string
  asyncActivity: boolean
  asyncResult: PlannerStoreAsyncActionResult
  deletePlanner: () => void
  openContract: () => void
}

class EventInner extends PureComponent<EventInnerProps> {
  state = {
    hovering: false,
    editing: false,
    saving: false,
  }

  UNSAFE_componentWillReceiveProps(nextProps: EventInnerProps) {
    if (nextProps.asyncActivity && !this.props.asyncActivity) {
      this.setState({saving: true})
    }
    if (!nextProps.asyncActivity && this.props.asyncActivity) {
      this.setState({saving: false})
      if (nextProps.asyncResult === 'SUCCESS') {
        this.setState({editing: false})
        this.props.addNoteEnd()
      } else if (nextProps.asyncResult === 'FAILURE') {
        this.setState({editing: true})
      }
    }
  }

  hideActions = () => {
    if (!this.state.hovering) {
      return true
    }
    return this.state.editing || this.state.saving
  }

  hideIcon = () => this.state.editing

  handleDelete = () => {
    this.props.deletePlanner()
  }

  handleStartEdit = () => this.setState({editing: true})
  handleStopEdit = () => this.setState({editing: false})

  handleMouseEnter = () => this.setState({hovering: true})
  handleMouseLeave = () => this.setState({hovering: false})

  handleClick = () => {
    if (this.state.editing) {
      return
    }

    const {
      data: {type},
    } = this.props

    if (
      [
        'EVENT_TYPE_NOTE_EVENT',
        'EVENT_TYPE_RESERVED_DATE',
        'EVENT_TYPE_OFF_DAY',
        'EVENT_TYPE_LOGISTICS_EVENT',
        'EMPTY_DAY',
      ].includes(type)
    ) {
      this.handleStartEdit()
    } else if (type === 'EVENT_TYPE_SHOW') {
      this.props.openContract()
    }
  }

  render() {
    const {
      data: {id, type, text},
      data,
      date,
      actId,
      showActName,
      cellKey,
      addNoteStart,
    } = this.props

    const content = !this.state.editing ? (
      <Show data={data} showActName={showActName} />
    ) : (
      <Edit
        data={data}
        cellKey={cellKey}
        value={text || ''}
        disabled={this.state.saving}
        onDelete={this.handleDelete}
        onCancel={this.handleStopEdit}
      />
    )

    return (
      <Container
        onClick={this.handleClick}
        className={cnLegacy('calendar-cell', this.props.className)}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        editing={this.state.editing}
        type={type}
      >
        {content}
        {this.state.saving && (
          <Loader>
            <SpinnerInline />
          </Loader>
        )}
        {!this.hideActions() && (
          <Actions
            type={type}
            id={id}
            date={date}
            actId={actId}
            cellKey={cellKey}
            onDelete={this.handleDelete}
            addNoteStart={addNoteStart}
          />
        )}
        {!this.hideIcon() && <Icon type={type} data={data} />}
      </Container>
    )
  }
}

export const Event = ({data, actId, date, ...rest}: EventProps) => {
  const {asyncActivity, asyncResult, cellKey, deletePlanner} = useCell(
    date,
    actId,
    data.type,
    data.id
  )
  const {openContract} = useContract()
  const handleOpenContract = () => openContract(data.contract_id)

  return (
    <EventInner
      {...rest}
      date={date}
      data={data}
      actId={actId}
      asyncActivity={asyncActivity}
      asyncResult={asyncResult}
      cellKey={cellKey}
      openContract={handleOpenContract}
      deletePlanner={deletePlanner}
    />
  )
}
