// This file is generated by icon spritesheet generator

export type IconName =
  | "zoom-out"
  | "zoom-in"
  | "warning"
  | "visible"
  | "van"
  | "user"
  | "underline"
  | "tiles"
  | "star"
  | "star-empty"
  | "stage"
  | "speaker"
  | "smiley"
  | "signed"
  | "setup"
  | "sent"
  | "search"
  | "search-results"
  | "sap-sales-order"
  | "sap-project-creation"
  | "safe"
  | "roskilde"
  | "road"
  | "report"
  | "refresh"
  | "promotion"
  | "plus"
  | "plus-16px"
  | "plus-13px"
  | "phone"
  | "pencil"
  | "pdf"
  | "paper"
  | "palm"
  | "notification"
  | "note"
  | "new-window"
  | "new-row"
  | "more"
  | "money"
  | "microphone"
  | "menu"
  | "mail"
  | "logout"
  | "lock"
  | "location"
  | "list"
  | "list-numbered"
  | "list-bullet"
  | "link"
  | "language"
  | "italic"
  | "invisible"
  | "info"
  | "indent-increase"
  | "indent-decrease"
  | "hour-glass"
  | "history"
  | "handle"
  | "group"
  | "final"
  | "filter"
  | "file-report-tour"
  | "file-report-tour-budget"
  | "file-report-royalty"
  | "file-report-financial"
  | "file-pdf"
  | "file-excel"
  | "export"
  | "excel"
  | "event"
  | "error"
  | "error-8"
  | "economy"
  | "earth"
  | "duplicate"
  | "drag"
  | "download"
  | "delete-16px"
  | "delete-13px"
  | "cvr"
  | "currency"
  | "contract"
  | "commission"
  | "commission-number"
  | "cog"
  | "close"
  | "claim"
  | "chevron-up"
  | "chevron-right"
  | "chevron-left"
  | "chevron-down"
  | "checkmark"
  | "checkmark-fill"
  | "checkered-flag"
  | "caret-up"
  | "caret-right"
  | "caret-left"
  | "caret-down"
  | "cards"
  | "capacity"
  | "cancel"
  | "calendar"
  | "calendar-one-month"
  | "calendar-months-in-columns"
  | "calendar-agenda"
  | "calendar-acts-in-columns"
  | "burger"
  | "bookmark"
  | "bold"
  | "board"
  | "bank"
  | "arrow-right"
  | "arrow-left"
  | "affiliation"
  | "abacus"

export const iconNames = [
  "zoom-out",
  "zoom-in",
  "warning",
  "visible",
  "van",
  "user",
  "underline",
  "tiles",
  "star",
  "star-empty",
  "stage",
  "speaker",
  "smiley",
  "signed",
  "setup",
  "sent",
  "search",
  "search-results",
  "sap-sales-order",
  "sap-project-creation",
  "safe",
  "roskilde",
  "road",
  "report",
  "refresh",
  "promotion",
  "plus",
  "plus-16px",
  "plus-13px",
  "phone",
  "pencil",
  "pdf",
  "paper",
  "palm",
  "notification",
  "note",
  "new-window",
  "new-row",
  "more",
  "money",
  "microphone",
  "menu",
  "mail",
  "logout",
  "lock",
  "location",
  "list",
  "list-numbered",
  "list-bullet",
  "link",
  "language",
  "italic",
  "invisible",
  "info",
  "indent-increase",
  "indent-decrease",
  "hour-glass",
  "history",
  "handle",
  "group",
  "final",
  "filter",
  "file-report-tour",
  "file-report-tour-budget",
  "file-report-royalty",
  "file-report-financial",
  "file-pdf",
  "file-excel",
  "export",
  "excel",
  "event",
  "error",
  "error-8",
  "economy",
  "earth",
  "duplicate",
  "drag",
  "download",
  "delete-16px",
  "delete-13px",
  "cvr",
  "currency",
  "contract",
  "commission",
  "commission-number",
  "cog",
  "close",
  "claim",
  "chevron-up",
  "chevron-right",
  "chevron-left",
  "chevron-down",
  "checkmark",
  "checkmark-fill",
  "checkered-flag",
  "caret-up",
  "caret-right",
  "caret-left",
  "caret-down",
  "cards",
  "capacity",
  "cancel",
  "calendar",
  "calendar-one-month",
  "calendar-months-in-columns",
  "calendar-agenda",
  "calendar-acts-in-columns",
  "burger",
  "bookmark",
  "bold",
  "board",
  "bank",
  "arrow-right",
  "arrow-left",
  "affiliation",
  "abacus",
] as const
