import type {ReactNode} from 'react'
import OtpInput from 'react-otp-input'
import type {ButtonProps} from 'components/elements'
import {Button, Tooltip} from 'components/elements'
import {Prompt} from 'components/utils/Prompt'
import type {ContractLanguage} from 'types/contract'
import {cnLegacy} from 'utils'
import {useDocumentSigning} from './helpers'
import type {DocumentSigningConfig} from './types'

export interface SignDocumentProps {
  config: DocumentSigningConfig
  onSuccess?: () => void
  buttonProps?: ButtonProps
  showSkipButton?: boolean
  language?: ContractLanguage
  phoneNumber: string
  signeeName: string
  tooltip?: ReactNode
  disabled?: boolean
}
export const SignDocument = ({
  config,
  onSuccess,
  buttonProps,
  showSkipButton,
  language,
  phoneNumber,
  signeeName,
  tooltip,
  disabled,
}: SignDocumentProps) => {
  const {
    code,
    setCode,
    pendingSigning,
    sendCode,
    sign,
    openSign,
    closeSign,
    isSendingCode,
    isSigning,
  } = useDocumentSigning(config, onSuccess)

  const prompt = pendingSigning && (
    <div className='fixed inset-0 z-[1000]'>
      <Prompt
        opened
        header={
          language === 'da'
            ? `Indtast den kode, vi har sendt dig via SMS til ${phoneNumber}`
            : `Type in the code we have sent you via SMS to ${phoneNumber}`
        }
        content={
          <div>
            <p className='mb-5'>
              {language === 'da'
                ? `Ved at indtaste koden og trykke på 'Bekræft' bekræfter du, at du er ${signeeName}, og du accepterer kontraktens vilkår i fuld udstrækning. Du accepterer endvidere, at kontrakten er juridisk bindende.`
                : `By entering the code and pressing 'Confirm' you confirm that you are ${signeeName}, and you agree to the terms of the contract to its full extend. You further more agree that the contract is legally binding.`}
            </p>
            <OtpInput
              value={code}
              onChange={setCode}
              numInputs={6}
              containerStyle={{
                display: 'grid',
                justifyContent: 'center',
                gridTemplateColumns: 'repeat(6, 12%)',
                columnGap: '0.5rem',
                overflow: 'hidden',
              }}
              renderInput={({style: _style, ...props}) => (
                <input
                  {...props}
                  className='w-full text-center text-bearSize py-5 px-2.5 focus:outline-bostonBlue border rounded'
                  inputMode='numeric'
                  autoComplete='one-time-code'
                />
              )}
              shouldAutoFocus
            />
          </div>
        }
        onCancel={closeSign}
        onConfirm={() => sign(code)}
        cancelText={language === 'da' ? 'Annullér' : 'Cancel'}
        confirmText={language === 'da' ? 'Bekræft' : 'Confirm'}
        confirmDisabled={code.length !== 6}
        confirmHasActivity={isSigning}
      />
    </div>
  )

  return (
    <>
      <div className='space-y-5'>
        <div className={cnLegacy('flex justify-end', showSkipButton && 'px-5')}>
          <Tooltip content={tooltip}>
            <Button
              {...buttonProps}
              onClick={() => sendCode()}
              hasActivity={isSendingCode}
              disabled={disabled}
            />
          </Tooltip>
        </div>
        {Boolean(showSkipButton && 'signingUrl' in config) && (
          <div className='border-t bg-springWood text-center py-2.5 px-5'>
            {language === 'da'
              ? 'Har du allerede en SMS kode? Så '
              : 'Already have a SMS code? Then '}
            <button
              onClick={() => openSign()}
              className='text-pictonBlue hover:underline'
              disabled={disabled}
            >
              {language === 'da' ? 'klik her' : 'click here'}
            </button>
          </div>
        )}
      </div>
      {prompt}
    </>
  )
}
