import type {PropsWithChildren} from 'react'
import {cnLegacy} from 'utils'
import styles from './container.module.css'

export interface MainProps extends PropsWithChildren {
  className?: string
  scroll?: 'x' | 'y' | boolean
  flex?: boolean
  grid?: boolean
}

export const Main = ({
  scroll = false,
  className,
  flex,
  grid,
  children,
}: MainProps) => (
  <div
    className={cnLegacy(
      {
        [styles.mainScroll]: scroll === true,
        [styles.mainScrollX]: scroll === 'x',
        [styles.mainScrollY]: scroll === 'y',
        [styles.mainFlex]: flex,
        [styles.mainGrid]: grid,
      },
      styles.main,
      className
    )}
  >
    {children}
  </div>
)
