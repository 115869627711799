import type {ReactNode} from 'react'
import {createElement, forwardRef} from 'react'
import styled, {css} from 'styled-components'
import {colors, effects, spacing, fontSizes} from 'styles/variables'
import {cnLegacy} from 'utils'
import type {IconName} from '../Icon'
import {Icon} from '../Icon'
import {SpinnerInline} from '../SpinnerInline'

export type ButtonStyleTheme =
  | 'reserveDate'
  | 'notAvailable'
  | 'logistics'
  | 'tour'
export type ButtonStyle =
  | 'primary'
  | 'secondary'
  | 'dangerous'
  | 'light'
  | 'text'
  | ButtonStyleTheme
  | undefined
export type ButtonSize = 'big' | 'normal' | 'small'
export type ButtonWidth = 'fit' | 'fill' | 'fixed' | 'fixedDouble'
export type ButtonAlignment = 'left' | 'center' | 'right'

interface GetStyleCssProps {
  disabled: boolean
  active: boolean
}

const handleDisabledAndActive = (cssCode: string) => {
  return css<GetStyleCssProps>`
    ${({disabled}) =>
      !disabled &&
      `
    &:active {
      ${cssCode}
    }`}
    ${({disabled, active}) =>
      !disabled &&
      active &&
      `
      ${cssCode}
    `}
  `
}

const getThemeBg = (btnStyle: ButtonStyleTheme) => {
  if (btnStyle === 'reserveDate') {
    return colors.apricotWhite
  } else if (btnStyle === 'notAvailable') {
    return colors.bridesMaid
  } else if (btnStyle === 'logistics') {
    return colors.moonRaker
  } else if (btnStyle === 'tour') {
    return colors.babyBlue
  }
}

const getIconSize = (size: ButtonSize) => {
  if (size === 'big') {
    return 18
  } else if (size === 'small') {
    return 8
  } else {
    return
  }
}

const getStyleCss = (btnStyle: ButtonStyle) => {
  if (btnStyle === 'primary') {
    return css<GetStyleCssProps>`
      color: ${colors.white};
      background-color: ${colors.gossamer};
      border: 1px solid ${colors.gossamer};
      ${({disabled}) =>
        !disabled &&
        `
        &:hover {
          background-color: ${colors.observatory};
          border: 1px solid ${colors.observatory};
          box-shadow: 0 0 0 2px ${colors.caribbeanGreen};
          color: ${colors.white};
        }
        &:focus {
          border: 1px solid ${colors.gossamer};
        }`}
      ${handleDisabledAndActive(
        `
        background-color: ${colors.watercourse};
        border: 1px solid ${colors.gossamer};
        box-shadow: 0 0 0 2px ${colors.caribbeanGreen};`
      )}
    `
  } else if (btnStyle === 'secondary') {
    return css<GetStyleCssProps>`
      background-color: ${colors.springWood};
      ${({disabled}) =>
        !disabled &&
        `
        &:hover {
          background-color: ${colors.springWood};
        }`}
      ${handleDisabledAndActive(
        `
        background-color: ${colors.desertStorm};`
      )}
    `
  } else if (btnStyle === 'dangerous') {
    return css<GetStyleCssProps>`
      color: ${colors.white};
      background-color: ${colors.crimson};
      ${({disabled}) =>
        !disabled &&
        `
        &:hover {
          background-color: ${colors.crimson};
          border: 1px solid ${colors.observatory};
          box-shadow: 0 0 0 2px ${colors.pippin};
          color: ${colors.white};
        }
        &:focus {
          border: 1px solid ${colors.amaranth};
        }`}
      ${handleDisabledAndActive(
        `
        background-color: ${colors.thunderbird};
        border: 1px solid ${colors.observatory};
        box-shadow: 0 0 0 2px ${colors.pippin};`
      )}
    `
  } else if (btnStyle === 'light') {
    return css<GetStyleCssProps>`
      border: 1px solid transparent;
      ${({disabled}) =>
        !disabled &&
        `
        &:hover {
          background-color: ${colors.springWood};
          border: 1px solid ${colors.alto};
          color: ${colors.midGray};
          box-shadow: none;
        }`}
      ${handleDisabledAndActive(
        `
        background-color: ${colors.desertStorm};`
      )}
    `
  } else if (btnStyle === 'text') {
    return css`
      border: none;
      outline: none;
      background: none;
      font-size: ${fontSizes.dogSize};
      display: inline-block;
      border-radius: ${effects.borderRadius};
      line-height: 30px;
      padding: 0 ${spacing.u2_5};
      text-decoration: none;
      user-select: none;
      color: ${colors.pictonBlue};
      ${({disabled}) =>
        !disabled &&
        `
        cursor: pointer;
        &:hover {
          color: ${colors.bostonBlue};
          border: none;
          outline: none;
          box-shadow: none;
        }`}
      ${handleDisabledAndActive(
        `
        border: 0;
        box-shadow: none;
        background-color: ${colors.hawkesBlue};`
      )}
    `
  } else if (
    btnStyle === 'reserveDate' ||
    btnStyle === 'notAvailable' ||
    btnStyle === 'logistics' ||
    btnStyle === 'tour'
  ) {
    return css`
      text-align: left;
      ${({disabled}) =>
        !disabled &&
        `
        cursor: pointer;
        &:hover {
          background-color: ${colors.white};
        }`}
      ${handleDisabledAndActive(
        `
        background-color: ${colors.desertStorm};`
      )}
      background: ${getThemeBg(btnStyle)};
    `
  }
}

const getSpinnerColor = (theme: ButtonStyle) => {
  if (theme && ['primary', 'dangerous'].includes(theme)) {
    return 'light'
  }
  return 'dark'
}

interface BaseButtonProps {
  disabled: boolean
  active: boolean
  selected: boolean
  btnStyle: ButtonStyle
}
const BaseButton = css<BaseButtonProps>`
  position: relative;
  height: 32px;
  outline: none;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  font-size: ${fontSizes.dogSize};
  border-radius: ${effects.borderRadius};
  line-height: 30px;
  padding: 0 ${spacing.u2_5};
  text-decoration: none;
  white-space: nowrap;
  user-select: none;
  transition: border 0.2s, box-shadow 0.2s, color 0.2s, background-color 0.2s;
  cursor: pointer;

  color: ${colors.midGray};
  background-color: ${colors.white};
  border: ${effects.inputBorder};
  ${({disabled}) =>
    disabled &&
    `
    opacity: ${effects.disabledOpacity};
    cursor: default;`}

  ${({disabled}) =>
    !disabled &&
    `
    &:hover {
      color: ${colors.pictonBlue};
      border: ${effects.inputHoverBorder};
      box-shadow: ${effects.inputHoverBoxShadow};
    }
    &:focus {
      box-shadow: ${effects.focusBoxShadow};
    }`}

  ${handleDisabledAndActive(
    `
      color: ${colors.pictonBlue};
      border: solid 1px ${colors.bostonBlue};
      box-shadow: 0 0 0 2px ${colors.pictonBlue};
      background-color: ${colors.springWood};`
  )}


  ${({btnStyle}) => getStyleCss(btnStyle)}

  ${({selected}) =>
    selected &&
    `
    border-color: var(--bostonBlue);
    background-color: var(--pictonBlue);
    color: var(--white);`}
  ${({selected, disabled}) =>
    !disabled &&
    selected &&
    `
    &:hover {
      color: var(--white);
      border-color: var(--bostonBlue) !important;
    }`}
`

interface RoundIconButtonProps {
  disabled: boolean
  active: boolean
  size: ButtonSize
}
const RoundIconButton = css<RoundIconButtonProps>`
  padding: 4px;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  box-sizing: border-box;
  line-height: 0;

  ${handleDisabledAndActive(
    `
    border: solid 1px ${colors.bostonBlue};
    box-shadow: 0 0 0 2px ${colors.pictonBlue};
    background-color: ${colors.springWood};`
  )}

  ${({size}) =>
    size === 'big' &&
    `
    width: 32px;
    height: 32px;
    padding: 6px;`}
  ${({size}) =>
    size === 'small' &&
    `
    width: 16px;
    height: 16px;
    padding: 3px;`}
`

interface ButtonAlignmentProps {
  alignment: ButtonAlignment
}
const ButtonAlignmentConfig = css<ButtonAlignmentProps>`
  ${({alignment}) =>
    alignment === 'left' &&
    `
  text-align: left;
  justify-content: flex-start;`}
  ${({alignment}) =>
    alignment === 'center' &&
    `
  text-align: center;
  justify-content: center;`}
  ${({alignment}) =>
    alignment === 'right' &&
    `
  text-align: right;
  justify-content: flex-end;`}
`

interface ButtonWidthProps {
  width: ButtonWidth
}
const ButtonWidthConfig = css<ButtonWidthProps>`
  ${({width}) =>
    width === 'fill' &&
    `
    min-width: auto;
    width: 100%;`}
  ${({width}) =>
    width === 'fixed' &&
    `
    min-width: 120px;`}
  ${({width}) =>
    width === 'fixedDouble' &&
    `
    min-width: 240px;`}
`

interface StyledButtonProps {
  disabled: boolean
  active: boolean
  selected: boolean
  btnStyle: ButtonStyle
  roundIcon: boolean
  size: ButtonSize
  alignment: ButtonAlignment
  width: ButtonWidth
}

const ButtonButton = styled.button<StyledButtonProps>`
  ${BaseButton};
  ${({roundIcon}) => roundIcon && RoundIconButton};
  ${ButtonAlignmentConfig};
  ${ButtonWidthConfig};
`

interface ContentProps {
  truncate: boolean
}
const Content = styled.div<ContentProps>`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  ${({truncate}) =>
    truncate &&
    `
    overflow: hidden;
    & > div {
      overflow: hidden;
      text-overflow: ellipsis;
    }`}
`
interface BntIconProps {
  hasLabel: boolean
  size?: number
}
const BntIcon = styled(Icon)<BntIconProps>`
  position: static;
  margin-right: ${({hasLabel}) => (hasLabel ? spacing.u1_5 : 0)};
`
interface ActivitySpinnerProps {
  roundIcon: boolean
  activitySpinnerOverlay: boolean
}
const ActivitySpinner = styled(SpinnerInline)<ActivitySpinnerProps>`
  ${({roundIcon}) =>
    roundIcon &&
    `
    position: absolute;
    left: 50%;
    transform: translateX(-50%);`}

  ${({roundIcon, activitySpinnerOverlay}) =>
    !roundIcon && !activitySpinnerOverlay
      ? `
        margin-left: var(--spacing-u2_5);`
      : `
        position: absolute;
        left: 50%;
        transform: translateX(-50%);`}
`

export interface ButtonProps {
  children?: React.ReactNode | React.ReactNode[]
  className?: string
  disabled?: boolean
  to?: any
  icon?: IconName
  label?: ReactNode
  active?: boolean
  isSelected?: boolean
  hasActivity?: boolean
  activitySpinnerOverlay?: boolean
  round?: boolean
  truncate?: boolean
  theme?: ButtonStyle
  size?: ButtonSize
  width?: ButtonWidth
  alignment?: ButtonAlignment
  tabIndex?: number | string
  onClick?: (e: React.MouseEvent) => void
  type?: 'button' | 'submit' | 'reset'
  modal?: boolean
  title?: string
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      to,
      icon,
      label,
      active,
      isSelected,
      hasActivity,
      activitySpinnerOverlay,
      disabled,
      round,
      truncate,
      theme,
      size = 'normal',
      width = 'fit',
      alignment = 'center',
      tabIndex,
      onClick,
      title,
      ...rest
    },
    ref
  ) => {
    const roundIcon = icon && round
    const isDisabled = disabled || hasActivity

    if (to) {
      console.info(
        `Button: 'to' is deprecated. Please use '<LinkButton />'`,
        to
      )
    }

    return createElement(
      ButtonButton,
      {
        ref,
        ...rest,
        className: cnLegacy(
          'whnue-button',
          {
            'whnue-button-active': active,
            'whnue-button-isSelected': isSelected,
          },
          className
        ),
        disabled: isDisabled,
        tabIndex: isDisabled ? '-1' : tabIndex,
        btnStyle: theme,
        onClick,
        roundIcon,
        size,
        active,
        selected: isSelected,
        alignment,
        width,
        title,
      },
      <Content truncate={!!truncate}>
        {icon && (
          <BntIcon hasLabel={!!label} icon={icon} size={getIconSize(size)} />
        )}
        {label && <div>{label}</div>}
        {children}
        {hasActivity && (
          <ActivitySpinner
            roundIcon={!!roundIcon}
            activitySpinnerOverlay={!!activitySpinnerOverlay}
            kind={getSpinnerColor(theme)}
          />
        )}
      </Content>
    )
  }
)

Button.displayName = 'Button'
