import {Icon} from 'components/elements'
import {cnLegacy} from 'utils'
import type {IconName} from '../Icon'

export type AvatarKind = 'person' | 'organizer'

const getIcon = (kind: AvatarKind): IconName => {
  if (kind === 'organizer') {
    return 'phone'
  } else {
    return 'user'
  }
}

interface AvatarProps {
  size?: number
  kind: AvatarKind
  className?: string
}

export const Avatar: React.FC<React.PropsWithChildren<AvatarProps>> = ({
  size = 32,
  kind,
  className,
}) => {
  return (
    <div
      className={cnLegacy('grid place-items-center text-secondary', className)}
    >
      <div
        className='grid place-items-center border border-current rounded-full'
        style={{width: `${size}px`, height: `${size}px`}}
      >
        <Icon icon={getIcon(kind)} size={Math.floor(size * 0.5)} />
      </div>
    </div>
  )
}
