import * as Sentry from '@sentry/react'
import {all, spawn, call, put} from 'redux-saga/effects'
import {contractSaga} from './contract/saga'
import {economyPlannerSaga} from './economyPlanner/saga'
import {entitySaga} from './entity/saga'
import {flowSaga} from './flow/saga'
import {formSubmitSaga} from './form'
import {plannerSaga} from './planner/saga'
import {promptSaga} from './prompt'

export function* rootSaga() {
  // https://github.com/redux-saga/redux-saga/pull/644#issuecomment-272236599
  yield all(
    [
      contractSaga,
      economyPlannerSaga,
      entitySaga,
      flowSaga,
      plannerSaga,
      formSubmitSaga,
      promptSaga,
    ].map((saga) =>
      spawn(function* () {
        let isSyncError = false
        while (!isSyncError) {
          isSyncError = true
          try {
            // eslint-disable-next-line no-loop-func
            setTimeout(() => (isSyncError = false))
            yield call(saga)
            break
          } catch (e: any) {
            if (isSyncError) {
              console.log('--saga error')
              console.log(saga)

              console.error(e)
              // TODO: Handle this error somewhere
              throw new Error(
                saga.name +
                  ' was terminated because it threw an exception on startup.'
              )
            }
            console.log('SAGA GLOBAL HANDLER REACHED')
            // Run it through the error resolver
            // const resolvedError = yield call(resolveError, e)
            // If the error was not handled, report it to sentry
            // if (!resolvedError.handled) {
            // console.log('CALL SENTRY')
            // const {message, ...extra} = resolvedError
            Sentry.captureException(e)
            if (import.meta.env.DEV) {
              console.error(e)
            }
            // }
            // Broadcast that saga has been restarted
            yield put({type: 'SAGA_RESTART', saga: saga.name})
          }
        }
      })
    )
  )
}
