import {isAfter} from 'date-fns'
import {Time} from 'components/elements'
import {EmailContract} from './Email'
import {useContractActions} from '../helpers'

export const PromiseeSign = () => {
  const {document} = useContractActions()

  if (!document?.promisee.invitation_expiry_at) {
    return null
  }

  const expiresAt = document?.promisee.invitation_expiry_at

  if (isAfter(new Date(), expiresAt)) {
    return (
      <div className='space-y-2'>
        <div className='font-bold'>Invitation expired</div>
        <EmailContract action='sendSigningLink' />
      </div>
    )
  }

  return (
    <div>
      <div className='font-bold'>Awaiting signature</div>
      <div className='text-catSize text-secondary'>
        <div>Invitation expires:</div>
        <Time
          obj={expiresAt}
          format='DateShortWithTime'
          className='font-semibold'
        />
      </div>
    </div>
  )
}
