import {Link} from 'react-router-dom'
import type {IconName} from 'components/elements'
import {Icon} from 'components/elements'
import type {AvatarKind} from 'components/elements/Avatar'
import {Avatar} from 'components/elements/Avatar'
import {cnLegacy} from 'utils'
import {useAppNavigation} from './hooks'

export const MenuItem = ({
  to,
  active,
  icon,
  title,
  onClick,
}: {
  to: string
  active: boolean
  icon: IconName
  title: string
  onClick?: () => void
}) => {
  const {maximized} = useAppNavigation()

  return (
    <Link
      to={to}
      onClick={onClick}
      className={cnLegacy(
        'flex items-center text-wolfSize relative',
        'py-2.5 md:py-0 md:h-14',
        'before:absolute before:inset-y-0 before:left-0 before:w-1',
        active
          ? 'bg-pictonBlue md:bg-transparent text-white before:bg-pictonBlue md:before:bg-pictonblue cursor-default'
          : 'text-midGray md:hover:text-pictonBlue md:hover:before:bg-pictonBlue md:hover:before:bg-opacity-50 md:before:transition-colors'
      )}
    >
      <div className='flex-[0_0_56px] grid place-items-center transition-colors'>
        <Icon icon={icon} size={20} />
      </div>
      <div
        className={cnLegacy(
          'flex-none',
          maximized ? 'md:opacity-100' : 'md:opacity-0',
          'md:transition-colors'
        )}
      >
        {title}
      </div>
    </Link>
  )
}

export const UserMenuSectionHeader = ({
  avatar,
  primary,
  secondary,
}: {
  avatar: AvatarKind
  primary: string
  secondary?: string
}) => {
  return (
    <div className='px-2.5 flex items-center my-4 text-left'>
      <Avatar kind={avatar} size={48} />
      <div className='ml-2.5'>
        <p className='font-bold'>{primary}</p>
        {!!secondary && <p className='text-secondary'>{secondary}</p>}
      </div>
    </div>
  )
}

export const UserMenuButton = ({
  active,
  className,
  ...props
}: {active?: boolean} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>) => {
  return (
    <button
      {...props}
      className={cnLegacy(
        'w-full flex justify-between items-center',
        'my-1.5 py-1.5 px-2.5',
        'outline-none',
        'border border-transparent rounded',
        'hover:border-primaryBorderColor hover:bg-springWood',
        'focus:border-primaryBorderColor focus:bg-springWood',
        'active:border-pictonBlue active:outline-none',
        'disabled:opacity-40 cursor-default disabled:bg-transparent disabled:border-transparent',
        active ? 'border-bostonBlue bg-hawkesBlue' : '',
        'transition-all',
        className
      )}
    />
  )
}
