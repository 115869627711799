import {useState} from 'react'
import {Icon, MobileMenu} from 'components/elements'
import {useIsDesktop} from 'hooks/useIsDesktop'
import {UserMenuButton, UserMenuSectionHeader} from './components'
import {useOrganizationSwitcher} from './hooks'

interface HoverMenuTargetProps {
  isActive: boolean
  onMouseEnter: () => void
}
interface HoverMenuProps {
  target: (targetProps: HoverMenuTargetProps) => React.ReactElement
  content: () => React.ReactElement
}

const HoverMenu = ({target, content}: HoverMenuProps) => {
  const [showMenu, setShowMenu] = useState(false)

  return (
    <div onMouseLeave={() => setShowMenu(false)} className='relative'>
      {target({isActive: showMenu, onMouseEnter: () => setShowMenu(true)})}
      {showMenu && (
        <div className='absolute top-0 right-0 translate-x-full pl-5 z-dropdown'>
          {content()}
        </div>
      )}
    </div>
  )
}

export const OrganizationSwitcher = () => {
  const isDesktop = useIsDesktop()
  const {bookingAgencies, switchBookingAgency} = useOrganizationSwitcher()

  const content = (
    <div className='p-2.5 md:bg-white md:border md:rounded md:shadow-popover'>
      {bookingAgencies.map((ba) => (
        <UserMenuButton
          key={ba.id}
          onClick={() => {
            switchBookingAgency(ba.organization.id)
          }}
        >
          <UserMenuSectionHeader
            avatar='organizer'
            primary={ba.organization.name}
            secondary='Booking agency'
          />
        </UserMenuButton>
      ))}
    </div>
  )

  if (!isDesktop) {
    return (
      <MobileMenu
        title='Switch organization'
        target={({openMenu}) => (
          <UserMenuButton onClick={openMenu}>
            Switch organization
            <Icon icon='chevron-right' />
          </UserMenuButton>
        )}
      >
        {() => content}
      </MobileMenu>
    )
  }

  return (
    <HoverMenu
      target={({isActive, onMouseEnter}: HoverMenuTargetProps) => (
        <UserMenuButton active={isActive} onMouseEnter={onMouseEnter}>
          Switch organization
          <Icon icon='chevron-right' />
        </UserMenuButton>
      )}
      content={() => content}
    />
  )
}
