import type {IconName} from 'components/elements/Icon'
import {
  jobListExportQueryParamsFormatter,
  accountingExportQueryParamsFormatter,
  actCommissionExportQueryParamsFormatter,
  actRoyaltyExportQueryParamsFormatter,
  navisionExportQueryParamsFormatter,
} from 'helpers/queryParams'
import type {
  ReportCardParameters,
  ReportCardParams,
  ReportCards,
} from '../types/reportCards'

export interface ReportCardOption {
  disabled?: boolean
  icon: IconName
  title: string
  description: string
  url?: string
  link?: string
  parameters: ReportCardParameters[]
  urlParams?: ReportCardParams[]
  queryParams?: ReportCardParams[]
  requiredParams?: ReportCardParams[]
  queryParamsFormatter?: () => unknown
}

type ReportCardOptions = {[key in ReportCards]: ReportCardOption}

export const REPORT_CARD_OPTIONS: ReportCardOptions = {
  TOUR_JOB_LIST: {
    icon: 'file-report-tour',
    title: 'Job list',
    description: 'A spredsheet of the jobs on this tour',
    url: '/events/tours/{tourId}/export/',
    parameters: ['tour'],
    urlParams: ['tourId'],
    requiredParams: ['tourId'],
  },
  JOB_LIST: {
    icon: 'file-report-tour',
    title: 'Job list',
    description: 'A spredsheet of the jobs on a tour or a timespan',
    url: '/reports/job-list/',
    parameters: ['act', 'dayrange', 'tour', 'showsOnly'],
    queryParams: [
      'actId',
      'tourId',
      'startDay',
      'endDay',
      'showsOnly',
      'dateRangeType',
    ],
    requiredParams: ['actId', 'startDay', 'endDay', 'dateRangeType'],
    queryParamsFormatter: jobListExportQueryParamsFormatter,
  },
  TOUR_FINANCIAL: {
    icon: 'file-report-tour-budget',
    title: 'Tour budget',
    description:
      'A template with all jobs on a tour, ready to fill in missing costs',
    url: '/reports/tour-budget/{tourId}/',
    parameters: ['act', 'tour'],
    urlParams: ['tourId'],
    requiredParams: ['tourId'],
  },
  FINANCIAL_OVERVIEW: {
    icon: 'file-excel',
    title: 'General overview',
    description:
      'A compiled list of jobs for one or more acts on a tour or a specified time span with all relevant numbers and amounts',
    url: '/reports/general-overview/',
    parameters: ['acts', 'organizers', 'dayrange', 'tour'],
    queryParams: [
      'actIds',
      'organizerIds',
      'tourId',
      'startDay',
      'endDay',
      'dateRangeType',
    ],
    requiredParams: ['startDay', 'endDay'],
    queryParamsFormatter: accountingExportQueryParamsFormatter,
  },
  ACT_COMMISSION: {
    icon: 'report',
    title: 'Act Commission',
    description:
      'See the commission per act per month, to gain insight when creating the budget',
    url: '/reports/act-commission/',
    parameters: ['dayrange'],
    queryParams: ['startDay', 'endDay', 'dateRangeType'],
    requiredParams: ['startDay', 'endDay'],
    queryParamsFormatter: actCommissionExportQueryParamsFormatter,
  },
  ACT_ROYALTY: {
    icon: 'file-report-royalty',
    title: 'Act Royalty',
    description:
      'Help your acts report to eg. KODA, by handing them an overview of their gigs',
    url: '/reports/act-royalties/',
    parameters: ['act', 'dayrange', 'tour'],
    queryParams: ['actId', 'tourId', 'startDay', 'endDay', 'dateRangeType'],
    requiredParams: ['startDay', 'endDay'],
    queryParamsFormatter: actRoyaltyExportQueryParamsFormatter,
  },
  NAVISION_EXPORT: {
    icon: 'file-report-financial',
    link: '/reports/export/navision',
    title: 'Navision export',
    description:
      'Download a CSV file, ready to import into Navision, with all the latest data',
    url: '/financial/navision/exports/',
    parameters: ['dayrange'],
    queryParams: ['startDay', 'endDay', 'dateRangeType'],
    requiredParams: ['startDay', 'endDay'],
    queryParamsFormatter: navisionExportQueryParamsFormatter,
  },
  SAP_PROJECT_EXPORT: {
    icon: 'sap-project-creation',
    link: '/reports/export/sap-project',
    title: 'SAP Project Creation',
    description:
      'Download a CSV file, ready to import into SAP, with all projects that needs to be created',
    url: '/financial/sap/project-exports/',
    parameters: ['dayrange'],
    queryParams: ['startDay', 'endDay', 'dateRangeType'],
    requiredParams: ['startDay', 'endDay'],
    queryParamsFormatter: navisionExportQueryParamsFormatter,
  },
  SAP_EXPORT: {
    icon: 'sap-sales-order',
    link: '/reports/export/sap-sales',
    title: 'SAP Sales Order',
    description:
      'Download a CSV file, ready to import into SAP, with sales orders matched up with existing projects',
    url: '/financial/sap/exports/',
    parameters: ['dayrange'],
    queryParams: ['startDay', 'endDay', 'dateRangeType'],
    requiredParams: ['startDay', 'endDay'],
    queryParamsFormatter: navisionExportQueryParamsFormatter,
  },
}
