import {cnLegacy} from 'utils'
import styles from './Form.module.css'
import type {GridHOCProps} from './gridHOC'
import {gridHOC} from './gridHOC'

interface CellInnerProps {
  className?: string
}
export type CellProps = GridHOCProps<CellInnerProps>

const CellInner: React.FC<React.PropsWithChildren<CellProps>> = ({
  children,
  wrapperClassName,
  className,
}) => {
  return (
    <div className={cnLegacy(styles.cell, wrapperClassName, className)}>
      {children}
    </div>
  )
}

export const Cell = gridHOC(CellInner)
