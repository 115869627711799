import {replaceTokens} from 'utils'
import type {
  EmailAction,
  EmailerComposeProps,
  EmailInitResponse,
  EmailStore,
} from './types'

function buildDefaultActions(name?: string): EmailAction[] {
  return [
    {
      action: 'default',
      name: name ?? 'Send',
      buttonProps: {
        primary: true,
        fixed: true,
        wide: true,
      },
    },
  ]
}

export const buildStore = (
  data: EmailInitResponse,
  props: EmailerComposeProps
): EmailStore => {
  const {tokens, template} = data

  if (!template) {
    throw new Error('Email template missing!')
  }

  const subject = replaceTokens(tokens, template.subject)

  const html_body = replaceTokens(tokens, template.html_body, {
    transformers: {
      signing_key: (value) =>
        `${window.location.origin}/app/contract-sign/${value}`,
    },
  })

  return {
    ...props,
    recipients: data.recipients,
    available_recipients: data.available_recipients,
    attachments: data.attachments,
    subject: subject,
    html_body: html_body,
    signature: data.signature,
    url: props.url,
    actions: buildDefaultActions(props.sendBtnText),
  }
}
