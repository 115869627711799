import {useAtomValue} from 'jotai'
import {useDispatch} from 'react-redux'
import {initErrorsAtom} from 'app/modal/Email/atoms'
import {Button, ButtonMenu} from 'components/elements'
import {useConfig} from 'hooks/useConfig'
import {changeState} from 'store/contract/actions'
import type {
  AdvanceOption,
  AdvanceOptionWithState,
} from 'types/contractConfigurations'
import {CancelContract} from '../Actions/Cancel'
import {EmailContract} from '../Actions/Email'
import {MissingOrganizerContactData} from '../Actions/MissingOrganizerContactData'
import {MissingOrganizerId} from '../Actions/MissingOrganizerId'
import {PromiseeSign} from '../Actions/PromiseeSign'
import {PromisorSign} from '../Actions/PromisorSign'
import {useContractFlow} from '../helpers'

const FILTER_KEYS = 'sendSigningLink'

const useAdvanceOptions = () => {
  const {digisign} = useConfig()
  const {advanceOptions} = useContractFlow()

  const options =
    (digisign
      ? advanceOptions
      : advanceOptions?.filter((option) => {
          if ('action' in option) {
            return !FILTER_KEYS.includes(option.action)
          }
          return true
        })) ?? []

  let primary
  let secondary
  if (options[0] && 'action' in options[0] && options[0].action === 'cancel') {
    secondary = options
  } else {
    primary = options[0]
    secondary = options.slice(1)
  }

  return {
    primary,
    secondary: digisign ? secondary.reverse() : secondary,
  }
}

const StateChangeButton = ({newState, label, icon}: AdvanceOptionWithState) => {
  const dispatch = useDispatch()
  const {isNew, isChangingState, stateChange} = useContractFlow()

  const handleChangeState = () => dispatch(changeState(newState))

  return (
    <Button
      onClick={() => handleChangeState()}
      disabled={isNew || isChangingState}
      hasActivity={stateChange === newState && isChangingState}
      activitySpinnerOverlay
      width='fill'
      theme='secondary'
      label={label}
      icon={icon}
      className='first-letter:uppercase'
    />
  )
}

interface AdvanceActionProps {
  options?: AdvanceOption
  onClick?: () => void
}
const AdvanceAction = ({options, onClick}: AdvanceActionProps) => {
  if (!options) {
    return null
  }

  if ('newState' in options) {
    return <StateChangeButton {...options} />
  }

  if ('action' in options) {
    switch (options.action) {
      case 'sendSigningLink':
        return <EmailContract action='sendSigningLink' onClick={onClick} />
      case 'sendPdfAndMarkAsSent':
        return <EmailContract action='sendPdfAndMarkAsSent' onClick={onClick} />
      case 'cancel':
        return <CancelContract onClick={onClick} />
      case 'awaitingPartialSignature':
        return <PromiseeSign />
      case 'sign':
        return <PromisorSign />
      case 'retractSigning':
        return <EmailContract action='retractSigning' onClick={onClick} />
      default:
        return null
    }
  }

  return null
}

const PrimaryAction = () => {
  const {primary} = useAdvanceOptions()
  if (!primary) {
    return <div></div>
  }

  return <AdvanceAction options={primary} />
}

const SecondaryActions = () => {
  const {secondary} = useAdvanceOptions()

  if (!secondary.length) {
    return null
  }

  return (
    <ButtonMenu icon='more' position='right' flipped autoClose='onClickInject'>
      {secondary.map((option) => (
        <AdvanceAction key={JSON.stringify(option)} options={option} />
      ))}
    </ButtonMenu>
  )
}

const InitErrors = () => {
  const errors = useAtomValue(initErrorsAtom)
  if (!errors) {
    return null
  }
  const keys = Object.keys(errors)

  return (
    <div className='absolute top-0 inset-x-5 -translate-y-full rounded border-amaranth bg-white border-2'>
      <div className='bg-amaranth bg-opacity-20 text-tamarillo pt-1.5 pb-3 px-2'>
        <h3 className='text-wolfSize'>Please fix these issues</h3>
        <ul className='divide-y space-y-3 divide-amaranth'>
          {keys.map((key) => (
            <li key={key} className='pt-3 space-y-2'>
              <div>{errors[key]}</div>
              {key === 'ORGANIZER_MISSING_TAX_ID' && <MissingOrganizerId />}
              {key === 'DOCUMENT_PROMISEE_SIGNEE_PHONE_NUMBERS' && (
                <MissingOrganizerContactData />
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export const ContractFlowActions = () => {
  const {status} = useContractFlow()

  if (!status) {
    return null
  }

  return (
    <div className='grid grid-cols-[1fr_auto] gap-x-2.5 items-center p-5 relative'>
      <PrimaryAction />
      <SecondaryActions />
      <InitErrors />
    </div>
  )
}
