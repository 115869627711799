import {Children, useContext} from 'react'
import {cnLegacy} from 'utils'
import {TabsContext} from './context'
import {getKindStyle} from './helpers'
import styles from './Tabs.module.css'
import type {TabsType} from './types'

interface TabListProps {
  kind?: TabsType
  className?: string
  hideSingleTab?: boolean
}

export const TabList: React.FC<React.PropsWithChildren<TabListProps>> = ({
  kind,
  className,
  children,
  hideSingleTab,
}) => {
  const {kind: contextKind} = useContext(TabsContext)
  return (
    <section
      className={cnLegacy(
        getKindStyle(kind ?? contextKind),
        styles.tabList,
        {
          [styles.tabListHideSingle]:
            hideSingleTab && Children.count(children) <= 1,
        },
        className
      )}
    >
      {children}
    </section>
  )
}
