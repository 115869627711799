import {isNil} from 'lodash'
import type {ReactDatePickerProps} from 'react-datepicker'
import ReactDatePicker from 'react-datepicker'
import {isoStringToDate, dateToIsoString} from 'helpers/date'
import 'react-datepicker/dist/react-datepicker.css'
import type {FormFieldBaseProps} from 'types/form'
import {cnLegacy} from 'utils'
import styles from './DatePicker.module.css'

const getValue = (propValue: unknown): Date | null =>
  isNil(propValue) ? null : isoStringToDate(propValue)

const setValue = (newValue: unknown): string | null =>
  isNil(newValue) ? null : dateToIsoString(newValue)

interface DatePickerProps
  extends Omit<
      ReactDatePickerProps,
      'value' | 'onChange' | 'minDate' | 'maxDate'
    >,
    FormFieldBaseProps<string | null> {
  minDate?: string
  maxDate?: string
  dateFormat?: string
  placeholder?: string
}

export const DatePicker: React.FC<React.PropsWithChildren<DatePickerProps>> = ({
  value,
  onChange,
  minDate,
  maxDate,
  placeholder,
  className,
  disabled,
  dateFormat = 'do MMM yyyy',
  ...rest
}) => {
  const handleChange = (newValue: Date | [Date, Date] | null) =>
    onChange(setValue(newValue))

  return (
    <div
      className={cnLegacy(
        styles.root,
        {
          [styles.disabled]: disabled,
        },
        className
      )}
    >
      <ReactDatePicker
        {...rest}
        selected={getValue(value)}
        onChange={handleChange}
        minDate={getValue(minDate)}
        maxDate={getValue(maxDate)}
        placeholderText={placeholder}
        disabled={disabled}
        className={styles.input}
        dateFormat={dateFormat}
      />
    </div>
  )
}
