import styled from 'styled-components'
import {DocumentDownloader} from 'components/connected'
import type {IconName} from 'components/elements'
import {Icon as IconComp, Tooltip, SpinnerInline} from 'components/elements'
import {useEmailData} from './hooks'
import type {EmailAttachment} from './types'

function getIcon(key: string): IconName | undefined {
  switch (key) {
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'file-excel'
    case 'application/pdf':
      return 'file-pdf'
  }
}

const Container = styled.div`
  display: grid;
  justify-content: start;
`

const Item = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: var(--spacing-u1_5);
  }
`

interface IconProps {
  type: string
}

const Icon = styled(IconComp)<IconProps>`
  margin-right: var(--spacing-u2_5);
  grid-column: 1;
  grid-row: 1;

  ${({type}) =>
    getIcon(type) === 'file-pdf' &&
    `
    color: #c81920;`}

  ${({type}) =>
    getIcon(type) === 'file-excel' &&
    `
    color: #206f47;`}
`

const FileName = styled.div`
  color: var(--primaryTextColor);
  grid-column: 2;
  grid-row: 1;
`

const Spinner = styled.div`
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  background: rgba(255, 255, 255, 0.6);
  align-self: stretch;
  justify-self: stretch;
  display: grid;
  justify-items: center;
  align-items: center;
`

interface AttachmentProps {
  attachment: EmailAttachment
}

const Attachment = ({attachment}: AttachmentProps) => {
  const icon = getIcon(attachment.content_type)
  return (
    <Tooltip content='Click to download'>
      <DocumentDownloader url={attachment.download_url}>
        {({onClick, hasActivity}) => (
          <Item onClick={onClick}>
            {icon && (
              <Icon icon={icon} size={32} type={attachment.content_type} />
            )}
            <FileName>{attachment.file_name}</FileName>
            {hasActivity && (
              <Spinner>
                <SpinnerInline />
              </Spinner>
            )}
          </Item>
        )}
      </DocumentDownloader>
    </Tooltip>
  )
}

export const Attachments = () => {
  const {attachments} = useEmailData()

  return (
    <Container>
      {attachments.map((attachment) => (
        <Attachment attachment={attachment} key={attachment.key} />
      ))}
    </Container>
  )
}
