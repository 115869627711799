import type {BadgeColor} from './types'

const THEME: Record<BadgeColor, Record<'standard' | 'inverted', string>> = {
  secondary: {
    standard: 'text-white bg-secondary border-secondary',
    inverted: 'text-secondary bg-white border-secondary',
  },
  info: {
    standard: 'text-white bg-bostonBlue border-bostonBlue',
    inverted: 'text-bostonBlue bg-white border-bostonBlue',
  },
  alert: {
    standard: 'text-white bg-thunderbird border-thunderbird',
    inverted: 'text-thunderbird bg-white border-thunderbird',
  },
  warning: {
    standard: 'text-white bg-casablanca border-casablanca',
    inverted: 'text-casablanca bg-white border-casablanca',
  },
  success: {
    standard: 'text-white bg-mountainMeadow border-mountainMeadow',
    inverted: 'text-mountainMeadow bg-white border-mountainMeadow',
  },
  primary: {
    standard: 'text-white bg-primary border-primary',
    inverted: 'text-primary bg-white border-primary',
  },
}

export const getTheme = (color?: BadgeColor, inverted?: boolean): string => {
  return THEME[color ?? 'primary'][inverted ? 'inverted' : 'standard']
}
