import {useState} from 'react'
import type {FormikContextType} from 'formik'
import {useFormikContext as useFormikContextRaw} from 'formik'
import type {Subtract} from 'utility-types'
import type {GridHOCProps} from 'containers/Form/gridHOC'
import {gridHOC} from 'containers/Form/gridHOC'
import type {FormFieldBaseProps} from 'types/form'
import {cnLegacy} from 'utils'
import {FieldError, FieldLabel} from './components'
import styles from './formStyles.module.css'

interface EnhancedFormikContextType<Values> extends FormikContextType<Values> {
  isFieldRequired: (model: string, value: any, values: any) => boolean
}

function useFormikContext<Values>(): EnhancedFormikContextType<Values> {
  const contextProps = useFormikContextRaw()
  return {
    ...contextProps,
  } as EnhancedFormikContextType<Values>
}

interface WithFormikFieldProps {
  model: string
  onChange?: (value: any) => void
  disabled?: boolean
  label?: string
  unpadded?: boolean
  className?: string
  wrapperClassName?: string
  rawField?: boolean
}

export type FormikFieldProps<P extends FormFieldBaseProps> = GridHOCProps<
  Subtract<P, FormFieldBaseProps> & WithFormikFieldProps
>

export const formikFieldHOC = <P extends FormFieldBaseProps>(
  Component: React.FC<React.PropsWithChildren<P>>
) => {
  const WithFormikField: React.FC<
    React.PropsWithChildren<FormikFieldProps<P>>
  > = ({
    model,
    onChange,
    disabled,
    label,
    unpadded,
    className,
    wrapperClassName,
    rawField,
    ...props
  }) => {
    const [focused, setFocused] = useState(false)

    const {values, getFieldMeta, getFieldHelpers, isFieldRequired} =
      useFormikContext()
    const {touched, value, error} = getFieldMeta(model)
    const {setTouched, setValue} = getFieldHelpers(model)

    const handleChange = (value: any) => {
      setValue(value)
      if (onChange) {
        onChange(value)
      }
    }

    const handleFocus = () => setFocused(true)
    const handleBlur = () => {
      setFocused(false)
      setTouched(true)
    }

    const required = isFieldRequired(model, value, values)
    const showError = !!error && !!touched

    const content = (
      <Component
        {...(props as unknown as P)}
        disabled={disabled}
        value={value}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    )

    return (
      <section
        className={cnLegacy(
          styles.root,
          'whnue-inputWrapper',
          {
            focused: focused,
            required: required,
            [styles.hasLabel]: label,
            [styles.unpadded]: unpadded,
            [styles.disabled]: disabled,
            hasError: showError,
          },
          wrapperClassName
        )}
      >
        <FieldLabel name={model} label={label} />
        <div className={cnLegacy(styles.input, 'whnue-inputWrapper-input')}>
          {content}
        </div>
        <FieldError show={showError} error={error} />
      </section>
    )
  }

  return gridHOC(WithFormikField)
}
