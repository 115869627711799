type StyleConfig<T extends string> = {[key in T]: string}

export type Color =
  | 'white'
  | 'springWood'
  | 'desertStorm'
  | 'westar'
  | 'alto'
  | 'chatelle'
  | 'bombay'
  | 'santasGray'
  | 'midGray'
  | 'scarpaFlow'
  | 'black'
  | 'selago'
  | 'hawkesBlue'
  | 'babyBlue'
  | 'malibu'
  | 'pictonBlue'
  | 'bostonBlue'
  | 'paleSky'
  | 'riverBed'
  | 'oxfordBlue'
  | 'ebonyClay'
  | 'bridesMaid'
  | 'pippin'
  | 'crimson'
  | 'amaranth'
  | 'thunderbird'
  | 'moonRaker'
  | 'hintOfGreen'
  | 'caribbeanGreen'
  | 'gossamer'
  | 'mountainMeadow'
  | 'observatory'
  | 'watercourse'
  | 'casablanca'
  | 'apricotWhite'
  | 'primaryTextColor'
  | 'secondaryTextColor'
  | 'borderColor'

export const colors: StyleConfig<Color> = {
  white: 'var(--white)',
  springWood: 'var(--springWood)',
  desertStorm: 'var(--desertStorm)',
  westar: 'var(--westar)',
  alto: 'var(--alto)',
  chatelle: 'var(--chatelle)',
  bombay: 'var(--bombay)',
  santasGray: 'var(--santasGray)',
  midGray: 'var(--midGray)',
  scarpaFlow: 'var(--scarpaFlow)',
  black: 'var(--black)',
  selago: 'var(--selago)',
  hawkesBlue: 'var(--hawkesBlue)',
  babyBlue: 'var(--babyBlue)',
  malibu: 'var(--malibu)',
  pictonBlue: 'var(--pictonBlue)',
  bostonBlue: 'var(--bostonBlue)',
  paleSky: 'var(--paleSky)',
  riverBed: 'var(--riverBed)',
  oxfordBlue: 'var(--oxfordBlue)',
  ebonyClay: 'var(--ebonyClay)',
  bridesMaid: 'var(--bridesMaid)',
  pippin: 'var(--pippin)',
  crimson: 'var(--crimson)',
  amaranth: 'var(--amaranth)',
  thunderbird: 'var(--thunderbird)',
  moonRaker: 'var(--moonRaker)',
  hintOfGreen: 'var(--hintOfGreen)',
  caribbeanGreen: 'var(--caribbeanGreen)',
  gossamer: 'var(--gossamer)',
  mountainMeadow: 'var(--mountainMeadow)',
  observatory: 'var(--observatory)',
  watercourse: 'var(--watercourse)',
  casablanca: 'var(--casablanca)',
  apricotWhite: 'var(--apricotWhite)',
  primaryTextColor: 'var(--primaryTextColor)',
  secondaryTextColor: 'var(--secondaryTextColor)',
  borderColor: 'var(--borderColor)',
}

export type Effect =
  | 'borderPrimary'
  | 'transitionTime'
  | 'inputColor'
  | 'inputPlaceholderColor'
  | 'inputBorder'
  | 'inputSecondaryBorder'
  | 'inputHoverBorder'
  | 'inputHoverBoxShadow'
  | 'inputFocusBorder'
  | 'inputFocusBoxShadow'
  | 'inputDisabledBackground'
  | 'inputDisabledBorder'
  | 'inputDisabledBoxShadow'
  | 'inputErrorColor'
  | 'inputErrorPlaceholderColor'
  | 'inputErrorBorder'
  | 'inputErrorHoverBorder'
  | 'inputErrorHoverBoxShadow'
  | 'inputErrorFocusBorder'
  | 'inputErrorFocusBoxShadow'
  | 'borderRadius'
  | 'focusBoxShadow'
  | 'topBarBoxShadow'
  | 'popoverBoxshadow'
  | 'eventPopoverBoxshadow'
  | 'disabledOpacity'

export const effects: StyleConfig<Effect> = {
  borderPrimary: 'var(--borderPrimary)',
  transitionTime: 'var(--transitionTime)',
  inputColor: 'var(--inputColor)',
  inputPlaceholderColor: 'var(--inputPlaceholderColor)',
  inputBorder: 'var(--inputBorder)',
  inputSecondaryBorder: 'var(--inputSecondaryBorder)',
  inputHoverBorder: 'var(--inputHoverBorder)',
  inputHoverBoxShadow: 'var(--inputHoverBoxShadow)',
  inputFocusBorder: 'var(--inputFocusBorder)',
  inputFocusBoxShadow: 'var(--inputFocusBoxShadow)',
  inputDisabledBackground: 'var(--inputDisabledBackground)',
  inputDisabledBorder: 'var(--inputDisabledBorder)',
  inputDisabledBoxShadow: 'var(--inputDisabledBoxShadow)',
  inputErrorColor: 'var(--inputErrorColor)',
  inputErrorPlaceholderColor: 'var(--inputErrorPlaceholderColor)',
  inputErrorBorder: 'var(--inputErrorBorder)',
  inputErrorHoverBorder: 'var(--inputErrorHoverBorder)',
  inputErrorHoverBoxShadow: 'var(--inputErrorHoverBoxShadow)',
  inputErrorFocusBorder: 'var(--inputErrorFocusBorder)',
  inputErrorFocusBoxShadow: 'var(--inputErrorFocusBoxShadow)',
  borderRadius: 'var(--borderRadius)',
  focusBoxShadow: 'var(--focusBoxShadow)',
  topBarBoxShadow: 'var(--topBarBoxShadow)',
  popoverBoxshadow: 'var(--popoverBoxshadow)',
  eventPopoverBoxshadow: 'var(--eventPopoverBoxshadow)',
  disabledOpacity: 'var(--disabledOpacity)',
}

export type FontSize =
  | 'whaleSize'
  | 'elephantSize'
  | 'rhinoSize'
  | 'mooseSize'
  | 'bearSize'
  | 'lionSize'
  | 'tigerSize'
  | 'wolfSize'
  | 'dogSize'
  | 'catSize'
  | 'ratSize'
  | 'mouseSize'
  | 'inputFontSize'

export const fontSizes: StyleConfig<FontSize> = {
  whaleSize: 'var(--whaleSize)',
  elephantSize: 'var(--elephantSize)',
  rhinoSize: 'var(--rhinoSize)',
  mooseSize: 'var(--mooseSize)',
  bearSize: 'var(--bearSize)',
  lionSize: 'var(--lionSize)',
  tigerSize: 'var(--tigerSize)',
  wolfSize: 'var(--wolfSize)',
  dogSize: 'var(--dogSize)',
  catSize: 'var(--catSize)',
  ratSize: 'var(--ratSize)',
  mouseSize: 'var(--mouseSize)',
  inputFontSize: 'var(--inputFontSize)',
}

export type Spacing =
  | 'u10' /* 40px */
  | 'u8' /* 32px */
  | 'u6' /* 24px */
  | 'u5' /* 20px */
  | 'u4' /* 16px */
  | 'u2_5' /* 10px */
  | 'u2' /* 8px */
  | 'u1_5' /* 6px */
  | 'u1' /* 4px */

export const spacing: StyleConfig<Spacing> = {
  u10: 'var(--spacing-u10)',
  u8: 'var(--spacing-u8)',
  u6: 'var(--spacing-u6)',
  u5: 'var(--spacing-u5)',
  u4: 'var(--spacing-u4)',
  u2_5: 'var(--spacing-u2_5)',
  u2: 'var(--spacing-u2)',
  u1_5: 'var(--spacing-u1_5)',
  u1: 'var(--spacing-u1)',
}

export type Unit =
  | 'coreHeaderHeight'
  | 'modalHeaderHeight'
  | 'modalFooterHeight'
  | 'formElementHeight'

export const units: StyleConfig<Unit> = {
  coreHeaderHeight: 'var(--coreHeaderHeight)',
  modalHeaderHeight: 'var(--modalHeaderHeight)',
  modalFooterHeight: 'var(--modalFooterHeight)',
  formElementHeight: 'var(--formElementHeight)',
}

export type ZIndex =
  | 'growl'
  | 'overlay'
  | 'popover'
  | 'dropdown'
  | 'modal'
  | 'headerPrimary'
  | 'headerSecondary'
  | 'headerModalPrimary'
  | 'headerModalSecondary'

export const zIndex: StyleConfig<ZIndex> = {
  growl: 'var(--growl)',
  overlay: 'var(--overlay)',
  popover: 'var(--popover)',
  dropdown: 'var(--dropdown)',
  modal: 'var(--modal)',
  headerPrimary: 'var(--headerPrimary)',
  headerSecondary: 'var(--headerSecondary)',
  headerModalPrimary: 'var(--headerModalPrimary)',
  headerModalSecondary: 'var(--headerModalSecondary)',
}

export type Mq = 'desktop'

export const mq: StyleConfig<Mq> = {
  desktop: 'min-width: 768px',
}
