import type {SVGProps} from 'react'

export const BrokenContract = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} fill='none' viewBox='0 0 154 154'>
      <path
        d='M100.565 0c6.278 6.31 12.551 12.626 18.836 18.93 5.086 5.101 10.207 10.169 15.25 15.312.55.56.975 1.523.978 2.3.056 15.258.031 30.517.065 45.775.002 1.084-.265 1.663-1.318 2.143-6.612 3.01-13.19 6.097-19.76 9.2-.981.463-1.7.546-2.675-.167-3.022-2.21-6.178-4.237-9.222-6.418-.887-.636-1.628-.691-2.611-.313-6.958 2.676-13.938 5.292-20.895 7.97-.953.368-1.724.357-2.67-.092-11.65-5.536-23.325-11.018-34.982-16.54-.917-.434-1.689-.484-2.663-.092-6.64 2.674-13.308 5.274-19.966 7.897-.222.088-.45.158-.92.32V0h82.554-.001ZM23.054 4.867v74.131c.657-.244 1.165-.424 1.666-.621 4.776-1.888 9.561-3.755 14.318-5.689.968-.393 1.752-.34 2.686.104A5704.453 5704.453 0 0 0 76.926 89.36c.664.31 1.671.317 2.37.06 7.25-2.675 14.476-5.414 21.688-8.19.851-.327 1.448-.277 2.183.24 3.146 2.214 6.307 4.407 9.525 6.513.5.328 1.439.433 1.974.193 5.007-2.246 9.959-4.612 14.954-6.884.93-.423 1.172-.981 1.169-1.955-.031-13.054-.02-26.108-.025-39.162 0-.477-.058-.953-.099-1.578h-33.72V4.867H23.054Zm78.895 4.071V33.55h24.647L101.949 8.938Z'
        fill='#3181B7'
      />
      <path
        d='M135.562 153.655H18.03v-1.591c0-19.207.008-38.415-.024-57.622-.002-1.088.258-1.643 1.334-2.101 6.364-2.714 12.692-5.513 19.009-8.336 1.077-.482 1.955-.555 3.087-.049a4068.116 4068.116 0 0 0 34.55 15.228c.763.332 1.875.372 2.652.083 6.944-2.585 13.854-5.26 20.758-7.951.883-.345 1.555-.248 2.353.225 3.774 2.238 7.596 4.396 11.371 6.633.806.477 1.426.523 2.308.1 6.106-2.929 12.249-5.782 18.382-8.657.533-.25 1.076-.477 1.75-.775v64.812l.002.001Zm-112.634-4.878H130.64v-52.24c-.618.276-1.067.468-1.509.675-4.566 2.133-9.153 4.222-13.678 6.438-1.101.539-1.886.436-2.877-.156-3.687-2.202-7.434-4.304-11.124-6.5-.895-.534-1.639-.61-2.633-.221-6.502 2.54-13.043 4.983-19.547 7.519-1.454.567-2.747.531-4.17-.102a5055.379 5055.379 0 0 0-33.968-14.97c-.67-.293-1.681-.294-2.356-.01-4.781 2.013-9.498 4.18-14.279 6.193-1.186.5-1.608 1.085-1.604 2.402.052 16.41.034 32.819.034 49.23v1.742Z'
        fill='#3181B7'
      />
      <path
        d='M23.053 4.867h73.893v33.731h33.719c.041.625.099 1.102.099 1.578.005 13.054-.006 26.108.026 39.162.002.973-.24 1.532-1.17 1.955-4.995 2.272-9.947 4.638-14.954 6.884-.535.24-1.473.135-1.974-.193-3.217-2.106-6.379-4.299-9.525-6.512-.735-.518-1.333-.568-2.182-.24a1655.664 1655.664 0 0 1-21.69 8.189c-.697.257-1.704.25-2.37-.06a5740.347 5740.347 0 0 1-35.2-16.57c-.935-.442-1.72-.496-2.686-.103-4.758 1.934-9.542 3.801-14.318 5.689-.501.198-1.01.377-1.667.621V4.868Zm52.36 44.35c-13.153 0-26.306 0-39.459.002-.5 0-1.117-.166-1.475.065-.743.479-1.732 1.08-1.904 1.802-.172.721.364 1.789.909 2.439.35.418 1.274.491 1.942.492 26.656.023 53.312.02 79.967.017.349 0 .718.037 1.044-.06 1.247-.367 1.953-1.185 1.855-2.536-.093-1.274-.845-1.99-2.072-2.187-.489-.078-.998-.032-1.498-.032l-39.309-.001Zm-.123 16.54 39.458-.001c.501 0 1.006.035 1.499-.026 1.228-.152 2.052-1.125 2.055-2.362.003-1.637-1.043-2.428-3.237-2.428-26.456-.002-52.911-.002-79.367 0-.449 0-.92-.052-1.343.064-1.247.341-2.009 1.162-1.916 2.5.091 1.31.865 2.09 2.194 2.233.495.053 1 .02 1.5.02H75.29ZM60.2 30.86H48.957c-4.697.003-9.394-.014-14.09.026-1.46.012-2.407.976-2.447 2.29-.038 1.283.906 2.32 2.297 2.497.296.038.6.022.899.022 16.29 0 32.58.004 48.87-.012.585 0 1.39.022 1.706-.321.62-.672 1.296-1.576 1.341-2.418.06-1.086-.782-1.91-2-2.04-.545-.058-1.098-.042-1.648-.042-7.895-.003-15.79-.002-23.685-.002Zm-16.533-9.135c2.797 0 5.595.019 8.392-.007 1.809-.017 2.85-.896 2.877-2.341.028-1.433-1.05-2.474-2.782-2.485a1385.33 1385.33 0 0 0-16.934 0c-1.741.01-2.814 1.028-2.8 2.464.014 1.443 1.062 2.344 2.855 2.361 2.797.027 5.595.008 8.392.008Z'
        fill='#F2F1F0'
      />
      <path
        d='m101.949 8.938 24.647 24.612h-24.647V8.938ZM22.928 148.777v-1.742c0-16.41.018-32.82-.033-49.23-.005-1.316.418-1.902 1.604-2.401 4.78-2.013 9.497-4.18 14.278-6.193.675-.284 1.686-.283 2.356.01a5084.837 5084.837 0 0 1 33.968 14.969c1.423.633 2.716.669 4.17.103 6.504-2.536 13.045-4.978 19.547-7.52.995-.388 1.738-.312 2.633.22 3.69 2.198 7.437 4.3 11.124 6.502.991.592 1.776.695 2.877.156 4.526-2.216 9.113-4.305 13.678-6.439.442-.206.891-.399 1.509-.675v52.241H22.929v-.001Zm52.357-15.287c13.1 0 26.198.002 39.297-.006.646-.001 1.312-.009 1.932-.161 1.222-.301 1.842-1.145 1.78-2.415-.058-1.202-.726-1.943-1.892-2.175-.533-.106-1.093-.104-1.641-.104-26.248-.005-52.495-.005-78.744 0-.547 0-1.105.002-1.641.097-1.185.21-1.855.989-1.934 2.154-.084 1.246.546 2.129 1.767 2.433.666.167 1.385.17 2.08.17 12.999.009 25.997.007 38.996.007Zm.063-16.7 39.308-.001c.5 0 1.008.046 1.499-.022 1.304-.182 2.079-.93 2.14-2.276.062-1.356-.685-2.145-1.94-2.469-.378-.097-.796-.049-1.196-.049l-79.517-.001c-.35 0-.705-.026-1.05.02-1.294.17-2.197 1.202-2.172 2.449.027 1.269.938 2.219 2.27 2.333.447.038.9.015 1.35.015l39.308.001Z'
        fill='#F2F1F0'
      />
      <path
        d='M75.413 49.218h39.309c.5 0 1.009-.046 1.498.033 1.228.197 1.979.913 2.072 2.187.098 1.35-.607 2.168-1.855 2.536-.326.097-.694.06-1.044.06-26.656.002-53.312.006-79.967-.017-.668 0-1.591-.074-1.942-.493-.544-.65-1.08-1.717-.909-2.439.172-.721 1.16-1.323 1.904-1.802.358-.23.976-.064 1.475-.064 13.153-.002 26.306-.001 39.46-.001Zm-.123 16.539H36.134c-.5 0-1.005.033-1.5-.02-1.328-.143-2.102-.923-2.194-2.234-.092-1.337.67-2.158 1.916-2.5.422-.115.894-.063 1.344-.063 26.455-.002 52.91-.002 79.366 0 2.195 0 3.241.79 3.237 2.428-.002 1.237-.827 2.21-2.054 2.361-.494.061-.999.027-1.499.027H75.291ZM60.2 30.861c7.896 0 15.79 0 23.686.002.55 0 1.103-.015 1.647.043 1.218.13 2.06.953 2 2.04-.045.84-.722 1.746-1.34 2.417-.317.344-1.123.32-1.707.322-16.29.015-32.58.012-48.87.011-.3 0-.603.017-.898-.021-1.392-.179-2.336-1.214-2.297-2.498.039-1.314.986-2.278 2.446-2.29 4.696-.04 9.394-.023 14.09-.025 3.748-.002 7.496 0 11.244 0Zm-16.533-9.135c-2.797 0-5.595.02-8.392-.008-1.793-.017-2.841-.918-2.856-2.361-.014-1.436 1.06-2.454 2.8-2.464 5.645-.034 11.29-.035 16.935 0 1.73.011 2.81 1.052 2.782 2.485-.028 1.444-1.069 2.324-2.877 2.34-2.797.027-5.595.008-8.392.008ZM75.285 133.49c-12.998 0-25.997.002-38.996-.007-.695 0-1.414-.003-2.08-.169-1.221-.305-1.851-1.188-1.767-2.434.078-1.165.748-1.944 1.933-2.154.537-.095 1.094-.097 1.642-.097 26.248-.005 52.496-.005 78.744 0 .548 0 1.108-.002 1.64.104 1.167.232 1.835.973 1.893 2.175.061 1.27-.558 2.114-1.781 2.415-.62.152-1.286.16-1.931.161-13.099.008-26.198.006-39.297.006Zm.063-16.7-39.309-.001c-.45 0-.902.023-1.35-.015-1.33-.114-2.242-1.064-2.268-2.333-.026-1.247.877-2.279 2.172-2.449.345-.046.7-.02 1.05-.02 26.505-.001 53.01-.001 79.517.001.399 0 .817-.048 1.195.049 1.255.324 2.002 1.113 1.94 2.469-.061 1.346-.835 2.094-2.14 2.276-.491.068-.999.022-1.499.022l-39.308.001Z'
        fill='#4AA6EE'
      />
    </svg>
  )
}
