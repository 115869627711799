import type {ReactNode} from 'react'
import {
  cloneElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import type {Options, Placement} from '@popperjs/core'
import {usePopper} from 'react-popper'
import {Portal} from 'react-portal'
import {cnLegacy} from 'utils'
import {getTheme} from '../Badge'
import type {BadgeColor} from '../Badge'

export type TooltipColor = BadgeColor

export type TooltipPosition = Extract<
  Placement,
  'top' | 'right' | 'bottom' | 'left'
>

interface TooltipProps {
  content: React.ReactNode
  color?: TooltipColor
  position?: TooltipPosition
  showDelay?: number
  maxWidth?: number
  popperClassName?: string
}

export const Tooltip: React.FC<React.PropsWithChildren<TooltipProps>> = ({
  children,
  content,
  color = 'primary',
  position = 'top',
  showDelay = 200,
  maxWidth = 150,
  popperClassName,
}) => {
  const [visible, setVisible] = useState(false)
  const _onMouseEnter = useRef<NodeJS.Timeout | null>(null)

  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  )
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null)

  const popperConfig = useMemo(
    (): Partial<Options> => ({
      placement: position,
      modifiers: [
        {
          name: 'arrow',
          options: {
            element: arrowElement,
          },
        },
        {
          name: 'preventOverflow',
          options: {
            padding: 8,
          },
        },
        {
          name: 'offset',
          options: {
            offset: [0, 7],
          },
        },
      ],
    }),
    [position, arrowElement]
  )

  const {styles, attributes} = usePopper(
    referenceElement,
    popperElement,
    popperConfig
  )

  const handleMouseEnter = useCallback(() => {
    _onMouseEnter.current = setTimeout(() => setVisible(true), showDelay)
  }, [showDelay])

  const handleMouseLeave = useCallback(() => {
    if (_onMouseEnter.current) {
      clearTimeout(_onMouseEnter.current)
    }
    setVisible(false)
  }, [])

  useEffect(() => {
    const _referenceElement = referenceElement
    if (_referenceElement) {
      _referenceElement.addEventListener('mouseenter', handleMouseEnter)
      _referenceElement.addEventListener('mouseleave', handleMouseLeave)
    }

    return () => {
      if (_referenceElement) {
        _referenceElement.removeEventListener('mouseenter', handleMouseEnter)
        _referenceElement.removeEventListener('mouseleave', handleMouseLeave)
      }
    }
  }, [referenceElement, handleMouseEnter, handleMouseLeave])

  const handleTarget = (child: ReactNode) => {
    if (!child) {
      return null
    }

    if (typeof child === 'string' || typeof child === 'number') {
      return (
        <div className='inline-block' ref={setReferenceElement}>
          {child}
        </div>
      )
    }

    if (typeof child === 'object' && 'type' in child) {
      return cloneElement(child, {
        ref: setReferenceElement,
      })
    }
  }

  return (
    <>
      {handleTarget(children)}

      {content && visible && (
        <Portal>
          <div
            ref={setPopperElement}
            color={color}
            className={cnLegacy(
              'group/popover',
              'relative z-[10000] pointer-events-none shadow-popover opacity-90 rounded',
              getTheme(color),
              popperClassName
            )}
            style={{
              ...styles.popper,
              maxWidth: maxWidth ? `${maxWidth}px` : undefined,
            }}
            {...attributes.popper}
          >
            <div className='py-2 px-4 text-catSize/[1.5]'>{content}</div>
            <div
              ref={setArrowElement}
              className={cnLegacy(
                'absolute w-[5px] h-[5px]',
                'group-data-[popper-placement=top]/popover:-bottom-[5px] group-data-[popper-placement=top]/popover:w-[10px] group-data-[popper-placement=top]/popover:clip-path-triangleDown',
                'group-data-[popper-placement=bottom]/popover:-top-[5px] group-data-[popper-placement=bottom]/popover:w-[10px] group-data-[popper-placement=bottom]/popover:clip-path-triangleUp',
                'group-data-[popper-placement=left]/popover:-right-[5px] group-data-[popper-placement=left]/popover:h-[10px] group-data-[popper-placement=left]/popover:clip-path-triangleRight',
                'group-data-[popper-placement=right]/popover:-left-[5px] group-data-[popper-placement=right]/popover:h-[10px] group-data-[popper-placement=right]/popover:clip-path-triangleLeft',
                getTheme(color)
              )}
              style={styles.arrow}
            />
          </div>
        </Portal>
      )}
    </>
  )
}
