import type {CSSProperties} from 'react'
import {memo} from 'react'
import {range} from 'lodash'
import type {DaysArrayItem, MonthsInColumnsColumn} from 'helpers/booking'
import {cnLegacy} from 'utils'
import {HeaderDay} from './elements'
import {Day} from '../components'

interface MonthsInColumnsProps {
  viewKey: string
  grid: MonthsInColumnsColumn[]
}

interface GridCellProps {
  grid: MonthsInColumnsColumn[]
  columnIndex: number
  rowIndex: number
  isVisible: boolean
}

interface CellProps extends Pick<GridCellProps, 'columnIndex' | 'rowIndex'> {
  className?: string
  style?: CSSProperties
}

interface HeaderCellProps extends CellProps {
  month: string
  title?: string
}

const HeaderCell = memo(({month, title, style, className}: HeaderCellProps) => {
  return (
    <div
      className={cnLegacy(
        className,
        'bg-oxfordBlue text-white font-bold grid grid-cols-[90px_250px]',
        'sticky top-0 width-[340px] z-10 h-[34px]'
      )}
      style={{
        ...style,
      }}
    >
      <div className='py-2 px-2.5 border-r border-scarpaFlow'>{month}</div>
      <div className='py-2 px-2.5'>{title}</div>
    </div>
  )
})
HeaderCell.displayName = 'HeaderCell'

interface DayCellProps
  extends CellProps,
    DaysArrayItem,
    Pick<MonthsInColumnsColumn, 'actId'> {}

const DayCell = memo(
  ({
    date,
    actId,
    events,
    isToday,
    isWeekend,
    tours,
    className,
    style,
  }: DayCellProps) => {
    return (
      <div
        className={cnLegacy(
          className,
          'grid grid-cols-[90px_250px] border-b border-borderColor'
        )}
        style={style}
      >
        <HeaderDay
          date={new Date(date)}
          className='border-r border-borderColor'
          isToday={isToday}
          isWeekend={isWeekend}
        />
        <Day
          date={date}
          actId={actId}
          events={events}
          isToday={isToday}
          isWeekend={isWeekend}
          tours={tours}
        />
      </div>
    )
  }
)
DayCell.displayName = 'DayCell'

const CellRenderer = memo(
  ({grid, columnIndex, rowIndex, isVisible}: GridCellProps) => {
    if (!isVisible) {
      return null
    }

    const column = grid[columnIndex]
    const cell = column.rows[rowIndex]

    const style = {}

    const className = 'content-visibility-auto'

    if (rowIndex === -1) {
      return (
        <HeaderCell
          columnIndex={columnIndex}
          rowIndex={rowIndex}
          month={column.month}
          className={className}
          style={style}
        />
      )
    }

    if (!cell) {
      return <div className={className} />
    }

    return (
      <DayCell
        {...cell}
        columnIndex={columnIndex}
        rowIndex={rowIndex}
        actId={column.actId}
        className={className}
      />
    )
  }
)
CellRenderer.displayName = 'CellRenderer'

export function MonthsInColumns({
  grid,
  viewKey,
}: MonthsInColumnsProps): JSX.Element {
  const columns = grid.length
  const rows = grid.reduce((sum, i) => Math.max(sum, i.rows.length), 0)

  return (
    <div
      key={viewKey}
      className='grid gap-x-2.5 content-start overflow-auto relative'
      style={{
        gridTemplateColumns: `repeat(${columns}, 340px)`,
        gridTemplateRows: `repeat(${rows + 1}, auto)`,
        gridAutoFlow: 'column',
      }}
    >
      {grid.map((_column, columnIndex) =>
        range(-1, rows).map((rowIndex) => {
          return (
            <CellRenderer
              key={`${columnIndex}-${rowIndex}`}
              columnIndex={columnIndex}
              rowIndex={rowIndex}
              grid={grid}
              isVisible
            />
          )
        })
      )}
    </div>
  )
}
