import get from 'lodash/get'
import last from 'lodash/last'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import {CONTRACT_STATUS_TO_AUDIT_TRAIL_STATUS} from 'options/contractAuditTrail'
import type {
  Contract,
  ContractInvoicingStatus,
  ContractRemote,
  ContractStatusAuditTrail,
  ContractStatusAuditTrailRemote,
} from 'types/contract'
import {ContractAuditTrailStatuses} from 'types/contract'
import type {SigningDocument} from 'types/signing'

function buildStatusAuditTrails(
  data?: ContractStatusAuditTrailRemote[] | null
): ContractStatusAuditTrail[] {
  if (!data) {
    return []
  }

  return map(data, (audit, index) => {
    const prevAudit = data.at(index - 1)

    let internal_status = CONTRACT_STATUS_TO_AUDIT_TRAIL_STATUS[audit.status]

    if (audit.status === 'CONTRACT_STATUS_CONFIRMED') {
      if (prevAudit?.status === 'CONTRACT_STATUS_CANCELLED') {
        internal_status = ContractAuditTrailStatuses.UNCANCELLED
      } else if (
        prevAudit?.status === 'CONTRACT_STATUS_FINALIZED' ||
        prevAudit?.status === 'CONTRACT_STATUS_SIGNED'
      ) {
        internal_status = ContractAuditTrailStatuses.REEDITED
      } else if (
        prevAudit?.status === 'CONTRACT_STATUS_UNSIGNED' ||
        prevAudit?.status === 'CONTRACT_STATUS_PARTIALLY_SIGNED'
      ) {
        internal_status = ContractAuditTrailStatuses.SIGNING_RETRACTED
      }
    }

    return {
      ...audit,
      internal_status,
    }
  })
}

function buildInvoicingAuditTrail(
  data?: ContractInvoicingStatus | null
): ContractStatusAuditTrail[] {
  if (!data) {
    return []
  }

  const rtn = []

  if (data.invoice_sent_at) {
    rtn.push({
      internal_status: 'INVOICING_STATUS_INVOICE_SENT',
      changed_at: data.invoice_sent_at,
      invoice_id: data.external_invoice_id,
    })
  }
  if (data.invoice_paid_at) {
    rtn.push({
      internal_status: 'INVOICING_STATUS_INVOICE_PAID',
      changed_at: data.invoice_paid_at,
    })
  }

  return rtn as ContractStatusAuditTrail[]
}

function buildDocumentSigningTrail(
  data: SigningDocument | null
): ContractStatusAuditTrail[] {
  if (!data) {
    return []
  }

  const rtn: ContractStatusAuditTrail[] = []

  if (data.promisee.signed_at) {
    rtn.push({
      status: 'CONTRACT_STATUS_SIGNED',
      internal_status: ContractAuditTrailStatuses.SIGNED_PROMISEE,
      changed_at: data.promisee.signed_at,
      changed_by: data.promisee.signee?.affiliation.person,
    })
  }
  if (data.promisor.signed_at) {
    rtn.push({
      status: 'CONTRACT_STATUS_SIGNED',
      internal_status: ContractAuditTrailStatuses.SIGNED_PROMISOR,
      changed_at: data.promisor.signed_at,
      changed_by: data.promisor.signee?.affiliation.person,
    })
  }

  return rtn
}

function buildAuditTrail(contract: ContractRemote) {
  return sortBy(
    [
      ...buildStatusAuditTrails(contract.status_audit_trail),
      ...buildInvoicingAuditTrail(contract.invoicing_status),
      ...buildDocumentSigningTrail(contract.document),
    ],
    'changed_at'
  )
}

export const processResult = (result: ContractRemote): Contract => {
  const status_audit_trail = buildAuditTrail(result)

  return {
    ...result,
    _commissionAbsolute: !!result.absolute_commission,
    status_audit_trail,
    status_audit_trail_last_status: get(
      last(status_audit_trail),
      'internal_status'
    ),
  } as Contract
}

export function buildUri(id?: number) {
  return id
    ? `contracts/booking-contracts/${id}`
    : 'contracts/booking-contracts'
}
