import {cnLegacy} from 'utils'
import styles from './Form.module.css'

export interface SectionProps {
  className?: string
  secondary?: boolean
  label?: string
  action?: React.ReactNode
}

export const Section: React.FC<React.PropsWithChildren<SectionProps>> = ({
  children,
  secondary,
  className,
  label,
  action,
}) => {
  const hasLabel = !!label
  const hasAction = !!action
  const hasDivider = hasLabel || hasAction

  const classNames = cnLegacy(
    {
      [styles.sectionSecondary]: secondary,
      [styles.hasDivider]: hasDivider,
      [styles.hasLabel]: hasLabel,
      [styles.hasAction]: hasAction,
    },
    styles.section,
    className
  )

  return (
    <section className={classNames}>
      {hasDivider && (
        <div className={styles.sectionDivider}>
          {hasLabel && <span className={styles.sectionDividerLeftSpacer} />}
          {hasLabel && <div className={styles.sectionLabel}>{label}</div>}
          <span className={styles.sectionDividerMiddleSpacer} />
          {hasAction && <div className={styles.sectionAction}>{action}</div>}
          {hasAction && <span className={styles.sectionDividerRightSpacer} />}
        </div>
      )}

      {children}
    </section>
  )
}
