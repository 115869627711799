import {MobileMenuCloseButton} from 'components/elements/MobileMenu'
import {ModalCloseButton} from 'context/Modal'
import {cnLegacy} from 'utils'
import styles from './container.module.css'

interface HeaderProps {
  className?: string
}

export const Header: React.FC<React.PropsWithChildren<HeaderProps>> = ({
  className,
  children,
}) => (
  <header className={cnLegacy(className, styles.header)}>
    <section className={styles.headerContent}>{children}</section>
    <div className={styles.headerModalClose}>
      <ModalCloseButton />
      <MobileMenuCloseButton />
    </div>
  </header>
)

export const HeaderLeft: React.FC<React.PropsWithChildren<HeaderProps>> = ({
  className,
  children,
}) => (
  <section className={cnLegacy(className, styles.headerLeft)}>
    {children}
  </section>
)

export const HeaderMiddle: React.FC<React.PropsWithChildren<HeaderProps>> = ({
  className,
  children,
}) => (
  <section className={cnLegacy(className, styles.headerMiddle)}>
    {children}
  </section>
)

export const HeaderRight: React.FC<React.PropsWithChildren<HeaderProps>> = ({
  className,
  children,
}) => (
  <section className={cnLegacy(className, styles.headerRight)}>
    {children}
  </section>
)
