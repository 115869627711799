import * as Sentry from '@sentry/react'
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import {createRoot} from 'react-dom/client'
import {Provider as ReduxProvider} from 'react-redux'
import {BrowserRouter} from 'react-router-dom'
import {RootRouter} from 'app/router'
import {Prompt} from 'shared/Prompt'
import {Toastr} from 'shared/Toastr'
import {store} from 'store/store'
import 'styles/index.css'

if (import.meta.env.VITE_SENTRY_URL) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_URL,
    environment: import.meta.env.DEV
      ? 'development'
      : window.location.hostname === 'whnue.com'
      ? 'production'
      : 'staging',
    maxBreadcrumbs: 50,
    ignoreErrors: [
      'ResizeObserver',
      'POST /authentication/jwt/refresh/ [400]',
      'POST /authentication/jwt/token/ [400]',
    ],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  })
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

// https://stackoverflow.com/a/72139826
let container: HTMLElement | null = null

document.addEventListener('DOMContentLoaded', function () {
  if (!container) {
    container = document.getElementById('root') as HTMLElement
    const root = createRoot(container)
    root.render(
      <QueryClientProvider client={queryClient}>
        <ReduxProvider store={store}>
          <BrowserRouter>
            <RootRouter />
            <Toastr />
            <Prompt />
            <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
          </BrowserRouter>
        </ReduxProvider>
      </QueryClientProvider>
    )
  }
})
