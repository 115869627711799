import styled from 'styled-components'
import {Button, HeaderText} from 'components/elements'
import {
  Container as ContainerComp,
  Header,
  HeaderLeft,
  Filters,
  Main as MainComp,
  Footer as FooterComp,
} from 'containers/Core'
import {colors, spacing} from 'styles/variables'
import {Attachments} from './Attachments'
import {Body} from './Body'
import {useEmail, useEmailData} from './hooks'
import {Recipients} from './Recipients'
import {Signature as SignatureComp} from './Signature'
import {Subject} from './Subject'

const Container = styled(ContainerComp)`
  width: 900px;
`

const RecipientsWrapper = styled(Filters)`
  z-index: 21;
`
const SubjectWrapper = styled(Filters)`
  z-index: 20;
`

const Main = styled(MainComp)`
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  position: relative;
`

const ContentWrapper = styled.div`
  overflow-y: scroll;

  & > * {
    border-top: 1px dotted ${colors.borderColor};
  }
`

const Signature = styled(SignatureComp)`
  border-top: 1px dotted ${colors.borderColor};
`

const AttachmentsWrapper = styled(Filters)`
  border-top: 1px dotted ${colors.borderColor};
`

const Footer = styled(FooterComp)`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  padding: ${spacing.u5};
`

const Actions = styled.div`
  display: flex;
  flex-flow: row nowrap;

  & > :not(:last-child) {
    margin-right: ${spacing.u2_5};
  }
`

export const Email = () => {
  const {valid, send, sending, cancel} = useEmail()
  const {headerPrimary, headerSecondary, attachments, actions} = useEmailData()

  return (
    <Container>
      <Header>
        <HeaderLeft>
          <HeaderText
            primary={headerPrimary}
            secondary={headerSecondary}
            twoLines
          />
        </HeaderLeft>
      </Header>

      <RecipientsWrapper bg='white' shadow={false}>
        <Recipients />
      </RecipientsWrapper>
      <SubjectWrapper bg='white'>
        <Subject />
      </SubjectWrapper>

      <Main>
        <ContentWrapper>
          <Body />
          <Signature />
        </ContentWrapper>
      </Main>

      {!!attachments && (
        <AttachmentsWrapper bg='white' shadow={false}>
          <Attachments />
        </AttachmentsWrapper>
      )}

      <Footer>
        <Button onClick={cancel}>Cancel</Button>
        <Actions>
          {actions.map(({action, name, buttonProps}) => (
            <Button
              key={action}
              {...buttonProps}
              disabled={!valid}
              onClick={() => send(action)}
              hasActivity={sending}
            >
              {name}
            </Button>
          ))}
        </Actions>
      </Footer>
    </Container>
  )
}
