import {forwardRef} from 'react'
import {cnLegacy} from 'utils'
import spriteHref from './generated/sprite.svg'
import type {IconProps} from './types'

export const Icon = forwardRef<SVGSVGElement, IconProps>(
  ({icon, size = 16, className}, ref) => {
    return (
      <svg
        width={size}
        height={size}
        ref={ref}
        fill='currentColor'
        className={cnLegacy('whnue-icon', className)}
      >
        <use xlinkHref={`${spriteHref}#${icon}`}></use>
      </svg>
    )
  }
)

Icon.displayName = 'Icon'
