import {Field} from 'formik'
import styled from 'styled-components'
import {colors, effects, fontSizes, spacing} from 'styles/variables'

export const Form = styled.form`
  display: grid;
  grid-template-columns: 290px;
  grid-template-rows: 80px 1fr 40px;
  grid-template-areas:
    'errorMessage'
    'fields'
    'actions';
`

export const Fields = styled.div`
  grid-area: fields;
`

export const Actions = styled.div`
  grid-area: actions;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 5px;
`

export const Input = styled(Field)`
  border: ${effects.inputBorder};
  color: ${colors.scarpaFlow};
  transition: border ${effects.transitionTime},
    box-shadow ${effects.transitionTime}, color ${effects.transitionTime};
  outline: none;
  box-sizing: border-box;
  background-color: transparent;
  height: 36px !important;
  width: 280px;
  padding-left: 10px;
  color: white;
  margin: 5px;

  &:hover {
    border: ${effects.inputHoverBorder};
  }

  &:focus {
    border: ${effects.inputFocusBorder};
    box-shadow: ${effects.inputFocusBoxShadow};
  }

  &::placeholder {
    color: ${effects.inputPlaceholderColor};
  }
`

export const ReadOnlyInput = styled.div`
  box-sizing: border-box;
  height: 36px !important;
  width: 280px;
  padding: 7px 10px;
  color: ${colors.primaryTextColor};
  margin: 5px;
  border: 1px solid ${colors.riverBed};
`

export const ErrorMessage = styled.div`
  grid-area: errorMessage;
  align-self: end;
  color: black;
  background-color: ${colors.crimson};
  margin: 0 calc(${spacing.u2_5} / 2);
  padding: ${spacing.u2_5} ${spacing.u5};
  font-size: ${fontSizes.dogSize};
  margin-bottom: ${spacing.u2_5};
  text-align: center;
`

export const SubmitButton = styled.button<{hidden: boolean}>`
  color: var(--paleSky);
  text-align: center;
  font-size: 14px;
  border: 0;
  outline: 0 !important;
  background-color: transparent;
  text-align: center;
  width: 100%;
  height: 30px;
  display: ${({hidden}) => (hidden ? 'none' : 'block')};
`
