import type {ReactElement} from 'react'
import {useSetAtom} from 'jotai'
import useScrollbarSize from 'react-scrollbar-size'
import {initErrorsAtom} from 'app/modal/Email/atoms'
import {Tabs, TabList, TabPanel} from 'components/elements/Tabs'
import {
  Form,
  ErrorsTooltip,
  SubmitButton,
  TabWithErrorCount,
} from 'components/form'
import {CONTRACT_CREATE, CONTRACT_UPDATE} from 'store/contract/constants'
import type {Contract} from 'types/contract'
import styles from './Form.module.css'
import {Show, Economy, Tickets, Scope, Production, Settings} from './panels'
import {validationSchema} from './schema'
import {useContractForm} from '../helpers'

const KEYS_SHOW = [
  'show.stakeholder_act.department.id',
  'show.stakeholder_organizer.department.id',
  'show.stakeholder_venue.department.id',
]
const KEYS_ECONOMY: string[] = []
const KEYS_TICKETS: string[] = ['show.tickets']
const KEYS_SCOPE: string[] = ['economic_terms_id', 'supplementary_contract.id']
const KEYS_PRODUCTION: string[] = ['show.production_status']
const KEYS_SETTINGS: string[] = []

export const ContractForm = (): ReactElement | null => {
  const {width: scrollbarWidth} = useScrollbarSize()
  const {isNew, data, locked} = useContractForm()
  const setEmailInitError = useSetAtom(initErrorsAtom)

  if (!data) {
    return null
  }

  return (
    <Form<Contract, unknown, unknown>
      initialValues={data}
      validationSchema={validationSchema}
      action={isNew ? CONTRACT_CREATE : CONTRACT_UPDATE}
      className={styles.form}
      enableReinitialize
    >
      {() => (
        <>
          <Tabs
            kind='contractPanes'
            className={styles.tabsContainer}
            style={{
              gridTemplateColumns: `140px ${540 + scrollbarWidth}px`,
            }}
          >
            <TabList className={styles.aside}>
              <TabWithErrorCount name='show' text='Show' keys={KEYS_SHOW} />
              <TabWithErrorCount
                name='economy'
                text='Economy'
                keys={KEYS_ECONOMY}
              />
              <TabWithErrorCount
                name='tickets'
                text='Tickets'
                keys={KEYS_TICKETS}
              />
              <TabWithErrorCount name='scope' text='Scope' keys={KEYS_SCOPE} />
              <TabWithErrorCount
                name='production'
                text='Production'
                keys={KEYS_PRODUCTION}
              />
              <TabWithErrorCount
                name='settings'
                text='Settings'
                keys={KEYS_SETTINGS}
              />
            </TabList>

            <section className={styles.main}>
              <section className={styles.content}>
                <TabPanel name='show' component={false}>
                  <Show locked={locked} />
                </TabPanel>
                <TabPanel name='economy' component={false}>
                  <Economy locked={locked} />
                </TabPanel>
                <TabPanel name='tickets' component={false}>
                  <Tickets locked={locked} />
                </TabPanel>
                <TabPanel name='scope' component={false}>
                  <Scope locked={locked} />
                </TabPanel>
                <TabPanel name='production' component={false}>
                  <Production locked={locked} />
                </TabPanel>
                <TabPanel name='settings' component={false}>
                  <Settings locked={locked} />
                </TabPanel>
              </section>

              <section className={styles.actions}>
                <section className={styles.submitActions}>
                  <ErrorsTooltip />

                  <SubmitButton
                    width='fixedDouble'
                    theme='primary'
                    label='Save'
                    type='submit'
                    onClick={() => setEmailInitError(undefined)}
                  />
                </section>
              </section>
            </section>
          </Tabs>
        </>
      )}
    </Form>
  )
}
