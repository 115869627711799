import {cnLegacy} from 'utils'
import styles from './Form.module.css'

export interface ContainerProps {
  className?: string
}

export const Container: React.FC<React.PropsWithChildren<ContainerProps>> = ({
  children,
  className,
}) => <div className={cnLegacy(styles.container, className)}>{children}</div>
