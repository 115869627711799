import styled from 'styled-components'
import {spacing} from 'styles/variables'
import {useEmailData} from './hooks'

const Container = styled.div`
  margin: 0 ${spacing.u5};
  cursor: default;
`

export const Signature = () => {
  const {signature} = useEmailData()

  return (
    <Container>
      <div dangerouslySetInnerHTML={{__html: signature}} />
    </Container>
  )
}
