import {cnLegacy} from 'utils'
import styles from './Form.module.css'
import type {GridHOCProps} from './gridHOC'
import {gridHOC} from './gridHOC'

interface InlineContentInnerProps {
  className?: string
}

export type InlineContentProps = GridHOCProps<InlineContentInnerProps>

const InlineContentInner: React.FC<
  React.PropsWithChildren<InlineContentProps>
> = ({children, wrapperClassName, className}) => {
  return (
    <div
      className={cnLegacy(
        styles.inlineContent,
        'form-inlineContent',
        wrapperClassName,
        className
      )}
    >
      {children}
    </div>
  )
}

export const InlineContent = gridHOC(InlineContentInner)
