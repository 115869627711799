import {BrokenContract} from 'components/svg/BrokenContract'

export function ErrorGeneric() {
  return (
    <div className='grid h-full place-items-center text-white'>
      <div className='flex flex-col items-center text-center px-5 max-w-md gap-y-6'>
        <BrokenContract className='w-24' />
        <h1 className='text-lionSize'>Contract could not be found</h1>
        <div className='space-y-3'>
          <p>
            It looks like the contract you were about to sign does not exist in
            the system anymore.
          </p>
          <p>
            You might already have received an email explaining why. If not, we
            advice you to contact the person that send you the contact to get an
            explanation, or have him/her send you a new signing link.
          </p>
          <p>Have a good day.</p>
        </div>
      </div>
    </div>
  )
}
