import {cnLegacy} from 'utils'

export const MainAppLoader = () => {
  return (
    <div className='absolute inset-0 bg-oxfordBlue'>
      <div
        className={cnLegacy(
          'text-bearSize text-primary',
          'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2',
          'flex flex-col items-center gap-y-8'
        )}
      >
        <img
          src='/images/logo_lights_no_bg_css.svg'
          alt=''
          width={100}
          height={64}
        />
        <div
          className={cnLegacy(
            'bg-ebonyClay, w-40 h-1.5 rounded-sm',
            'gradient-loading animate-loading'
          )}
        />
        <p className='text-lionSize text-ebonyClay'>Loading</p>
      </div>
    </div>
  )
}
