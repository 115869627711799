import type {DateRangeType} from 'types/date'

export const INITIAL_PARAMS = {
  startDay: null,
  endDay: null,
  dateRangeType: 'month' as DateRangeType,
  actId: null,
  actIds: null,
  organizerIds: null,
  tourId: null,
  showsOnly: null,
}
