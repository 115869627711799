import styled, {keyframes} from 'styled-components'
import {Logo as LogoComp} from 'components/svg/Logo'
import {LoginForm} from 'shared/AuthHandler/LoginForm'
import {colors} from 'styles/variables'

const dropLogo = keyframes`
  0% {
    transform: translateY(-40px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`

const Video = styled.video`
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  max-width: none;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  transition: 1s opacity;
`

const FormWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const LogoWrapper = styled.div`
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Logo = styled(LogoComp)`
  fill: ${colors.white};
  width: 200px;
  animation: ${dropLogo} 1s;
`

export const Login = () => {
  return (
    <div>
      <Video poster='/video/login/login_video.jpg' autoPlay loop muted>
        <source src='/video/login/login_video.mp4' type='video/mp4' />
      </Video>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <FormWrapper>
        <LoginForm />
      </FormWrapper>
    </div>
  )
}
