import {useCallback, useEffect, useRef, useState} from 'react'
import {Icon} from 'components/elements'
import {toastrActions} from 'features/toastr'
import {useAppDispatch} from 'hooks/store'
import type {Toast as IToast, ToastKind} from 'types/toast'
import {cnLegacy} from 'utils'

const getTheme = (kind: ToastKind) => {
  switch (kind) {
    case 'success':
      return 'bg-gossamer text-white'

    case 'warning':
      return 'bg-casablanca text-white'

    case 'error':
      return 'bg-crimson text-white'

    case 'info':
      return 'bg-pictonBlue text-white'
  }
}

interface ToastProps {
  data: IToast
}

export const Toast = ({data}: ToastProps) => {
  const dispatch = useAppDispatch()
  const {
    id,
    kind,
    sticky,
    icon,
    message,
    description,
    timeout: timeoutFromData = 5,
  } = data

  const timeoutRef = useRef<NodeJS.Timeout | null>()
  const [timeoutCount, setTimeoutCount] = useState(timeoutFromData)
  const [visible, setVisible] = useState(false)
  const [paused, setPaused] = useState(false)
  const [removing, setRemoving] = useState(false)

  const clearTimer = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
  }

  const handleRemove = useCallback(() => {
    clearTimer()
    setVisible(false)
    setRemoving(true)
    setTimeout(() => dispatch(toastrActions.removeToast({id})), 200)
  }, [dispatch, id])

  const handleMouseEnter = () => setPaused(true)
  const handleMouseLeave = () => setPaused(false)

  const handleClick = () => handleRemove()

  useEffect(() => {
    if (sticky) {
      return
    }

    if (paused) {
      return
    }

    if (timeoutCount <= 0) {
      handleRemove()
      return
    }

    if (!sticky) {
      timeoutRef.current = setTimeout(() => {
        setTimeoutCount(timeoutCount - 1)
      }, 1000)
    }
  }, [sticky, timeoutRef, timeoutCount, handleRemove, paused])

  useEffect(() => {
    if (!visible && !removing) {
      setVisible(true)
    }
  }, [visible, removing])

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      className={cnLegacy(
        'flex justify-center max-w-lg',
        'shadow-popover cursor-pointer',
        getTheme(kind),
        visible ? 'opacity-100' : 'opacity-0',
        visible ? 'translate-x-0' : 'translate-x-8',
        'transition-all',
        'group/toast'
      )}
    >
      {icon && (
        <div
          className={cnLegacy(
            'flex-none opacity-50 pl-5',
            'grid place-items-center',
            'group-hover/toast:opacity-70',
            'transition-opacity'
          )}
        >
          <Icon icon={icon} size={24} />
        </div>
      )}
      <div className='flex-1 py-2.5 px-5'>
        {message && (
          <div
            className={cnLegacy(
              'text-wolfSize font-bold',
              'opacity-80 group-hover/toast:opacity-100',
              'transition-opacity'
            )}
          >
            {message}
          </div>
        )}
        {description && (
          <div
            className={cnLegacy(
              'text-catSize',
              'opacity-70 group-hover/toast:opacity-90',
              'transition-opacity'
            )}
          >
            {description}
          </div>
        )}
      </div>
    </div>
  )
}
