import {Button} from 'components/elements'
import {useAppSelector} from 'hooks/store'
import {useEntity} from 'hooks/useEntity'

export const MissingOrganizerId = () => {
  const {openOrganizer} = useEntity()

  const contract = useAppSelector(({contract}) => contract.result)
  const invoiceOrganizerId =
    contract?.show.stakeholder_organizer_for_invoicing?.department.id
  const organizerId = contract?.show.stakeholder_organizer?.department.id

  if (invoiceOrganizerId) {
    return (
      <Button
        onClick={() => openOrganizer(invoiceOrganizerId)}
        icon='new-window'
        label='Add Tax-ID'
      />
    )
  }
  if (organizerId) {
    return (
      <Button
        onClick={() => openOrganizer(organizerId)}
        icon='new-window'
        label='Add Tax-ID'
      />
    )
  }

  return null
}
