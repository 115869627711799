import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'
import {EmptyMessage, HeaderText, PriorityMenu} from 'components/elements'
import {Container, Filters, Header, Main} from 'containers/Core'
import {PlaygroundIcon} from './Icon'
import {PlaygroundToastr} from './Toastr'

export const PlaygroundSwitch = () => {
  const location = useLocation()
  const {path} = useRouteMatch()
  const {push} = useHistory()

  return (
    <Container>
      <Header>
        <HeaderText primary='Playground' />
      </Header>
      <Filters>
        <PriorityMenu
          value={location.pathname.replace(path, '')}
          items={[
            {label: 'Icon', value: '/Icon'},
            {label: 'Toast', value: '/toast'},
          ]}
          onChange={(value) => push(`${path}${value}`)}
        />
      </Filters>
      <Main className='grid overflow-hidden'>
        <Switch>
          <Route path={`${path}/icon`}>
            <PlaygroundIcon />
          </Route>
          <Route path={`${path}/toast`}>
            <PlaygroundToastr />
          </Route>
          <Route>
            <EmptyMessage
              title='Choose something cool from the menu above'
              className='self-center'
            />
          </Route>
        </Switch>
      </Main>
    </Container>
  )
}
