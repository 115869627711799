import type {ReactElement} from 'react'
import {useState, useRef, useMemo} from 'react'
import type {Options} from '@popperjs/core'
import {usePopper} from 'react-popper'
import {Portal} from 'react-portal'
import {Avatar, Icon} from 'components/elements'
import {useAuth} from 'hooks/useAuth'
import {useClickOutside} from 'hooks/useClickOutside'
import {useIsDesktop} from 'hooks/useIsDesktop'
import {cnLegacy} from 'utils'
import {UserMenuButton, UserMenuSectionHeader} from './components'
import {useAppNavigation, useOpenBookingAgency, useUserMenu} from './hooks'
import {OrganizationSwitcher} from './OrganizationSwitcher'

export const UserMenu = (): ReactElement => {
  const {maximized} = useAppNavigation()
  const {organizationName, organizationType, userName, userTitle} =
    useUserMenu()
  const {logout, hasRole} = useAuth()
  const {canOpenBookingAgency, openBookingAgency} = useOpenBookingAgency()
  const [opened, setOpened] = useState(false)
  const _ref = useRef(null)
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  )
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)
  const isDesktop = useIsDesktop()

  const popperConfig = useMemo(
    (): Partial<Options> => ({
      modifiers: [
        {
          name: 'preventOverflow',
          options: {
            padding: 4,
          },
        },
      ],
    }),
    []
  )

  const {styles, attributes} = usePopper(
    referenceElement,
    popperElement,
    popperConfig
  )

  useClickOutside(_ref, opened, () => {
    setOpened(false)
  })

  const content = (
    <>
      <section className='p-2.5'>
        <UserMenuSectionHeader
          avatar='person'
          primary={userName}
          secondary={userTitle}
        />

        <UserMenuButton disabled title='Coming soon...'>
          Profile settings
        </UserMenuButton>
        <UserMenuButton disabled title='Coming soon...'>
          Help & feedback
        </UserMenuButton>
        <OrganizationSwitcher />
      </section>

      <section className='p-2.5'>
        <UserMenuSectionHeader
          avatar='organizer'
          primary={organizationName}
          secondary={organizationType}
        />
        {hasRole('BOOKING_AGENCY_ADMIN') && (
          <UserMenuButton disabled title='Coming soon...'>
            System settings
          </UserMenuButton>
        )}
        {hasRole('BOOKING_AGENCY_ADMIN') && (
          <UserMenuButton
            disabled={!canOpenBookingAgency}
            onClick={openBookingAgency}
          >
            Company setup
          </UserMenuButton>
        )}
        <UserMenuButton onClick={() => logout()}>Sign out</UserMenuButton>
      </section>
    </>
  )

  if (!isDesktop) {
    return content
  }

  return (
    <div ref={_ref} className='flex items-stretch'>
      <div
        ref={setReferenceElement}
        onClick={() => setOpened(!opened)}
        className={cnLegacy(
          'md:flex md:cursor-pointer',
          maximized ? '-translate-x-14' : 'translate-x-0',
          'transition-transform',
          'group/target'
        )}
      >
        <Avatar
          kind='person'
          className='flex-[0_0_56px] hover:text-pictonBlue'
        />
        <div className='md:flex-none hidden md:flex md:items-center md:w-60 md:px-5'>
          <div className='flex-1'>
            <div
              className={cnLegacy(
                'text-wolfSize transition-colors',
                opened
                  ? 'text-bostonBlue'
                  : 'text-white group-hover/target:text-pictonBlue'
              )}
            >
              {organizationName}
            </div>
            <div className='text-bombay'>{userName}</div>
          </div>
          <div className='flex-none -mr-1'>
            <Icon icon='chevron-down' size={16} />
          </div>
        </div>
      </div>

      {opened && (
        <Portal>
          <div
            ref={setPopperElement}
            onClick={() => setTimeout(() => setOpened(false))}
            className={
              'text-dogSize w-80 bg-white border rounded shadow-popover z-popover divide-y'
            }
            style={styles.popper}
            {...attributes.popper}
          >
            {content}
          </div>
        </Portal>
      )}
    </div>
  )
}
