import type {PropsWithChildren} from 'react'
import {cnLegacy} from 'utils'

interface ButtonGroupProps extends PropsWithChildren {
  className?: string
}

export const ButtonGroup = ({children, className}: ButtonGroupProps) => {
  return (
    <div
      className={cnLegacy(
        'inline-flex',
        '[&>*]:!rounded-none [&>*]:!text-catSize [&>*]:flex-none [&>*]:m-0',
        '[&>:first-child]:!rounded-l [&>:last-child]:!rounded-r',
        '[&>.whnue-button-isSelected]:z-[3]',
        '[&>:hover]:z-[2] [&>:hover]:!shadow-none',
        '[&>*:not(:last-child)]:-mr-px',
        'isolate',
        className
      )}
    >
      {children}
    </div>
  )
}
