import {Input, Checkbox, TimePicker, ShowTypeSelect} from 'components/form'
import {Container, Section, Row, Column, Spacer} from 'containers/Form'
import {useConfig} from 'hooks/useConfig'
import type {ContractPanelProps} from './types'
import {
  OrganizerContactId,
  OrganizerId,
  StartAt,
  TourId,
  VenueId,
  ViewOrganizer,
  ViewOrganizerContact,
  ViewVenue,
  ViewTour,
  BookingAgencyContactId,
} from '../fields'

export const Show: React.FC<React.PropsWithChildren<ContractPanelProps>> = ({
  locked,
}) => {
  const {digisign} = useConfig()

  return (
    <Container>
      <Section label='Show'>
        <Row>
          <Column>
            <StartAt
              model='show.start_at'
              disabled={locked}
              label='Date'
              grid='8'
            />
            <TimePicker model='show.show_at' label='Show' grid='5' />
            <Checkbox
              model='show.is_show_at_tba'
              inverted
              text='Confirmed'
              aligned
              grid='5'
            />
          </Column>
          <Column>
            {false && (
              <>
                <TimePicker model='show.doors_at' label='Doors' grid='5' />
                <Checkbox
                  model='show.is_doors_at_tba'
                  text='TBA'
                  aligned
                  grid='5'
                />
              </>
            )}
            <Input
              model='show.set_amount'
              disabled={locked}
              label='Set'
              kind='number'
              min='0'
              max='3'
              step='1'
              grid='3'
            />
            <Input
              model='show.set_duration'
              disabled={locked}
              label='Set duration'
              kind='number'
              min='0'
              max='300'
              step='1'
              grid='7'
            />
          </Column>
        </Row>

        <Row>
          <Column>
            <Input
              model='show.text'
              label='Title of event'
              placeholder='Only write a title if needed'
            />
          </Column>
          <Column>
            <ShowTypeSelect
              theme='form'
              model='show.show_type_id'
              label='Type of event'
              placeholder='Only select a type if needed'
              isClearable
            />
          </Column>
        </Row>

        <Row>
          <Column nowrap>
            <TourId theme='form' model='show.tour.id' grid='fill' />
            <ViewTour grid='fit' />
          </Column>
          <Column />
        </Row>

        <Spacer />

        <Row>
          <Column nowrap>
            <OrganizerId
              theme='form'
              model='show.stakeholder_organizer.department.id'
              disabled={locked}
              label='Organizer'
              placeholder='Please select organizer...'
              grid='fill'
            />
            <ViewOrganizer
              grid='fit'
              model='show.stakeholder_organizer.department.id'
            />
          </Column>
          <Column nowrap>
            <VenueId
              theme='form'
              model='show.stakeholder_venue.department.id'
              disabled={locked}
              organizerModel='show.stakeholder_organizer.department.id'
              label='Stage'
              placeholder='Please select stage...'
              grid='fill'
            />
            <ViewVenue grid='fit' />
          </Column>
        </Row>

        <Row>
          <Column nowrap>
            <OrganizerContactId
              theme='form'
              model='show.stakeholder_organizer.contact_person.id'
              organizerModel='show.stakeholder_organizer.department.id'
              grid='fill'
              label='Organizer contact / signee'
              disabled={locked}
            />
            <ViewOrganizerContact
              grid='fit'
              model='show.stakeholder_organizer.contact_person.id'
              label='View organizer contact'
            />
          </Column>
          {digisign && (
            <Column nowrap>
              <BookingAgencyContactId
                theme='form'
                model='show.stakeholder_booking_agency.contact_person.id'
                actModel='show.stakeholder_act.department.id'
                grid='fill'
                label='Our contact / signee'
                disabled={locked}
              />
            </Column>
          )}
        </Row>
      </Section>

      <Section label='Notes' secondary>
        <Row>
          <Column>
            <Input model='notes_for_economy' rows='5' multiline />
          </Column>
        </Row>
      </Section>
    </Container>
  )
}
